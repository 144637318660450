/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect  } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import { MdAdsClick } from "react-icons/md";
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
import UserContext from "../UserContext/UserContext"
import Success from '../Success/Success';
 export default function SupportAccTr() {
const { load , url } = useContext(UserContext);
const [auth , setAuth] = useState({data:[] , links:[], user:[]})
const [modal , setModal] = useState(false)
const [detail , setDetail] = useState("")
const [active , setActive] = useState({a1:"" , a2:"active" , a3:"",a4:"",a5:"",a6:"",a7:""})
const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);

    // Clear the timeout if searchTerm changes (user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  const sendSearchRequest = (term) => {
    
        if(term.length >2){
        load(true)

        axios.get(url+"api/v1/panel/transactions/search?query="+
          term +which()  ,
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setAuth(res.data.results);
        }).catch(err=>{
            load(false)
            console.log(err);
        })
    }else{
        load(true)
        axios.get(url+"api/v1/panel/transactions/search?query="+
          term+which() ,
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setAuth(res.data.results)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
    }
    
  };
    useEffect(()=>{
        load(true)
        axios.get(url+"api/v1/panel/transactions/show?type=completed",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                   
                }
            }).then(res => {
                load(false)
                setAuth(res.data.data)
                console.log(res.data.data);
                
                
            }).catch(err => {
                load(false)
                console.log(err);
            })
    },[])
    const which = () =>{
        if(active.a1){
            return "&&type=tcpay"
        }else if(active.a2){
            return "&&type=completed"
        }else if(active.a3){
            return "&&type=cancelled"
        }else if(active.a4){
             return "&&type=coinsbuy"
        }else if(active.a5){
            return "&&type=wallet"
        }else if(active.a6){
            return "&&type=payout"
        }else if(active.a7){
            return "&&type=safir"
        }
    }
    const liShows = () =>{
        return <>
        {
            auth.links.map(dd=>{
                let c = ()=>{
                    if(dd.active){
                        return "ac"
                    }else{
                        return null
                    }
                }
                if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                    return <li key={dd.label} className={"page__numbers "+ c()} onClick={()=>{
                        if(dd.url !== null){
                          load(true)
                          axios.get(dd.url+which()+onSearch(),
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                            }}).then(res=>{
                            load(false)
                            console.log(res.data);
                            if(onSearch()=== ""){
                              setAuth(res.data.data)
                            }else{
                              setAuth(res.data.results)
                            }
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }
                    }}> {dd.label} </li>
                }
             
            })
        }
        </>
        
     }
    const authShow = ()=>{
        return <>
          { auth.data.map(d=>{
            let amount = (a,b) =>{
                if(a === b){
                    return a
                }else{
                    return <div >
                    <div style={{textDecoration: "line-through"}}> {a} </div>
                    <div>{b}</div>
                    </div>

                }
            }
           if(d.order){
            return <>
            <tr className='font'>
                <th> {d.order.user.first_name !== null ?
                 d.order.user.first_name + " " +
                 d.order.user.last_name : d.order.user.username } </th>
               <th> { d.order.product.category.title+ " " + d.order.product.title} </th>
               <th> 
                { d.order.user.username}
               </th>
               <th> {amount(d.amount , d.discount_price)} </th>
               <th style={{textAlign:"left" , fontSize:"9px" , padding:"3px"}} className='transId '>
                 {d.traceNumber!==null?<a target='_blank' href={"https://tronscan.org/#/transaction/"+d.traceNumber}>
                  {d.traceNumber} </a>:
                 "Paid By Coinsbuy"
                 }
               </th>
               <th > {d.updated_at} </th>
               {(active.a2||active.a4)?<th > 
                <MdAdsClick onClick={()=>{
                 load(true)
                 axios.get(url+"api/v1/admin/transactions/get_deposit?id="+d.deposit_id ,
                     { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                 } }).then(res=>{
                     load(false)
                    console.log(res.data.data.data.attributes);
                    setDetail(res.data.data.data.attributes)
                    setModal(true)
                 }).catch(err=>{
                     load(false)
                     console.log(err);
                 })
               }}/> </th>:null}
               
               
            </tr>
            
           </>
           }else{
            if(d.verified_by>1){
                return <>
                <tr style={{color:"yellow"}} className='font'>
                    <th> {d.user.first_name !== null ?
                     d.user.first_name + " " +d.user.last_name: d.user.username} </th>
                   <th> واریز سود  </th>
                   <th> 
                    {d.user.username }
                   </th>
                   
                   
                   <th> {amount(d.amount , d.discount_price)} </th>
                   
                   <th style={{textAlign:"left" , fontSize:"9px" , padding:"3px"}}
                    className='transId '> <a target='_blank' 
                    href={"https://tronscan.org/#/transaction/"+d.traceNumber}> {d.traceNumber} </a></th>
                  
                   <th > {d.updated_at} </th>
                   {(active.a2||active.a4)?<th > 
                <MdAdsClick onClick={()=>{
                 load(true)
                 axios.get(url+"api/v1/admin/transactions/get_deposit?id="+d.deposit_id ,
                     { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                 } }).then(res=>{
                     load(false)
                    console.log(res.data.data.data.attributes);
                    setDetail(res.data.data.data.attributes)
                    setModal(true)
                 }).catch(err=>{
                     load(false)
                     console.log(err);
                 })
               }}/> </th>:null}
                   
                </tr>
                
               </>
            }else{
                return <>
                <tr style={{color:"yellowgreen"}} className='font'>
                    <th> {d.user.first_name !== null ?
                     d.user.first_name + " " +d.user.last_name: d.user.username} </th>
                   <th> واریز به کیف پول  </th>
                   <th> 
                    {d.user.username }
                   </th>
                   
                   
                   <th> {amount(d.amount , d.discount_price)} </th>
                   
                   <th style={{textAlign:"left" , fontSize:"9px" , padding:"3px"}}
                    className='transId '> <a target='_blank' 
                    href={"https://tronscan.org/#/transaction/"+d.traceNumber}> {d.traceNumber} </a></th>
                  
                   <th > {d.updated_at} </th>
                   {(active.a2||active.a4)?<th > 
                <MdAdsClick onClick={()=>{
                 load(true)
                 axios.get(url+"api/v1/admin/transactions/get_deposit?id="+d.deposit_id ,
                     { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                 } }).then(res=>{
                     load(false)
                    console.log(res.data.data.data.attributes);
                    setDetail(res.data.data.data.attributes)
                    setModal(true)
                 }).catch(err=>{
                     load(false)
                     console.log(err);
                 })
               }}/> </th>:null}
                   
                </tr>
                
               </>
            }
           }
                   

          })}
        </>
    }
    // const showXol = ()=>{
    //     if(xol === 1){
    //       return <>
    //        <textarea className='raddWhy' value={radd} onChange={(e)=>{setRadd(e.target.value)}} placeholder='در صورت رد علت رد را وارد نمایید' />
    //          <div className='closeModal'>
    //             <button style={{width:"100%"}} className='btnreply mt' onClick={()=>{
    //             if(radd === ""){
    //                 NotificationManager.error("علت رد خالی می باشد", "رد تراکنش انجام نشد",5000)
    //             }else{
    //                 load(true)
    //                 axios.post(url+"api/v1/panel/transactions/verify", { transaction_id: id[0] , status: 2 , description: radd} ,
    //                     {
    //                         headers: {
    //                             "Authorization": `bearer ${Cookies.get('token')}`,
                               
    //                         }
    //                     }).then(res => {
    //                         load(false)
    //                         NotificationManager.success("تراکنش با موفقیت رد شد", "انجام شد", 5000)
    //                         setModal(false)
    //                         setBtn(e=>[...e,id[0]])
    //                     }).catch(err => {
    //                         load(false)
    //                         NotificationManager.error("رد تراکنش انجام نشد دوباره سعی کنید", "انجام نشد",5000)
    //                         console.log(err);
    //                     })
    //             }
    //             }}> رد نهایی تراکنش </button>
               
    //          </div>
    //       </>
    //     }else if(xol === 2){
    //         return <>
          
    //          <div className='closeModal'>
                
    //             <button style={{width:"100%"}} className='btnreply mt' onClick={()=>{
    //                 load(true)
    //                 axios.post(url+"api/v1/panel/transactions/verify", { transaction_id: id[0] , status: 1 , description: "success"} ,
    //                 {
    //                     headers: {
    //                         "Authorization": `bearer ${Cookies.get('token')}`,
    //                     }
    //                 }).then(res => {
    //                     load(false)
    //                     NotificationManager.success("تراکنش با موفقیت تایید شد", "انجام شد", 5000)
    //                         setModal(false)
    //                         setBtn(e=>[...e,id[0]])
                       
    //                 }).catch(err => {
    //                     NotificationManager.error("تایید تراکنش انجام نشد دوباره سعی کنید", "انجام نشد",5000)
    //                     load(false)
    //                     console.log(err);
    //                 })
    //             }}> تایید نهایی تراکنش  </button>
    //          </div>
    //       </>
    //     }else if(xol === 3){
    //         return <>
          
    //          <div className='closeModal'>
                
    //             <button style={{width:"100%"}} className='btnreply mt' onClick={()=>{
    //                 load(true)
    //               axios.post(url+"api/v1/panel/transactions/verify", { transaction_id: id[0] , status: 1 , description: "success"} ,
    //                 {
    //                     headers: {
    //                         "Authorization": `bearer ${Cookies.get('token')}`,
                           
    //                     }
    //                 }).then(res => {
    //                     load(false)
    //                     NotificationManager.success("تراکنش با موفقیت تایید شد", "انجام شد", 5000)
    //                         setModal(false)
    //                         setBtn(e=>[...e,id[0]])
                       
    //                 }).catch(err => {
    //                     NotificationManager.error("تایید تراکنش انجام نشد دوباره سعی کنید", "انجام نشد",5000)
    //                     load(false)
    //                     console.log(err);
    //                 })
    //             }}> تایید تراکنش  ( اصلاح رد )  </button>
    //          </div>
    //       </>
    //     }
    // }
    const onSearch = ()=>{
        if(searchTerm.length >2){
           return "&&query="+searchTerm
        }else{
          return ""
        }
       }
  return (
    <>
    <Success show={modal} closeModal={()=>{setModal(false) 
       
    } } name="inside23da" >
     <div >
        {detail!==""&&<div style={{padding:"10px"}}>
          <div className='orderDetail bb mt'>
            <div className='fl'> مبلغ  </div>
            <div> {detail.amount}  </div>
          </div>
          <div className='orderDetail bb mt'>
            <div className='fl'> خالص دریافتی  </div>
            <div> {detail.amount_cleared}  </div>
          </div>
          <div className='orderDetail bb mt'>
            <div className='fl'> کمیسیون  </div>
            <div> {detail.commission}  </div>
          </div>
          <div className='orderDetail bb mt'>
            <div className='fl'> TX-ID  </div>
            <div style={{marginRight:"20px"}} className='f12'> {detail.txid}  </div>
          </div>
          <div className='orderDetail mt'>
            <div className='fl'> تاریخ  </div>
            <div> {detail.created_at}  </div>
          </div>
         </div>
        }
     </div>
    </Success>
    
     <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                load(true)
                axios.get(url+"api/v1/panel/transactions/show?type=completed",
                    {
                        headers: {
                            "Authorization": `bearer ${Cookies.get('token')}`,
                           
                        }
                    }).then(res => {
                        load(false)
                        setAuth(res.data.data)
                        setActive({a1:"" , a2:"active" , a3:"",a4:"",a5:"",a6:"",a7:""})
                    }).catch(err => {
                        load(false)
                        console.log(err);
                    })
                
            }}  className={active.a2}>
             تایید شده
            </a>
            <a onClick={()=>{
                setSearchTerm('')
                load(true)
                axios.get(url+"api/v1/panel/transactions/show?type=cancelled",
                    {
                        headers: {
                            "Authorization": `bearer ${Cookies.get('token')}`,
                        }
                    }).then(res => {
                        load(false)
                        setAuth(res.data.data)
                        setActive({a1:"" , a2:"" , a3:"active",a4:"",a5:"",a6:"",a7:""})
                    }).catch(err => {
                        load(false)
                        console.log(err);
                    })
                
            }}  className={active.a3}>
                 واریز سود
            </a>
            <a onClick={()=>{
                setSearchTerm('')
                load(true)
                axios.get(url+"api/v1/panel/transactions/show?type=coinsbuy",
                    {
                        headers: {
                            "Authorization": `bearer ${Cookies.get('token')}`,
                        }
                    }).then(res => {
                        load(false)
                        setAuth(res.data.data)
                        setActive({a1:"" , a2:"" , a3:"",a4:"active",a5:"",a6:"",a7:""})
                    }).catch(err => {
                        load(false)
                        console.log(err);
                    })
                    
            }}  className={active.a4}>
                 CoinsBuy
            </a>
            <a onClick={()=>{
                setSearchTerm('')
                load(true)
                axios.get(url+"api/v1/panel/transactions/show?type=tcpay",
                    {
                        headers: {
                            "Authorization": `bearer ${Cookies.get('token')}`,
                        }
                    }).then(res => {
                        load(false)
                        setAuth(res.data.data)
                        setActive({a1:"active" , a2:"" , a3:"",a4:"",a5:"",a6:"",a7:""})
                    }).catch(err => {
                        load(false)
                        console.log(err);
                    })
                
            }}  className={active.a1}>
                 TopChange
            </a>
            <a onClick={()=>{
                setSearchTerm('')
                load(true)
                axios.get(url+"api/v1/panel/transactions/show?type=wallet",
                    {
                        headers: {
                            "Authorization": `bearer ${Cookies.get('token')}`,
                        }
                    }).then(res => {
                        load(false)
                        setAuth(res.data.data)
                        setActive({a1:"" , a2:"" , a3:"",a4:"",a5:"active",a6:"",a7:""})
                    }).catch(err => {
                        load(false)
                        console.log(err);
                    })
                
            }}  className={active.a5}>
                 خرید با کیف پول
            </a>
            <a onClick={()=>{
                setSearchTerm('')
                load(true)
                axios.get(url+"api/v1/panel/transactions/show?type=safir",
                    {
                        headers: {
                            "Authorization": `bearer ${Cookies.get('token')}`,
                        }
                    }).then(res => {
                        load(false)
                        setAuth(res.data.data)
                        setActive({a1:"" , a2:"" , a3:"",a4:"",a5:"",a6:"",a7:"active"})
                    }).catch(err => {
                        load(false)
                        console.log(err);
                    })
                
            }}  className={active.a7}>
                 سفیر
            </a>
        </div>
        <div style={{overflowX:"scroll"}} className='transAction'>
                <div className='eachTicket'>
                    <p className='bb'>با کلیک بر روی آیکون کلیک جزئیات بیشتر را مشاهده نمایید : </p>
                    جستجو
                    <input value={searchTerm} style={{width:"35%" , margin:"10px"}}
                                        className='searchIn'
                                     onChange={(e)=>{
                                        setSearchTerm(e.target.value)
                                     }} type='text' placeholder='جستجو نام کاربری ...'/>
                
                  {/* <select value={search} style={{width:"35%" , margin:"10px"}}
                                        className='searchIn'
                                     onChange={(e)=>{
                                    setSearch(e.target.value)
                  }} type='text' >
                   <option value="username" >   نام کاربری  </option>
                   <option value="first_name">   نام   </option>
                   <option value="last_name">   نام خانوادگی   </option>
                   <option value="traceNumber">   TX-id   </option>
                  </select> */}
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover ">
                            <thead >
                                <tr className='font'>
                                    <th> کاربر </th>
                                    <th style={{minWidth:"100px"}}> محصول </th>
                                    <th> نام کاربری </th>     
                                    <th>پرداختی </th>                 
                                    {active.a1 === "active"?<th> شناسه پرداخت </th>:
                                    <th className='f12' style={{padding:"5px"}}> TX-ID </th>
                                    }
                                    <th> تاریخ ایجاد </th>
                                    {active.a3||active.a4?<th>  </th>:null}
                                </tr>
                            </thead>
                            <tbody>
                                {authShow()}
                            </tbody>
                            

                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(auth.prev_page_url !== null){
                                    load(true)
                                    console.log(auth.prev_page_url+which()+onSearch());
                                    
                                axios.get(auth.prev_page_url+which()+onSearch(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    if(onSearch()=== ""){
                                        setAuth(res.data.data)
                                       }else{
                                        setAuth(res.data.results)
                                       }
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(auth.next_page_url !== null){
                                    load(true)
                                    console.log(auth.next_page_url+which()+onSearch());
                                    
                                axios.get(auth.next_page_url+which()+onSearch(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    if(onSearch()=== ""){
                                        setAuth(res.data.data)
                                       }else{
                                        setAuth(res.data.results)
                                       }
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

