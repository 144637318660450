/* eslint-disable no-useless-concat */
import React, { useState , useContext} from 'react'
import "./InsideTicket.css"
import { TiTick } from "react-icons/ti";
import { BsTicketPerforatedFill } from "react-icons/bs";
import { MdSupportAgent } from "react-icons/md";
import { FaUser , FaEye } from "react-icons/fa";
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { HiAnnotation } from "react-icons/hi";
import 'react-notifications/lib/notifications.css';
import { Rating } from 'react-simple-star-rating'
import axios from 'axios';
import Success from '../Success/Success';
import { HiBanknotes } from "react-icons/hi2";
export default function InsideTicket(props) {
    const [replyText , setRep] = useState("")
    const [modal , setModal] = useState(false)
    const [rate , setRate] = useState(1)
    const [inDep , setInDep] = useState([])
    const [dep , setDep] = useState("")
    const [assign , setAssign] = useState("")
    const [fileUrl, setFileUrl] = useState([])
    const { load , url , user } = useContext(UserContext);
    
    const handleRating = (rat) => {
        load(true)
                    axios.post(url+"api/v1/panel/tickets/" + props.rep.id +"/rating" ,{rate: rat},
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}`     
                    } }).then(res=>{
                        load(false)
                        setRate(3)
                        NotificationManager.success("امتیاز با موفقیت ثبت شد" , "",5000)
                    }).catch(err=>{
                        load(false)
                        NotificationManager.error("امتیاز ثبت نشد دوباره امتحان نمایید" , "",3000)
                        console.log(err);
                    })
       
        
        
      }
     const closeRate = ()=>{
        
            if(props.rep.status !== 2){
                if(rate === 1 ){
                    return  <div className='replyBtn'>
                    <textarea value={replyText} onChange={(e)=>{
                     setRep(e.target.value)
                    }} className='input-field' placeholder='متن پیام خود را وارد نمایید' />
                    <input  type='file' accept='.jpg , .jpeg , .png , .webp , .avif , .gif , .zip'
                     name='file' className='form-control mt' onChange={(e) => {
                            setFileUrl(e.target.files[0])
                        }} />
                    <div className='btns'>
                       <button onClick={()=>{
                        if(user.dataes.roles[0].name !== "user"){
                            if (window.confirm("آیا از ازسال تیکت به کاربر اطمینان دارید؟")) {
                                let dt = {text : replyText , type: props.type , file:fileUrl}
                             if(replyText !== ""){
                                  load(true)
                                 axios.post(url+"api/v1/panel/tickets/" + props.rep.id +"/reply",dt ,
                                     { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,
                                     'Content-Type': 'multipart/form-data'    
                                 } }).then(res=>{
                                     setRep("")
                                     load(false)
                                     NotificationManager.success("پاسخ شما با موفقیت ارسال شد" , "ارسال پاسخ ",3000)
                                     props.afterRep(props.rep.id)
                                     setFileUrl("")
                                 }).catch(err=>{
                                     load(false)
                                     console.log(err);
                                 })
                             }else {
                                 NotificationManager.warning("متن پیام خالی است" , "پیام ارسال نشد",3000)
                             }
                              }
                        }else{
                              load(true)
                               let dt = {text : replyText , type: props.type , file:fileUrl}
                                 axios.post(url+"api/v1/panel/tickets/" + props.rep.id +"/reply",dt ,
                                     { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,
                                     'Content-Type': 'multipart/form-data'    
                                 } }).then(res=>{
                                     setRep("")
                                     load(false)
                                     NotificationManager.success("پاسخ شما با موفقیت ارسال شد" , "ارسال پاسخ ",3000)
                                     props.afterRep(props.rep.id)
                                     setFileUrl("")
                                 }).catch(err=>{
                                     load(false)
                                     console.log(err);
                                 })
                        }
                         
                       }} className='btnreply mt'> ارسال پاسخ </button>
                       <button onClick={()=>{
                         if(user.dataes.roles[0].name !== "user"){
                            if(window.confirm("آیا از ازسال کانت برای همکاران اطمینان دارید؟")){
                                let dt = {text : replyText , type: props.type , is_comment:1 , file:fileUrl}
                         if(replyText !== ""){
                              load(true)
                             axios.post(url+"api/v1/panel/tickets/" + props.rep.id +"/reply",dt ,
                                 { headers: { "Authorization": `bearer ${Cookies.get('token')}`,
                                 'Content-Type': 'multipart/form-data'     
                                 } }).then(res=>{
                                 setRep("")
                                 load(false)
                                 NotificationManager.success("کامنت شما با موفقیت ارسال شد" , "ارسال کامنت ",3000)
                                 props.afterRep(props.rep.id)
                                 }).catch(err=>{
                                 load(false)
                                 console.log(err);
                             })
                         }else {
                             NotificationManager.warning("متن پیام خالی است" , "پیام ارسال نشد",3000)
                         }
                            }
                            
                         }else{
                            setModal(true)
                         }
                         
                       }} className='btnreply mt'> {user.dataes.roles[0].name !== "user"?"ارسال کامنت":"بستن تیکت"} </button>
                    </div>
                    {user.dataes.roles[0].name !== "user"?
                    <>
                    <button onClick={()=>{
                       if(window.confirm("آیا از تعلیق این تیکت اطمینان دارید؟")){
                        load(true)
                        axios.post(url+"api/v1/panel/suspended/"+props.rep.id ,{} ,
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}`     
                        } }).then(res=>{
                            load(false)
                            NotificationManager.success("تیکت با موفقیت تعلیق شد")
                        }).catch(err=>{
                            load(false)
                            NotificationManager.error("تیکت تعلیق نشد بعدا دوباره سعی کنید")
                            console.log(err);
                        })
                       }
                    }} className='btnreply mt' style={{width:"100%"}}>تعلیق</button>
                     <fieldset className='erja c1'>
                        <legend > ارجاع تیکت به سایر  </legend>
                        
                        <select className='erjaSelect' value={dep} onChange={(e)=>{
                           load(true)
                            setDep(e.target.value)
                           axios.get(url+"api/v1/panel/department_user?type=" + e.target.value ,
                               { headers: { "Authorization": `bearer ${Cookies.get('token')}`     
                           } }).then(res=>{
                               load(false)
                               setInDep(res.data.data)
                           }).catch(err=>{
                               load(false)
                               console.log(err);
                           })
                        }}>
                           <option value=""> دپارتمان مورد نظر را انتخاب نمایید </option>
                           <option value="1"> پشتیبانی عمومی </option>
                           <option value="2"> پشتیبانی فنی </option>
                        </select>
                        <select className='erjaSelect' value={assign} onChange={(e)=>{
                            setAssign(e.target.value)
                        }}>
                            <option value="0"> پشتیبان مورد نظر راانتخاب نمایید</option>
                         {inDep.map(d=>{
                            return <option key={d.id} value={d.id}> {d.first_name+" "+d.last_name} </option>
                         })}
                        </select>
                        {dep!== ""?<button className='erjaSelect' onClick={()=>{
                            load(true)
                            axios.post(url+"api/v1/panel/assigned_to/"+props.rep.id,{department_id:dep,admin_id:assign} ,
                                { headers: { "Authorization": `bearer ${Cookies.get('token')}`     
                            } }).then(res=>{
                                load(false)
                                setRate(3)
                                NotificationManager.success("این تیکت با موفقیت ارجاع یافت","ارجاع موفق",4000)
                            }).catch(err=>{
                                load(false)
                                NotificationManager.error("عدم برقراری ارتباط با سرور", "ارجاع انجام نشد",3000)
                                console.log(err);
                            })
                        }}> تایید ارجاع </button>:null}
                     </fieldset></>:null  
                     }
                 </div>
                 }else if(rate===2){
                    if(user.dataes.roles[0].name === "user"){
                         return <div className='nazar'>
                   <p > برای بهبود کیفیت پاسخدهی میزان رضایت خود از پاسخدهی به این تیکت را مشخص نمایید </p>
                   <Rating
                     onClick={handleRating}
                     className='rate'
                   />
                     </div>
                    }
                    
                 }else if(rate===3){
                    return <></>
                 }
            }else{
                if( props.rep.rating === null && rate !== 3){
                    return <div className='nazar'>
                    <p > برای بهبود کیفیت پاسخدهی میزان رضایت خود از پاسخدهی به این تیکت را مشخص نمایید </p>
                    <Rating
                      onClick={handleRating}
                      className='rate'
                    />
                      </div>
    
                }
            }
        
     }
    const ticketReplies = ()=>{
        let rep = props.rep
        let x = ()=>{
            if(rep.comments !== undefined){
                return props.rep.replies.concat(props.rep.comments)

            }else{
                return props.rep.replies
            }
        }
        
        const nameLast = ()=>{
            let x = props.name
            if(x === ""){
                return "شما"
            }else{
                return x.first_name + " " + x.last_name  
            }
        }
        const depName = (x)=>{
            if(x === 1){
                return "پشتیبانی عمومی"
            }else if(x===2){
                return "پشتیبانی فنی"
            }else{
                return "پشتیبانی"
            }
        }
        const fileShow = (rf)=>{
          if(rf !== null){
            if(/\.(jpg|jpeg|png|webp|avif|gif)$/.test(url+"storage/"+rf)){
                return <img onClick={()=>{
                    window.open(url+"storage/"+rf, "_blank") 
           }} className='tickImage' src={url+"storage/"+rf} alt="ticketFile"/>
            }else{
                return <a style={{color:"blue"}}
                href={url+"storage/"+rf} target='_blank' rel='noreferrer'>برای دانلود فایل آپلود شده کلیک نمایید</a>
            }
          }else{
            return null
          }
        }
        return <nav className="tmln tmln--box">
            <li className="tmln__item tmln__item--bg-dark">
				        <span > <BsTicketPerforatedFill />    تیکت  {" "} {rep.created_at} توسط {nameLast()} ایجاد شده </span>
				        
				        <span > برای دپارتمان {rep.departments.title} </span>
                        {user.dataes.roles[0].name!=="user"&&<p className="tmln__item-headline">  {rep.is_read? 
                            <><FaEye style={{marginLeft:"5px"}}/> 
                        دیده شده توسط کاربر  </>:null}  </p>}
                        <p className="tmln__item-headline">  <TiTick/> عنوان تیکت -- {rep.title}</p>
                        <p className="tmln__item-headline">  <HiAnnotation /> موضوع تیکت -- {rep.department_sections.title}   </p>
                        {rep.account !== null?<p className="tmln__item-headline">  <HiBanknotes /> حساب مربوطه -- {rep.account.login !== null?
                                    rep.account.order.product.type+" ("
                                    +  rep.account.login+
                                    ") " + " / " + rep.account.order.product.title 
                                    :
                                    rep.account.order.product.type + " / " + rep.account.order.product.title +" / غیر فعال "
                                    }   </p>:null}
				        <div className='tikcontent'>
				           <p> {rep.description} </p>  
                           {fileShow(rep.file)}
                        </div>
			       </li>
            {x().map(d=>{
                if(d.reply_type !== "user"){
                    if(d.internal_comment === 1){
                       return <li style={{background:"bisque"}} className="tmln__item tmln__item--bg-lght">
                                  <span > کامنت  {d.created_at} توسط  {d.replier !== undefined?
                                    d.replier.first_name + " "+d.replier.last_name:
                                    "دپارتمان "+depName(d.department)} : 
                                  </span>
                                  <p className='answer'> {d.description} </p>
                                  {fileShow(d.file)}
                              </li> 
                    }else{
                        return <li className="tmln__item tmln__item--bg-lght">
                             <span > <MdSupportAgent /> پاسخ تیکت {d.created_at} توسط  {d.replier !== undefined?
                              d.replier.first_name + " "+d.replier.last_name:"دپارتمان "+depName(d.department)} : </span>
                             <p className='answer'> {d.description} </p>
                             {fileShow(d.file)}
                          </li> 
                    }
                   
                }else{
                    
                        return <li className="tmln__item tmln__item--bg-dark" >
                                 <span > <FaUser /> پاسخ {nameLast()} {d.created_at} : </span>
                                 <p className='tikcontent'>{d.description}</p>
                                 {fileShow(d.file)}
                               </li>
                   
                }
            })}
            {closeRate()}
        </nav>
    }
  return (
    <>
    <Success show={modal} closeModal={()=>{setModal(false)} } name="inside" >
     <div >
        <div className='bb'> آیا از بستن تیکت اطمینان دارید؟ </div>
        <p className='mt'> در صورت بسته شدن تیکت امکان باز کردن مجدد آن وجود ندارد در صورتی
             که قصد بستن تیکت را ندارید دکمه خیر را بزنید</p>
             <div className='closeModal'>
                <button className='btnreply mt' onClick={()=>{
                    setModal(false)
                }}> خیر </button>
                <button className='btnreply mt' onClick={()=>{
                    load(true)
                    axios.get(url+"api/v1/panel/tickets/" + props.rep.id +props.close ,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}`     
                    } }).then(()=>{
                        load(false)
                        NotificationManager.success("تیکت با موفقیت بسته شد" , "بستن تیکت",3000)
                        setModal(false)
                        setRate(2)
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                    })
                }}> بله </button>
             </div>
     </div>
    </Success>
    <NotificationContainer />
     {ticketReplies()}
    </>
  )
}
