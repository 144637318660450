/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState , useContext, useEffect } from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import Success from '../Success/Success'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { MdAdsClick } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
export default function SupportWidReq() {
    const [m , setM] = useState([])
    const [des , setDes] = useState({})
    const [amount , setAmount] = useState("")
    const [imageSer , setImageSer] = useState("")
    const [searchTerm, setSearchTerm] = useState('');

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const tab = ()=>{
        if(activer.a1){
            return "pending"
        }else if(activer.a2){
            return "completed"
        }else if(activer.a3){
            return "mine"
        }else if(activer.a4){
            return "cancelled"
        }else if(activer.a5){
            return "approved"
        }else{
            return ""
        }
    }
    const sendSearchRequest = (term) => {
        load(true)
        if(activer.a6){
        load(true)
         axios.get(url+"api/v1/admin/payouts/certificates/search?query="+term,
        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
         } }).then(res=>{
        load(false)
        setHesab(res.data)
        }).catch(err=>{
        load(false)
        console.log(err);
         })
        }else{
            load(true)
            axios.get(url+"api/v1/panel/payouts/search?type="+tab()+"&&query="+term,
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
            } }).then(res=>{
                load(false)
                setHesab(res.data)
            }).catch(err=>{
                load(false)
                console.log(err);
            })
        }
    
    
  };
    useEffect(() => {
        if (debouncedSearchTerm) {
          sendSearchRequest(debouncedSearchTerm);
        }
      }, [debouncedSearchTerm]);
      useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedSearchTerm(searchTerm);
        }, 2000);
        return () => {
          clearTimeout(handler);
        };
      }, [searchTerm]);
    useEffect(()=>{
      user.dataes.permissions.map(d=>{
        setM(e=>[...e , d.name])
      })
        load(true)
        axios.get(url+"api/v1/panel/payouts",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
    },[])
    const { load , url , user } = useContext(UserContext);
    const [modal , setModal] = useState(false)
    const [hesab , setHesab] = useState({data:[] , links:[], user:[]})
    const [radd , setRadd] = useState("")
    const [id , setId] = useState("")
    const [btn , setBtn] = useState([])
    const [shows , setShows] = useState(0)
    const [nextAmount , setNextAm] = useState("")
    const [taidRad , setTaidRad] = useState(false)
    const hesabs = ()=>{
        const translator = (n)=>{
            if(n==="First Step"){
                return "مرحله اول"
            }else if(n === "Second Step"){
                return "مرحله دوم"
            }else if(n === "Pro Trader"){
                return "پرو تریدر"
            }else if(n === "Real"){
                return "REAL"
            }else{
                return n
            }
        }

        return <>
        {hesab.data.map((d,i)=>{
              
                return <tr key={i}>
                         <th> {d.user.username} </th>
                         <th> {d.account.order.product.type + "/" + d.account.order.product.title} </th>
                         <th> {d.account.order.server+" / "+d.account.order.platform} </th>
                         <th> {d.account.login} </th>
                         <th style={{direction:"ltr",textAlign:"left"}}>
                             {d.account.investor_password} 
                         </th>
                         <th> {translator(d.account.type)} </th>  
                         <th> {d.created_at} </th>    
                         <th> {d.updated_at} </th>    
                         {activer.a2==="active" || activer.a4 === "active"?
                         <th> {d.did_by!==null?d.did_by.first_name + " " + d.did_by.last_name:null} </th>:null  }  
                         {activer.a5 !== "active"?<th className='lastTh'> 
                         {!btn.includes(d.id)?<MdAdsClick onClick={()=>{
                               if((activer.a3 === "active" && d.status !== 0) ){
                                  if(d.status === 3 || d.status === 4){
                                    NotificationManager.warning("این درخواست قبلا رد شده")
                                  }else if(d.status === 1 || d.status === 2){
                                    NotificationManager.warning("این درخواست قبلا تایید شده")
                                  }
                               }else{
                                setId(d.id)
                                setModal(true)
                                setDes(d)
                                console.log(d);
                               }
                            }} />:null} </th>:<th> {d.status === 4?
                            <div onClick={()=>{
                             setDes([d,false])
                             setTaidRad(true)
                             setModal(true)
                            }} className='red  joziat'>
                              رد شده
                            </div>:<div onClick={()=>{
                             console.log(d);
                             setDes([d,true])
                             setTaidRad(true)
                             setModal(true)
                            }} className='green joziat'>
                              تایید شده
                            </div>} </th>} 
                     </tr>
              
            
        })}
        </>
    }
    const btnShow = ()=>{
      if(shows === 1){
        return <> 
        <textarea className='raddWhy mt' value={radd} onChange={(e)=>{setRadd(e.target.value)}}
            placeholder='در صورت رد علت رد را وارد نمایید' />
            <button 
                style={{width:"100%" , background:"red"}} 
                className='btn btn-success mt' 
                onClick={()=>{
              if(radd === ""){
                NotificationManager.error("علت رد خالی می باشد", "رد تقاضای برداشت انجام نشد",5000)
              }else{
               const st = ()=>{
                   if(activer.a4){
                       return 4
                   }else{
                       return 3
                   }
               }
               let verify = ()=>{
                if(activer.a4 === "active"|| activer.a2 === "active"){
                    return "/super_verify"
                }else{
                    return "/verify"
                }
             }
               load(true)
               axios.post(url+"api/v1/panel/payout/"+id+verify(), {  status: st() , text: radd} ,
                   {
                       headers: {
                           "Authorization": `bearer ${Cookies.get('token')}`,
                       }
                   }).then(() => {
                       load(false)
                       NotificationManager.success("تقاضای درخواست برداشت با موفقیت رد شد", "انجام شد", 5000)
                       setModal(false)
                       setBtn(e=>[...e,id])
                       setRadd("")
                   }).catch(err => {
                       load(false)
                       NotificationManager.error("رد درخواست برداشت انجام نشد دوباره سعی کنید", "انجام نشد",5000)
                       console.log(err);
                   })
           }
        }}> رد نهایی</button>
        </> 
      }else if(shows === 2){
            return <>
            <textarea className='raddWhy mt' value={radd}
            onChange={(e)=>{setRadd(e.target.value)}}
                 placeholder='توضیحات ...' />
                
             {activer.a2==="active"?<>
             <div className='mt dfjc' style={{width:"50%"}}>
                <label> مبلغ دریافتی : </label>
                <input value={amount} onChange={(e)=>{
                setAmount(e.target.value)
                }} type='text' />
             </div>
             <div className='mt dfjc' style={{width:"50%"}}>
                <label> بالانس بعدی : </label>
                <input value={nextAmount} onChange={(e)=>{
                setNextAm(e.target.value)
                }} type='text' />
             </div>
             </>
             :null}
             <button style={{width:"100%" , background:"green"}} className='btn btn-success mt' onClick={()=>{
                if(radd !== ""){
                
                    let status = ()=>{
                       if(activer.a1 === "active"){
                        return 1
                    }else if(activer.a2 === "active"){
                        return 2
                    }else {
                        return 1
                    } 
                    }
                    
                 let verify = ()=>{
                    if(activer.a2 === "active" || activer.a4 === "active" ){
                        return "/super_verify"
                    }else{
                        return "/verify"
                    }
                 }
                 if(activer.a2 === "active"){
                  if(amount !== "" && nextAmount !==""){
                    load(true)
                    
                    axios.post(url+"api/v1/panel/payout/"+id+verify(), 
                    { status: status() ,
                      text :radd , 
                      amount:amount , 
                      balance: nextAmount 
                    } ,
                        {
                            headers: {
                                "Authorization": `bearer ${Cookies.get('token')}`,
                            }
                        }).then(res => {
                            load(false)
                            NotificationManager.success("تقاضای برداشت با موفقیت تایید شد", "انجام شد", 5000)
                            setModal(false)
                            setBtn(e=>[...e,id])
                            console.log(res.data);
                        }).catch(err => {
                            load(false)
                            NotificationManager.error("تایید درخواست برداشت انجام نشد دوباره سعی کنید", "انجام نشد",5000)
                            console.log(err);
                        })
                  }else{
                    NotificationManager.warning("همه فیلد ها را پر نمایید")
                  }
                 }else{
                    load(true)
                    axios.post(url+"api/v1/panel/payout/"+id+verify(), { status: status() , text :radd } ,
                    {
                        headers: {
                            "Authorization": `bearer ${Cookies.get('token')}`,
                        }
                    }).then(res => {
                        load(false)
                        NotificationManager.success("تقاضای برداشت با موفقیت تایید شد", "انجام شد", 5000)
                        setModal(false)
                        setBtn(e=>[...e,id])
                        console.log(res.data);
                    }).catch(err => {
                        load(false)
                        NotificationManager.error("تایید درخواست برداشت انجام نشد دوباره سعی کنید", "انجام نشد",5000)
                        console.log(err);
                    })
                 }
                }else{
                        NotificationManager.error("متن پیام خالی می باشد","انجام نشد",4000)
                       }
             }}>  تایید نهایی </button>
             </>
        
      }
    }
    const inModalShow = ()=>{
        if(taidRad){
           return  <div> 
                 <div style={{textAlign:"center"}} className='bb'> اطلاعات برداشت کاربر 
                    {" "+des[0].user.username}  </div>
                    {des[0].f_did_by &&<div  className='des'>
                               <p className='why'> توضیحات توسط {des[0].f_did_by.first_name+ " " + des[0].f_did_by.last_name}  :  </p> 
                                 <p> {des[0].f_description} </p>
                                </div>}
                <div className='des'> 
                       <p className='why'> توضیحات توسط {des[0].did_by ?
                       des[0].did_by.first_name+ " " + des[0].did_by.last_name+"":null}  :  </p> 
                       <p> {des[0].description} </p>
                 </div>
                 <div className='des mt'> 
                       <p className='why'> توضیحات توسط {des[0].verified_by ?
                       des[0].verified_by.first_name+ " " + des[0].verified_by.last_name+"":null}  :  </p> 
                       <p> {des[0].supervisor_description} </p>
                 </div>
                 <div className='orderDetail bb mt'>
                   <div className='fl'> تعداد برداشت </div>
                   <div> {des[0].payouts_count} <FaCopy onClick={()=>{
                       navigator.clipboard.writeText(des.payouts_count)
                    }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
               </div>
                 {des[1]&&<><div className='orderDetail bb mt'>
                   <div className='fl'> مبلغ دریافتی </div>
                   <div> {des[0].received_amount} </div>
               </div>
               
               
               
                 {des[0].image?<div style={{display:"grid"}}>
                    <div className='flex1 bb'>
                        <div className='gg'> تصویر سرتیفیکیت </div>
                        <img style={{
                            height:"200px",
                            width:"200px"
                         }} onClick={()=>{
                            window.open(url+"storage"+des[0].image,"_blank")
                         }}  src={url+"storage"+des[0].image} alt='serImage' />
                    </div>
                    
                 <div className='flex1'>
               <div className='gg'> تعویض سرتیفیکیت </div>
                 <input style={{width:"100%"}} type="file" accept="image/*"  onChange={(e)=>{
                    setImageSer(e.target.files[0])
                }}  className='loginInput'/>
               </div>
               <button style={{width:"100%"}} className='btnreply mt' 
               onClick={()=>{
                 if(imageSer!==""){
                     load(true)
                     axios.post(url+"api/v1/admin/payouts/"+des[0].id+"/certificate",{
                         certificate:imageSer
                     },
                         { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
                                      'Content-Type': 'multipart/form-data'
                     } }).then(()=>{
                         load(false)
                         NotificationManager.success("با موفقیت انجام شد")
                         setModal(false)
                         setImageSer("")
                         load(true)
                         axios.get(url+"api/v1/admin/payouts/certificates",
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                         } }).then(res=>{
                        load(false)
                        setHesab(res.data)
                        }).catch(err=>{
                        load(false)
                        console.log(err);
                         })
                     }).catch(err=>{
                         NotificationManager.error("درخواست انجام نشد")
                         load(false)
                         console.log(err);
                         
                     })
                 }else{
                  NotificationManager.warning("تصویر سرتیفیکیت بارگزاری نشده است")
                 }
               }}> تعویض تصویر CERTIFICATE </button>
                 </div>:<div className='mt'>
                    تصویر Certificate بارگزاری نشده
                    </div>}
               
               </>}
             </div>
        }else{
            if(activer.a6){
                if(des.user !== undefined){
                 return  <div >
                 
                 <div key={des.description} className='des'> 
                       <p className='why'> توضیحات توسط {des.verified_by ?
                       des.verified_by.first_name+ " " + des.verified_by.last_name+"":null}  :  </p> 
                       <p> {des.supervisor_description} </p>
                 </div>
                 <div className='orderDetail bb mt'>
                   <div className='fl '> مبلغ دریافتی </div>
                   <div> {des.received_amount} <FaCopy onClick={()=>{
                       navigator.clipboard.writeText(des.received_amount)
                    }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
               </div>
               
               <div className='orderDetail bb'>
                   <div className='fl'> نام کامل </div>
                   <div> {des.user.first_name+" "+des.user.last_name} <FaCopy onClick={()=>{
                       navigator.clipboard.writeText(des.user.first_name+" "+des.user.last_name)
                    }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
               </div>
               <div className='orderDetail bb'>
                   <div className='fl'> نام کاربری </div>
                   <div> {des.user.username} <FaCopy onClick={()=>{
                       navigator.clipboard.writeText(des.user.username)
                    }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
               </div>
               <div className='orderDetail bb'>
                   <div className='fl'> تعداد برداشت </div>
                   <div> {des.payouts_count} <FaCopy onClick={()=>{
                       navigator.clipboard.writeText(des.payouts_count)
                    }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
               </div>
               <div className='flex1'>
               <div className='gg'> تصویر سرتیفیکیت </div>
                 <input style={{width:"166.3px"}} type="file" accept="image/*"  onChange={(e)=>{
                    setImageSer(e.target.files[0])
                }}  className='loginInput'/>
               </div>
               <button style={{width:"100%"}} className='btnreply mt' 
               onClick={()=>{
                 if(imageSer!==""){
                     load(true)
                     axios.post(url+"api/v1/admin/payouts/"+des.id+"/certificate",{
                         certificate:imageSer
                     },
                         { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
                                      'Content-Type': 'multipart/form-data'
                     } }).then(res=>{
                         load(false)
                         NotificationManager.success("با موفقیت انجام شد")
                         setModal(false)
                         setImageSer("")
                         load(true)
                         axios.get(url+"api/v1/admin/payouts/certificates",
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                         } }).then(res=>{
                        load(false)
                        setHesab(res.data)
                        }).catch(err=>{
                        load(false)
                        console.log(err);
                         })
                     }).catch(err=>{
                         NotificationManager.error("درخواست انجام نشد")
                         load(false)
                         console.log(err);
                     })
                 }else{
                  NotificationManager.warning("تصویر سرتیفیکیت بارگزاری نشده است")
                 }
               }}> بارگزاری CERTIFICATE </button>
             </div>
                }
             }else{
             
                 return  <div >
                          <div className='bb'> تایید یا رد درخواست برداشت حساب {des.user?.username} </div>
                          <p className='mt'> تیم مانیتورینگ عزیز لطفا پس از انجام بررسی های لازم 
                             ، وضعیت درخواست برداشت کاربر را تایید یا رد نمایید   </p>
                             {des.f_did_by &&<div  className='des'>
                               <p className='why'> توضیحات توسط {des.f_did_by.first_name+ " " + des.f_did_by.last_name}  :  </p> 
                                 <p> {des.f_description} </p>
                                </div>}
                              {activer.a4 === "active" || activer.a2==="active"? <div className='des'> 
                                 <p className='why'> توضیحات توسط {des.did_by ?des.did_by.first_name+ " " + des.did_by.last_name+"":null}  :  </p> 
                                 <p> {des.description} </p>
                                </div>:null}
                                
                                
                                <div className='orderDetail bb mt'>
                                <div className='fl'> تعداد برداشت </div>
                                  <div> {des.payouts_count} <FaCopy onClick={()=>{
                                   navigator.clipboard.writeText(des.payouts_count)
                                 }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                                </div>
                               <div className='closeModal'>
                                  <button className='btnreply mt' onClick={()=>{setShows(1)}}> رد</button>
                                  <button className='btnreply mt' onClick={()=>{setShows(2)}}> تایید </button>
                               </div>
                               {btnShow()}
                       </div>
             }
        }
    }
  const [activer , setActiver] = useState({a1:"active",a2:"", a3:"",a4:"",a5:"",a6:"",a7:"",a8:""})
  const onSearch = ()=>{
    if(searchTerm.length >2){
       return "&&query="+searchTerm
    }else{
      return ""
    }
   }
  const liShows = () =>{
    return <>
    {
        hesab.links.map(dd=>{
            let c = ()=>{
                if(dd.active){
                    return "ac"
                }else{
                    return null
                }
            }
            if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                return <li key={dd.label} className={"page__numbers"+" "+ c()} onClick={()=>{
                    
                        if(dd.url !== null){
                            load(true)
                        axios.get(dd.url+"&&type="+tab()+onSearch(),
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            setHesab(res.data)
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }
                    
                }}> {dd.label} </li>
            }
         
        })
    }
    </>
    
 }
    

  return (
    <div>
        <Success show={modal} closeModal={()=>{setModal(false)
        setImageSer("")
            setShows(0)
            setDes({})
            setRadd("")
            setTaidRad(false)
        } } name="insidefjhfj" >
          {inModalShow()}
        </Success>
        <NotificationContainer/>
      <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                setActiver({a1:"active",a2:"",a4:"",a3:"", a5:"",a6:"",a7:"",a8:""})
                load(true)
        axios.get(url+"api/v1/panel/payouts?type=pending",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a1}>
              درخواست های برداشت
            </a>
            <a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"active",a4:"", a5:"",a6:"",a7:"",a8:""})
                load(true)
        axios.get(url+"api/v1/panel/payouts?type=mine",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a3}>
              بررسی های من
            </a>
            {m.includes("payouts")&&<><a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"",a4:"", a5:"",a6:"",a7:"active",a8:""})
                load(true)
        axios.get(url+"api/v1/panel/payouts?type=first-a",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a7}>
              تایید اولیه
            </a>
            <a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"",a4:"", a5:"",a6:"",a7:"",a8:"active"})
                load(true)
        axios.get(url+"api/v1/panel/payouts?type=first-r",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a8}>
              رد اولیه
            </a></>}
            {m.includes("payouts-verify")?<a onClick={()=>{
                setActiver({a1:"",a2:"active" , a3:"",a4:"", a5:"",a6:"",a7:"",a8:""})
                load(true)
             axios.get(url+"api/v1/panel/payouts?type=completed",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
             } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a2}>
              تایید نهایی
            </a>:null}
            {m.includes("payouts-verify")?<a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"" ,a4:"active" , a5:"",a6:"",a7:"",a8:""})
                load(true)
             axios.get(url+"api/v1/panel/payouts?type=cancelled",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
             } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a4}>
              رد نهایی
            </a>:null}
            <a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"" ,a4:"" , a5:"active",a6:"",a7:"",a8:""})
                load(true)
             axios.get(url+"api/v1/panel/payouts?type=approved",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
             } }).then(res=>{
            load(false)
            setHesab(res.data)
            }).catch(err=>{
            load(false)
            console.log(err);
             })
            }} className={activer.a5}>
              تکمیل شده
            </a>
            {user.dataes.permissions.find(e=>e.name==="payouts-certificate")&&<a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"" ,a4:"" , a5:"",a6:"active",a7:"",a8:""})
                load(true)
             axios.get(url+"api/v1/admin/payouts/certificates",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
             } }).then(res=>{
            load(false)
            setHesab(res.data)
            }).catch(err=>{
            load(false)
            console.log(err);
             })
            }} className={activer.a6}>
              CERTIFICATE
            </a>}
        </div>
        <>
            <div className='transAction'>
                <div className='eachTicket'>
                    <div className='bb'> کل درخواست های ثبت شده برای برداشت  : </div>
                    <p className='mt f10'> *برای تایید یا رد درخواست بر روی آیکون   
                        <MdAdsClick 
                        style={{fontSize:"20px",verticalAlign:"middle", marginRight:"3px"}} />
                         کلیک نمایید </p>
                        <div className='table-responsive-md'>
                                   <input style={{width:"35%" , margin:"10px"}}
                                        className='searchIn'
                                     onChange={(e)=>{
                                        setSearchTerm(e.target.value)
                                     }} type='text' placeholder='جستجو نام کاربری ...'/>
                                     {activer.a5&&<select className='searchIn' onChange={(e)=>{
                                          load(true)
                                          let have = ()=>{
                                            if(e.target.value == 1){
                                                return "api/v1/admin/payouts/have_certificates?type=have"
                                            }else if(e.target.value == 2){
                                                return "api/v1/admin/payouts/have_certificates?type=not"
                                            }else{
                                                return "api/v1/panel/payouts?type=approved"
                                            }
                                          }
                                          
                                          axios.get(url+have(),
                                         { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                          } }).then(res=>{
                                         load(false)
                                         setHesab(res.data)
                                         }).catch(err=>{
                                         load(false)
                                         console.log(err);
                                          })
                                     }}>
                                        <option style={{color:"black"}} value={0}>فیلتر بر اساس سرتیفیکیت</option>
                                        <option style={{color:"black"}} value={1}>بارگزاری شده</option>
                                        <option style={{color:"black"}} value={2}>بارگزاری نشده</option>
                                     </select>}
                        <table border={1} className="table table-hover">
                            <thead >
                                <tr>
                                    <th> کاربر </th>
                                    <th> حساب </th>
                                    <th> سرور </th>
                                    <th> ورود </th>
                                    <th> گذرواژه </th>
                                    <th> مرحله </th> 
                                    <th> تاریخ </th>      
                                    <th> بروزرسانی </th>      
                                    {activer.a2==="active" || activer.a4 === "active"?
                                    <th> انتخاب توسط </th>:null}      
                                    <th className='lastTh'> {activer.a5==="active"?"وضعیت":""} </th>    
                                </tr>
                                
                            </thead>
                            <tbody>
                                {hesabs()}
                            </tbody>

                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(hesab.prev_page_url !== null){
                                    load(true)
                                    axios.get(hesab.prev_page_url+"&&type="+tab()+onSearch(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                    } }).then(res=>{
                                    load(false)
                                    setHesab(res.data)
                                    }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(hesab.next_page_url !== null){          
                                    load(true)
                                axios.get(hesab.next_page_url+"&&type="+tab()+onSearch(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setHesab(res.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>
            </div>
            </>
       </div>
    </div>
  )
}
