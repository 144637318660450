/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect } from 'react'
import { MdAdsClick } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Success from '../Success/Success';
import axios from 'axios';
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
export default function SupportGift() {
const [modal , setModal] = useState(false)
const [users , setUsers] = useState({data:[] , links:[] })
const [pakages , setPakages] = useState([])
const [userD , setUser] = useState({})
const [selectedCategory, setSelectedCategory] = useState(null);
const [selectedProduct, setSelectedProduct] = useState(null);
const [auth , setAuth] = useState({data:[] , links:[] })
const [btn , setBtn] = useState([])
const [showUser , setShowUser] = useState(false)
const [show , setShow] = useState(false)
const [imageSer , setImageSer] = useState([false,""])
const [id , setId] = useState("")

// Handle category change
const handleCategoryChange = (e) => {
  const categoryId = e.target.value;
  const category = pakages.find((cat) => cat.id === parseInt(categoryId));
  setSelectedCategory(category);
  setSelectedProduct(null); // Reset product selection when category changes
};

// Handle product change
const handleProductChange = (e) => {
  const productId = e.target.value;
  const product = selectedCategory.products.find(
    (prod) => prod.id === parseInt(productId)
  );
  setSelectedProduct(product);
};

// Handle button click to send Axios request
const handleSubmit = () => {
  if (selectedCategory && selectedProduct) {
    axios
      .post(url+"api/v1/admin/accounts/gift", {
        user_id: userD.id,
        product_id: selectedProduct.id,
      },{
        headers: { "Authorization": `bearer ${Cookies.get("token")}`}
      })
      .then((response) => {
        NotificationManager.success("حساب هدیه با موفقیت ساخته شد")
        console.log(response.data);
        setModal(false)
      })
      .catch((error) => {
        NotificationManager.error("انجام نشد مشکل در سرور")
        console.log(error);
      });
  }
};
 const inModalShow = ()=>{
    return <div style={{
        display:"grid",
        gap:"10px",
        padding:"10px",
        minWidth:"300px"
    }}>
    <div className='bb'> حساب هدیه برای {userD.username} </div>
    <label htmlFor="category">نوع حساب را انتخاب نمایید</label>
    <select id="category" onChange={handleCategoryChange}>
      <option value="">-- نوع حساب --</option>
      {pakages.map((category) => (
        <option key={category.id} value={category.id}>
          {category.title}
        </option>
      ))}
    </select>
    {selectedCategory && (
      <>
        <label htmlFor="product">نوع محصول را انتخاب نمایید :</label>
        <select id="product" onChange={handleProductChange}>
          <option value="">-- محصول --</option>
          {selectedCategory.products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.title}
            </option>
          ))}
        </select>
      </>
    )}

    {/* Submit button (visible only when both category and product are selected) */}
    {selectedCategory && selectedProduct && (
      <button className="btn mt" onClick={handleSubmit}>ساخت حساب هدیه</button>
    )}
  </div>
 }
 const { load , url } = useContext(UserContext);
 
 useEffect(()=>{
    load(true)
    axios.get(url+"api/v1/panel/users?type=users",
        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
    } }).then(res=>{
        load(false)
        setUsers(res.data.data)
    }).catch(err=>{
        load(false)
        console.log(err);
    })
 },[])

 const usersShow = ()=>{
    return <>
    {users.data.map(d=>{
          if(d.roles[0]){
            return <tr className='chatUsers' key={d.username}>
                     <th className='lastTh'>{d.id}</th>
                     <th> {d.username} </th>
                     <th> {d.first_name !== null?
                    d.first_name + " " + d.last_name:
                    "ثبت نشده" 
                    } </th>
                     <th> {d.roles[0].fa_name} </th>
                     <th className='lastTh' > 
                     <MdAdsClick onClick={()=>{
                        setUser(d)
                       setModal([])
                       load(true)
                       axios.get(url+"api/v1/admin/accounts/gift/details",
                           { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                       } }).then(res=>{
                           load(false)
                           console.log(res.data);
                           setPakages(res.data.packages)
                       }).catch(err=>{
                           load(false)
                           console.log(err);
                       })
                    }} /> </th>    
                 </tr>
          } 
        
    })}
    </>
 }
 const liShows = () =>{
   if(active.a1){
    return <>
    {
        users.links.map(dd=>{
            let c = ()=>{
                if(dd.active){
                    return "ac"
                }else{
                    return null
                }
            }
            if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                return <li key={dd.label} className={"page__numbers "+ c()} onClick={()=>{
                    if(dd.url !== null){
                        load(true)
                    axios.get(dd.url+"&&type=users",
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                       
                        setUsers(res.data.data)
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                    })
                    }
                }}> {dd.label} </li>
            }
         
        })
    }
    </>
   }else{
    return <>
    {
        auth.links.map(dd=>{
            let c = ()=>{
                if(dd.active){
                    return "ac"
                }else{
                    return null
                }
            }
            if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                return <li key={dd.label} className={"page__numbers "+ c()} onClick={()=>{
                    if(dd.url !== null){
                        load(true)
                    axios.get(dd.url,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false) 
                        setAuth(res.data)
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                    })
                    }
                }}> {dd.label} </li>
            }
         
        })
    }
    </>
   }
    
 }
 const [active , setActive] = useState({a1:"active",a2:""})
 const authShow = ()=>{
  const translator = (n)=>{
    if(n==="First Step"){
        return "مرحله اول"
    }else if(n === "Second Step"){
        return "مرحله دوم"
    }else if(n === "Pro Trader"){
        return "پرو تریدر"
    }else if(n === "Real"){
        return "REAL"
    }else{
        return n
    }
}
  return <>
    {auth.data.map(d=>{
      
          
      return <>
              <tr className='font'>
              <th> {d.id} </th>
              <th> {d.user.username} </th>
                 <th> { d.order.product.category.title+ " " + d.order.product.title} </th>
                 <th> 
                  { d.order.server + "/"+ d.order.platform}
                 </th>
                  <th> {d.login} </th>
                  <th> {translator(d.type)} </th>
                  <th> {d.created_at} </th>
                  <th> {d.updated_at} </th>
                 
              </tr>                   
             </>
    })}
  </>
}
 const mainShow = ()=>{

   return <>
            <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                setActive({a1:"active",a2:""})
                load(true)
                axios.get(url+"api/v1/panel/users?type=users",
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    setUsers(res.data.data)
                }).catch(err=>{
                    load(false)
                    console.log(err);
                })
            }} className={active.a1}>
                ایجاد حساب هدیه
            </a>
            <a onClick={()=>{
                setActive({a1:"",a2:"active"})
                load(true)
                axios.get(url+"api/v1/admin/accounts/gift/show",
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    console.log(res.data.data);
                    setAuth(res.data)
                }).catch(err=>{
                    load(false)
                    console.log(err);
                })
            }} className={active.a2}>
                حساب های هدیه
            </a>
            </div>
        </div>
            <div  className='transAction'>
           
                {active.a1?<div className='eachTicket'>
                    <div className='table-responsive-md'>
                        <table style={{color:"white",textAlign:"center"}} border={1} className="table table-hover">
                            <thead >
                                <tr>
                                    <th style={{textAlign:"center"}}> ID </th>
                                    <th className='dfjc'> نام کاربری 
                                        <input
                                        className='searchIn'
                                     onChange={(e)=>{
                      setTimeout(()=>{
                          if(e.target.value.length >2){
                          load(true)
                          axios.get(url+"api/v1/panel/users/search?query="+e.target.value +"&&type=users",
                              { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                          } }).then(res=>{
                              load(false)
                             setUsers(res.data.data);
                          }).catch(err=>{
                              load(false)
                              console.log(err);
                          })
                      }else{
                          load(true)
                          axios.get(url+"api/v1/panel/users?type=users",
                              { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                          } }).then(res=>{
                              load(false)
                              console.log(res.data);
                              setUsers(res.data.data)
                          }).catch(err=>{
                              load(false)
                              console.log(err);
                          })
                        }
                        },2000)
                        }} type='text' placeholder='جستجو نام کاربری'/> </th>            
                                    <th> نام کامل </th>
                                    <th> نقش </th>
                                    <th className='lastTh'>  </th>    
                                </tr>
                                
                            </thead>
                            <tbody>
                                {usersShow()}
                            </tbody>
                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(users.prev_page_url !== null){
                                    load(true)
                                axios.get(users.prev_page_url+"&&type=users",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(users.next_page_url !== null){
                                    load(true)

                                axios.get(users.next_page_url+"&&type=users",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>:
                <div className='eachTicket'>
                <p className='bb'>با کلیک بر روی آیکون کلیک اطلاعات حساب کاربر را وارد کنید : </p>
                <div className='table-responsive-md'>
                    <table border={1} className="table table-hover  ">
                        <thead >
                            <tr className='font'>
                                <th style={{textAlign:"center"}}> # </th>
                                <th> کاربر </th>
                                <th> محصول </th>
                                <th> سرور </th>     
                                <th> حساب </th>     
                                <th> مرحله </th>                 
                                <th> تاریخ درخواست </th>
                                <th> بروزرسانی </th>
                            </tr>
                        </thead>
                        <tbody>
                            {authShow()}
                        </tbody>
                    </table>
                    <ul class="page">
                               {active.a1?<><li onClick={()=>{
                                if(users.prev_page_url !== null){
                                    load(true)
                                axios.get(users.prev_page_url+"&&type=users",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(users.next_page_url !== null){
                                    load(true)
                                       
                                axios.get(users.next_page_url+"&&type=users",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons">
                                <IoArrowForwardCircleSharp />
                               </span>
                               </li>
                               </>:
                                <><li onClick={()=>{
                                  if(users.prev_page_url !== null){
                                      load(true)
                                  axios.get(users.prev_page_url,
                                      { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                  } }).then(res=>{
                                      load(false)
                                     
                                      setAuth(res.data)
                                  }).catch(err=>{
                                      load(false)
                                      console.log(err);
                                  })
                                  }
                                 }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                                 {liShows()}
                                 <li onClick={()=>{
                                  if(users.next_page_url !== null){
                                      load(true)
                                         
                                  axios.get(users.next_page_url,
                                      { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                  } }).then(res=>{
                                      load(false)
                                      setAuth(res.data)
                                  }).catch(err=>{
                                      load(false)
                                      console.log(err);
                                  })
                                  }
                                 }} className="page__btn"><span class="material-icons">
                                  <IoArrowForwardCircleSharp />
                                 </span>
                                 </li>
                                 </>
                               }
                        </ul>
                </div>

            </div>
                }
            </div>
            </>
  
 }
 
  return (
    <div>
        <Success show={modal} closeModal={()=>{setModal(false)} } name="ihjeffse" >
          {inModalShow()}
        </Success>
        <NotificationContainer/>
        {mainShow()}
    </div>
  )
}
