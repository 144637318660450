/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {React,useContext, useEffect,useState} from 'react'
import "./Safir.css"
import UserContext from "../UserContext/UserContext"
import { IoIosCopy } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import { GiEmptyHourglass } from "react-icons/gi";
export default function Safir() {
    
const { user  } = useContext(UserContext);
const sucRef = ()=>{
    return <>
     {user.dataes.referals.map(d=>{
        return <div className='sucRef'>
        <TiTick color='green' />   با خرید حساب توسط "  {d.user_buy_name} "  مبلغ {d.commission}$ به پاداش شما افزوده شد.
     </div>
     })}
    </>
}
const [prc,setPrice] = useState(0)
const [refCopy,setCopy] = useState("https://my.fenefx.net/?ref="+user.dataes.referral_link.split('?ref=')[1] )
const [refCopy2,setCopy2] = useState(user.dataes.referral_link.split('?ref=')[1])
useEffect(()=>{
    let x = []
    user.dataes.referals.map(dd=>{
        x.push(+dd.commission)
    })
    setPrice(x.reduce((a,v) =>  a = a + v , 0 ))
},[])
const allUsers =()=>{
   return<>
    {user.dataes.all_referrals.map(d=>{
        return <div className='refCode mt5'>
        <div className='name_family'> {d.username}</div> 
        <div> {d.first_buy === 1 ? <TiTick color='green' /> : <MdCancel color='red' /> }   </div>
     </div>
    })}
   </>
}
  return (
    <>
        <div className="app-header-navigation">
				<div className="tabs">
					<a className='active' >
						وضعیت دعوت ها
					</a>
				</div>
        </div>
        <div className='row m10'>
                <div className='col-xl-4 '> 
                    <div className='userRef'>
                         
                         <div className='eachref'>
                            <div >  پاداش کل  :  </div> 
                            <div className='profitPrice'> {prc.toFixed(2)}$ </div>
                         </div>
                         <div className='eachref'>
                            <div > کد دعوت   </div> 
                            <div> <IoIosCopy onClick={() => {
                                setCopy2(" کپی شد ")
                                setTimeout(()=>{
                                   setCopy2(user.dataes.referral_link.split('?ref=')[1])
                                },1000)
                         navigator.clipboard.writeText(user.dataes.referral_link.split('?ref=')[1])}} />
                          {refCopy2} 
                           </div>
                         </div>
                         <div className='eachref '>
                            <div > افراد دعوت شده  </div> 
                            <div> {user.dataes.all_referrals.length}  </div>
                         </div>
                         <div className='eachref bn'>
                            <div >  حساب های  دعوت شده  </div> 
                            <div> {user.dataes.referals.length}  </div>
                         </div>
                         
                        
                    </div>
                    <fieldset className="refField mobPad">
                        <legend>  افراد دعوت شده </legend>
                        {user.dataes.all_referrals.length === 0 ?
                                <div className='refCode mt5'>
                                  <div style={{textAlign:"center" , color:"GrayText"}}>
                                     <GiEmptyHourglass /> بدون دعوت </div> 
                                </div>:null}
                        {allUsers()}
                    </fieldset>
                </div> 
                <div className='col-xl-8 pr0'> 
                    <fieldset className='refField '>
                        <legend>  اطلاعات کلی دعوت ها </legend>
                        <div className='refCode'>
                           <div > لینک دعوت شما :</div> 
                           <div style={{textAlign:"left"}}> <IoIosCopy onClick={() => {
                             setCopy(" کپی شد ")
                             setTimeout(()=>{
                                setCopy("https://my.fenefx.net/?ref="+user.dataes.referral_link.split('?ref=')[1])
                             },1000)
                           navigator.clipboard.writeText("https://my.fenefx.net/?ref="+user.dataes.referral_link.split('?ref=')[1]);}} /> {refCopy }  </div>
                        </div>
                        <div className='bb mt30'> حساب های خریداری شده با کد دعوت شما :  </div>
                        {user.dataes.referals.length === 0?<div style={{textAlign:"center" , color:"GrayText"}} className='sucRef'>
                          <GiEmptyHourglass /> حسابی با کد دعوت شما خریداری نشده </div>:null}
                        {sucRef()}
                    </fieldset>
                </div>    
            </div>
        

    </>
      
  )
}
