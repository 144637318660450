/* eslint-disable jsx-a11y/anchor-is-valid */
import {React , useState , useContext} from 'react'

import logo from "../../photoes/logo.png"
import validator from 'validator';
import axios from 'axios';
import { BiSolidLogInCircle } from "react-icons/bi";
import { TbPasswordFingerprint } from "react-icons/tb";
import UserContext from "../UserContext/UserContext"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Cookies from 'js-cookie';
const AddUser = (props) => {
	const [number, setNumber] = useState("")
	const [role, setRole] = useState("")
	
	const numChange = (event) => {
        setNumber(event.target.value);
    };
	const {load , url , user } = useContext(UserContext);                   

	const [password, setPassword] = useState("")
	const [password2, setPassword2] = useState("")
	const [first_name, setname] = useState("")
	const [last_name, setLast] = useState("")
	

	const regCheck = ()=>{
		load(true)
		if(password === "" || password2 === "" || number === "" ||
		  first_name ==="" || last_name ==="" || role ===""  ){
			load(false)
			NotificationManager.error("لطفا همه فیلد ها را پر کنید","ثبت نام انجام نشد",5000)
		}else{
			if(password === password2){
				let data = {
					username: number ,
					password: password , 
					first_name:first_name,
					last_name:last_name,
					user_access:role,
				   }
				if(validator.isEmail(number) || validator.isMobilePhone(number , ['fa-IR'])){
					load(true)
					axios.post(url+"api/v1/admin/register_us", data ,
						{ headers: { "Authorization": `bearer ${Cookies.get('token')}` } },
						).then(
						(res)=>{
							load(false)
							props.update()
							NotificationManager.success("با استفاده از اطلاعات وارد شده در ثبت نام وارد شوید","ثبت نام با موفقیت انجام شد",5000)
							
						}
					).catch((err)=>{
						load(false)
						console.log(err);
							if(err.response !== null && err.response !== undefined){
								
								let x = err.response.data
								if(x === '{"username":["The username has already been taken."]}'){
									NotificationManager.error(" نام کاربری قبلا ثبت شده ","ثبت نام انجام نشد",5000)
								}else if(x === '{"username":["The username has already been taken."],"password":["The password must be at least 8 characters."]}'){
									NotificationManager.error(" گذرواژه حداقل باید 8 کاراکتر داشته باشد","ثبت نام انجام نشد",5000)
								}else if(x === '{"password":["The password must be at least 8 characters."]}'){
									NotificationManager.error(" گذرواژه حداقل باید 8 کاراکتر داشته باشد ","ثبت نام انجام نشد",5000)
								}else{
									NotificationManager.error("مشکل در برقراری ارتباط با سرور","ثبت نام انجام نشد",5000)
								}
							}else{
								
								NotificationManager.error("مشکل در برقراری ارتباط با سرور","ثبت نام انجام نشد",5000)
							}
						
					})
				}else{
					load(false)
					NotificationManager.error("شماره همراه یا ایمیل معتبر نمی باشد","ثبت نام انجام نشد",5000)
				}
			}else{
				load(false)
				NotificationManager.error("عدم تطابق رمز عبور با تکرار آن","ثبت نام انجام نشد",5000)
			}
		}
	}
	const afterReg = ()=>{
     
		return <>
		
		<form onSubmit={(e)=>{
				e.preventDefault()
			}} className="login">
				<div className="login__field">
					<input onChange={numChange} type="text" className="login__input" placeholder=" ایمیل کاربر  "/>
					<BiSolidLogInCircle color='#cca64d' />
				</div>
				<div className="login__field">
					<input value={first_name} onChange={(e)=>{
                            setname(e.target.value)
					}} type="text" className="login__input" placeholder="نام کاربر "/>
					
				</div>
				<div className="login__field">
					<input value={last_name} onChange={(e)=>{
                     setLast(e.target.value)
					}} type="text" className="login__input" 
					placeholder="نام خانوادگی کاربر "/>
				</div>
				<div className="login__field">
					<i className="login__icon fas fa-lock"></i>
					<input onChange={(e)=>{
						setPassword(e.target.value)
					}} type="password" className="login__input" placeholder="رمز عبور"/>
					<TbPasswordFingerprint color='#cca64d' />
				</div>
                <div className="login__field">
					<i className="login__icon fas fa-lock"></i>
					<input onChange={(e)=>{
                      setPassword2(e.target.value)
					}}  type="password" className="login__input" placeholder=" تکرار رمز عبور"/>
					<TbPasswordFingerprint color='#cca64d'/>
				</div>
				
				<div className="login__field">
					
					<select onChange={(e)=>{
                      setRole(e.target.value)
					}} value={role}  type="text" className="login__input">

						<option value=""> نقش کاربر را انتخاب نمایید </option>
						<option value="monitoring"> مانیتورینگ </option>
						<option value="support"> پشتیبانی </option>
						{user.dataes.permissions.find(e=>e.name==="create-admin")
						?<option value="admin"> ادمین </option>:null}
					</select>
					
				</div>
				<button onClick={regCheck} className="button login__submit">
					<span className="button__text"> ثبت نام </span>
					<i className="button__icon fas fa-chevron-right"></i>
				</button>				
			</form>
			
		</>
	 
	}
  return (
    <div className="container1">
		<NotificationContainer/>
	<div className="screen mt16">
		<div className="screen__content">
      <div className='logoCon'>
      <img className='logo' src={logo} alt=''/>
      </div>
			{afterReg()}
		</div>
		<div className="screen__background">	
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</div>
  )
}

export default AddUser