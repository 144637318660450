/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect } from 'react'
import "./AdminUsers.css"
import { MdAdsClick } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Success from '../Success/Success';
import axios from 'axios';
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
export default function AdminChatPanel(props) {
const [modal , setModal] = useState(false)
const [users , setUsers] = useState({data:[] , links:[] })
const [userD , setUser] = useState([[],0])
const today = new Date().toISOString().split('T')[0];
 const inModalShow = ()=>{
    const filteredMessages = userD[0].flatMap(chat =>
        chat.messages.filter(message => {
          const messageDate = message.created_at.split('T')[0]; 
          return message.type == "support" && messageDate == today && message.sender_id == userD[1];
        })
      );
      const filteredMessagesToday = userD[0].filter(chat =>
        chat.messages.some(message => {
          const messageDate = message.created_at.split('T')[0];
          return message.type == "support" && messageDate == today && message.sender_id == userD[1];
        })
      );
   return <div style={{color:"white",minWidth:"40vw"}}>
      <h5 className='bb' style={{textAlign:"center"}}> {userD[2]} </h5>
      <div style={{
        border:"1px solid white",
        borderRadius:"10px",
        padding:"10px",
        margin:"10px"
      }} className='dfjc mt'>
        <div>تعداد کل چت های انتخاب شده :</div>
        <div>{userD[0].length}</div>
      </div>
      <div style={{
        border:"1px solid white",
        borderRadius:"10px",
        padding:"10px",
        margin:"10px"
      }} className='dfjc mt'>
        <div>تعداد کل پیام های ارسال شده :</div>
        <div>{userD[0].flatMap(chat =>
             chat.messages.filter(message => message.type == "support" && message.sender_id == userD[1])
             ).length}</div>
      </div>
      <div style={{
        border:"1px solid white",
        borderRadius:"10px",
        padding:"10px",
        margin:"10px"
      }} className='dfjc mt'>
        <div>تعداد کل پیام های ارسال شده امروز :</div>
        <div>{filteredMessages.length}</div>
      </div>
      <div style={{
        border:"1px solid white",
        borderRadius:"10px",
        padding:"10px",
        margin:"10px"
      }} className='dfjc mt'>
        <div>تعداد کل چت های امروز :</div>
        <div>{filteredMessagesToday.length}</div>
      </div>
      <button onClick={()=>{
        props.setPerson(userD[0])
      }} style={{margin:"10px" , background:"white"}} 
      className='btn '>  مشاهده چت های {userD[2]}  </button>
   </div>
 }
 const { load , url } = useContext(UserContext);
 const filterByTime = ()=>{
    if(startTime !== "" && endTime!==""){
        return "&&from="+startTime.split('T')[0]+"&&to="+endTime.split('T')[0]
    }else{
        return ""
    }
 }
 useEffect(()=>{
    load(true)
    axios.get(url+"api/v1/panel/users?type=active-support",
        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
    } }).then(res=>{
        load(false)
        
        setUsers(res.data.data)
    }).catch(err=>{
        load(false)
        console.log(err);
    })
 },[])

 const usersShow = ()=>{
    return <>
    {users.data.map(d=>{
          if(d.roles[0]){
            return <tr className='chatUsers' key={d.username}>
                     <th className='lastTh'>{d.id}</th>
                     <th> {d.username} </th>
                     <th> {d.first_name !== null?
                    d.first_name + " " + d.last_name:
                    "ثبت نشده" 
                    } </th>
                     <th> {d.roles[0].fa_name} </th>
                     <th className='lastTh' > 
                     <MdAdsClick onClick={()=>{
                       if(startTime!==""||endTime!==""){
                        let name = d.first_name !== null?
                        d.first_name + " " + d.last_name:
                        "ثبت نشده" 
                       load(true)
                       axios.get(url+"api/v1/admin/chats/support_chats?user_id="+d.id+filterByTime(),
                           { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                       } }).then(res=>{
                           load(false)
                           console.log(res.data);
                           setUser([res.data , d.id , name])
                           setModal(true)
                       }).catch(err=>{
                           load(false)
                           console.log(err);
                       })
                       }else{
                        NotificationManager.warning("لطفا بازه زمانی مورد نظر را انتخاب نمایید")
                       }
                    }} /> </th>    
                 </tr>
          } 
        
    })}
    </>
 }
 const liShows = () =>{
    return <>
    {
        users.links.map(dd=>{
            let c = ()=>{
                if(dd.active){
                    return "ac"
                }else{
                    return null
                }
            }
            if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                return <li key={dd.label} className={"page__numbers "+ c()} onClick={()=>{
                    if(dd.url !== null){
                        load(true)
                        
                        
                    axios.get(dd.url+"&&type=active-support",
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                       
                        setUsers(res.data.data)
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                    })
                    }
                }}> {dd.label} </li>
            }
         
        })
    }
    </>
    
 }
 const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  // Function to handle form submission or validation
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate that the end time is after the start time
    if (new Date(startTime) >= new Date(endTime)) {
      alert("End time must be after start time!");
      return;
    }
    // Do something with the selected time range
    console.log("Start Time:", startTime);
    console.log("End Time:", endTime);
  };
 const mainShow = ()=>{

   return <>
            <div  className='transAction'>
            <form style={{
                color:"white",
                direction:"rtl", 
                textAlign:"right",
                display:"grid",
                gap:"10px",
                width:"50%"
                }} onSubmit={handleSubmit}>
               <div className='dfjc'>
                 <label>از تاریخ : </label>
                 <input
                   className='searchIn'
                   style={{marginRight:"10px"}}
                   type="date"
                   value={startTime}
                   onChange={(e) => setStartTime(e.target.value)}
                   
                 />
               </div>
               <div className='dfjc'>
                 <label>تا تاریخ :</label>
                 <input
                   className='searchIn'
                   style={{marginRight:"10px"}}
                   type="date"
                   value={endTime}
                   onChange={(e) => setEndTime(e.target.value)}
                   
                 />
               </div>
               <div>  
                   <button style={{
                    width:"100%",
                    marginBottom:"10px",
                    outline:"0",
                    borderRadius:"10px"
                   }} onClick={()=>{
                    setStartTime("")
                    setEndTime('')
                   }} > حذف فیلتر </button>
                   
               </div>
               
             </form>
                <div className='eachTicket'>
                    <div className='table-responsive-md'>
                        <table style={{color:"white",textAlign:"center"}} border={1} className="table table-hover">
                            <thead >
                                <tr>
                                    <th style={{textAlign:"center"}}> ID </th>
                                    <th className='dfjc'> نام کاربری 
                                        <input
                                        className='searchIn'
                                     onChange={(e)=>{
                      setTimeout(()=>{
                          if(e.target.value.length >2){
                          load(true)
                          axios.get(url+"api/v1/panel/users/search?query="+e.target.value +"&&type=active-support",
                              { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                          } }).then(res=>{
                              load(false)
                             setUsers(res.data.data);
                          }).catch(err=>{
                              load(false)
                              console.log(err);
                          })
                      }else{
                          load(true)
                          axios.get(url+"api/v1/panel/users?type=active-support",
                              { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                          } }).then(res=>{
                              load(false)
                              console.log(res.data);
                              setUsers(res.data.data)
                          }).catch(err=>{
                              load(false)
                              console.log(err);
                          })
                        }
                        },2000)
                        }} type='text' placeholder='جستجو نام کاربری'/> </th>            
                                    <th> نام کامل </th>
                                    <th> نقش </th>
                                    <th className='lastTh'>  </th>    
                                </tr>
                                
                            </thead>
                            <tbody>
                                {usersShow()}
                            </tbody>

                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(users.prev_page_url !== null){
                                    load(true)
                                axios.get(users.prev_page_url+"&&type=active-support",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(users.next_page_url !== null){
                                    load(true)

                                axios.get(users.next_page_url+"&&type=active-support",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>
            </div>
            </>
  
 }
 
  return (
    <div>
        <Success show={modal} closeModal={()=>{setModal(false)} } name="iheffse" >
          {inModalShow()}
        </Success>
        <NotificationContainer/>
        {mainShow()}
    </div>
  )
}
