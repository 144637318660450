// ComingSoon.js
import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <div className="content4343">
        <h1 className='ah'>به زودی برمی‌گردیم ...</h1>
        <p className='pe' style={{
            marginTop:"20px"
        }}>درحال بروزرسانی زیرساخت ها</p>
        <p>از شکیبایی شما سپاسگذاریم</p>
      </div>
    </div>
  );
};

export default ComingSoon;
