import React from 'react'
import { useNavigate } from 'react-router-dom';
import Dashbord from './components/Dashbord/Dashbord';
function DashWithHook(Component) {
 
    function CompWithHook(props) {
      
      const navigate = useNavigate();
  
      return <Component {...props} navigate={navigate} />;
    }
  
    return CompWithHook;
  }
  
  export default DashWithHook(Dashbord);