/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect,useContext, useState } from 'react'
import UserContext from "../UserContext/UserContext"

import "./Wallet.css"
import axios from 'axios';
import Cookies from 'js-cookie';
import { PiHandDepositFill , PiHandWithdrawFill } from "react-icons/pi";
import { RiInformation2Fill } from "react-icons/ri";
import Success from '../Success/Success';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { IoIosCopy } from "react-icons/io";
import QRCode from "react-qr-code";
export default function Wallet() {
const { load , url , user} = useContext(UserContext);
const [transes , setTranses] = useState([])
const [each , setEach] = useState({})
const [modal , setModal] = useState(false)
const [shabake , setShab] = useState([])
const [dis , setDis] = useState(true)
const [href , setHref] = useState("#")
const [inMod , setInMod] = useState(false)
const [tx , setTx] = useState("")
const [amount , setAmount] = useState("")
const [modal1 , setModal1] = useState(false)
const [wid , setWid] = useState("")
useEffect(()=>{
  load(true)
  axios.get(url+"api/v1/panel/transactions",
      {
          headers: {
              "Authorization": `bearer ${Cookies.get('token')}`,
              'Content-Type': 'multipart/form-data'
          }
      }).then(res => {
          load(false)
          setTranses(res.data)
          console.log(res.data);
          axios.get(url+"api/v1/panel/transaction",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setShab(res.data.currency)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
      }).catch(err => {
          load(false)
          console.log(err.response);
      })
      load(true)
  
},[])

const transShowDeposit = ()=>{
  let m = []
  transes.map(d=>{
    if(d.type === "deposit" && (d.transition_type === "Commission" || d.transition_type === "Wallet Recharge") && d.amount > 0){
       m.push(d)
    }
  })
  if(m.length === 0){
    return <fieldset className='c1 ml10'>
                    <legend> واریزی های من </legend> 
    <div className='empty'>
      تاریخچه واریزی شما خالی می باشد
    </div>
    </fieldset>
  }else{
     return <fieldset className='c1 ml10'>
                    <legend> واریزی های من </legend>
                    {transes.map(d=>{
                      if(d.type === "deposit" && d.amount > 0){
                        if(d.transition_type === "Commission"){
                          return <div key={d.id} className='sucRef'>
                          <PiHandDepositFill />    {} در تاریخ " {d.updated_at} "  مبلغ {d.amount}$ از بابت ( کمیسیون ) به کیف پول شما افزوده شد.
                          </div>
                        }else if(d.transition_type === "Wallet Recharge"){
                          if(d.verified_by === 0){
                            return <div key={d.id} className='sucRef'>
                          <PiHandDepositFill />   {} در تاریخ " {d.updated_at}  "
                            مبلغ {d.amount}$ از بابت ( واریز ) به کیف پول شما افزوده شد.
                             </div>
                          }else{
                            if(d.gateway===4){
                              return <div key={d.id} className='sucRef'>
                          <PiHandDepositFill />   {} در تاریخ " {d.updated_at}  "
                            مبلغ {d.amount}$ از بابت ( عودت وجه ) به کیف پول شما افزوده شد.
                             </div>
                            }else{
                              return <div key={d.id} className='sucRef'>
                          <PiHandDepositFill />   {} در تاریخ " {d.updated_at}  "
                            مبلغ {d.amount}$ از بابت ( واریز سود ) به کیف پول شما افزوده شد.
                             </div>
                            }
                          }
                        }
                      }
                    })}
          </fieldset>
  }
 
}
const transShowWithdraw = ()=>{
  let m = []
  transes.map(d=>{
    if(d.type === "withdraw"){
       m.push(d)
    }
  })
  if(m.length === 0){
    return <fieldset className='c1 ml10'>
                    <legend> برداشت های من </legend> 
    <div className='empty'>
      تاریخچه برداشت شما خالی می باشد
    </div>
    </fieldset>
  }else{
     return <fieldset className='c1 ml10'>
                    <legend> برداشت های من </legend>
                    {transes.map(d=>{
                      if(d.type === "withdraw"){
                        if(d.status === 0){
                          return <div key={d.id} className='sucRef dfjc'>
                          
                           <div> <PiHandWithdrawFill />   برداشت در تاریخ "{d.updated_at}" به مبلغ  {d.amount} $  
                           در حال انجام  است. </div>
                           <div className="orange joziat"> در حال بررسی </div>
                            </div>
                        }else if(d.status === 1){
                          if(d.transactionable_type==="App\\Models\\Withdraw"){
                            return <div key={d.id} className='sucRef dfjc'>
                                    <div>
                                     <PiHandWithdrawFill />   برداشت  در تاریخ " {d.updated_at}  "  به مبلغ  {d.amount} $  انجام شد.
                                   </div>
                                   <div style={{cursor:"pointer"}} onClick={()=>{
                                    load(true)
                                    axios.get(url+"api/v1/panel/withdraw/get_wallet?id="+d.transactionable_id , 
                                      { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                  } }).then(res=>{
                                    setWid(res.data.withdraw.address)
                                      load(false)
                                      setEach(d)
                                      setModal(true)
                                      console.log(res.data);
                                      
                                  }).catch(err=>{
                                      load(false)
                                      NotificationManager.error("مشکل در سرور بعدا دوباره امتحان نمایید","",4000)
                                      console.log(err);
                                  })
                                      
                                   }} className="green joziat"> جزئیات برداشت </div>
                                 </div>
                          }
                        }else if(d.status=== 2){
                          return <div key={d.id} className='sucRef dfjc'>
                                    <div>
                                     <PiHandWithdrawFill />  درخواست برداشت شما در تاریخ {d.updated_at} رد شد
                                   </div>
                                   <div style={{cursor:"pointer"}} onClick={()=>{
                                      setEach(d)
                                      setModal1(true)
                                      
                                   }} className="red joziat"> علت رد </div>
                                 </div>
                        }
                      }
                    })}
          </fieldset>
  }
 
}
const shabakeShow = ()=>{
 return <select className='selectArz' name="walletCharge"
 onChange={(e) => {

    if(e.currentTarget.value !== ""){
      load(true)
  axios.post(url+"api/v1/panel/wallet_recharge", {currency_id:e.target.value} , 
    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
} }).then(res=>{
    load(false)
    setHref([res.data.wallet,res.data.link])
    setDis(false)
    console.log(res.data);
    
    
}).catch(err=>{
    load(false)
    NotificationManager.error("مشکل در سرور بعدا دوباره امتحان نمایید","",4000)
    console.log(err);
}) 
    }else{
      setDis(true)
    }

}}>
<option value=""> ارز واریزی را انتخاب نمایید </option>

   {
    shabake.map(d=>{
     return <option key={d.id} value={d.id}> {d.name+" | "+ d.symbol} </option>
    })
   }

</select>
}
  const qr = ()=>{
    if(!dis){
      return <div className='varizCon f12'>
                   <div className='add '> آدرس ولت : </div>
                   <div className='wallet'> {href[0]}  <IoIosCopy onClick={()=>{
                    navigator.clipboard.writeText(href[0])
                    NotificationManager.success("آدرس ولت کپی شد" , "" ,3000)
                   }}/> </div>
                   <div className='add mt'> تصویر QR-CODE ولت : </div>
                   <div className='backQr'>
                     <QRCode style={{width:"60%"}} value={href[0]}  />
                   </div>
             </div>
    }
  }
  return (<>
           <div className="tabs">
              <a href className="active">
                   کیف پول
              </a>			
            </div>
            
            <div className='row' style={{marginBottom:"10px"}}>
              <div className="col-xl-4 pl0 mb mmt15">
                  <div className="card mb-4 mb-xl-0 mt15">
                              <div className='dfjcc'>
                                <div className='walletAmount '> {user.dataes.wallet}$ </div>
                              </div>
                              <div className='mt bb'>
                              <ul  className='messageCon m0 mobileMessageCon'>
                                    <p> <RiInformation2Fill className='information ml5' color='greenyellow' />  
                                    حداقل مبلغ قابل برداشت : 20 $ 
                                    </p>
                                    <p  > <RiInformation2Fill className='information ml5' color='greenyellow' />  
                                    برای درخواست واریز نوع ارز واریزی را انتخاب نمایید 
                                    </p>
                                    <p  > <RiInformation2Fill className='information ml5' 
                                    style={{boxShadow:"0px 0px 5px #ff1818"}} color='red' />  
                                      ( مهم )  واریزی های کمتر از 15 $ در شبکه ترون قابل پیگیری نمی باشد
                                    </p>
                               </ul>
                                        </div>
                                        <div className='mobBtn'>
                                        {shabakeShow()}
                                   <div className='flex btnCon'>  
                                     
                                      <a className='mt' style={{width:"48%"}} href={href[1]} target='_blank'>
                                      <button disabled={dis} style={{width:"100%"}} className='btnreply'>واریز</button>
                                      </a>
                                      <button disabled={user.dataes.wallet < 20? true: false} onClick={()=>{
                                        setInMod(true)
                                        setModal(true)
                                      }} className='btnreply mt'>برداشت</button>
                                    </div>
                                        </div>
                                        {qr()}
                                    </div>                  
              </div>
              <div className="col-xl-8 pr0 ">
                {transShowDeposit()}
                {transShowWithdraw()}
              </div>
            </div>
           <Success show={modal} name="wallet" closeModal={()=>{setModal(false)}} >
           {inMod?
           <div className='bardasht f12' >
            <div className='bb'> درخواست برداشت </div>
              <div style={{color:"red" , marginTop:"10px"}} className='f12' > * در وارد کردن آدرس کیف پول دقت لازم را داشته باشید </div>
              <div className='p10'> آدرس کیف پول : </div>
              <input style={{fontSize:"12px"}} className='form-control-select ' value={tx} onChange={(e)=>{setTx(e.target.value)}}/>
              <div className='p10'> مبلغ درخواستی به دلار ( $ ) : </div>
              <input style={{fontSize:"12px"}} placeholder={"حداکثر مبلغ قابل برداشت : "+user.dataes.wallet+" $"} 
                className='form-control-select '  value={amount} onChange={(e)=>{
                if (/^\d*\.?\d*$/.test(e.target.value)) {
                  setAmount(e.target.value)
                }
                }}/>
                <button disabled={tx !=="" && amount!==""?false:true} onClick={()=>{
                  if(user.dataes.confirmed_authentications.find(e=>e.type==="Video_auth")){
                    if(parseFloat(amount) > parseFloat(user.dataes.wallet) ){
                      NotificationManager.warning("مبلغ درخواستی بیش از مبلغ کیف پول شماست ")
                    }else{
                      load(true)
                      axios.post(url+"api/v1/panel/wallet_decharge",{
                        amount:amount,
                        address:tx
                      } ,
                          {
                              headers: {
                                  "Authorization": `bearer ${Cookies.get('token')}`
                              }
                          }).then(res => {
                              load(false)
                              console.log(res.data);
                              NotificationManager.success("درخواست برداشت با موفقیت ثبت شد","موفق",4000)
                              setTx("")
                              setAmount("")
                              setModal(false)
                              setInMod(false)
                              
                          }).catch(err => {
                              load(false)
                              NotificationManager.error("درخواست برداشت ثبت نشد کمی بعد دوباره امتحان نمایید",
                                "نا موفق",4000)
                              console.log(err.response);
                          })
                          
                    }
                  }else{
                    NotificationManager.warning("احراز هویت ویدیویی شما تکمیل نشده است، ابتدا احراز هویت ویدیویی را تکمیل نمایید")
                  }
                }} style={{width:"100%"}} className='btnreply mt'>درخواست برداشت</button>
           </div>
           :   <div className='varizCon f12 minW'>
                    <h6 className='bB p5'> اطلاعات تراکنش : </h6>
                    <div className='orderDetail bb'>
                        <div className='fl' style={{textAlign:"right" , minWidth:"60px"}}>  کد پیگیری </div>
                        <div className='longs'> { each.traceNumber} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'>  توضیحات  </div>
                        <div> {each.description} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl' style={{textAlign:"right" , minWidth:"60px"}}> شناسه تراکنش </div>
                        <div className='longs'> {each.transId} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'>  تاریخ انجام  </div>
                        <div> {each.updated_at} </div>
                    </div>
                   {wid!==""&& <div className='orderDetail'>
                        <div className='fl'>  آدرس کیف پول  </div>
                        <div> {wid} </div>
                    </div>}
                
                    
            </div>}
           </Success>
           <Success name="gytrd" show={modal1} closeModal={()=>{setModal1(false)}} >
           <div className='des'> 
                    <p className='why'> علت رد تراکنش در {each.created_at} :  </p> 
                    <p> {each.description} </p>
                    
                </div> 
           </Success>
            <NotificationContainer/>
   
            
  </>
             
  )
}
