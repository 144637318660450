import React from 'react'
import { useNavigate } from 'react-router-dom';
import SupportDashbord from './components/SupportDashboard/SupportDashboard';
function DashWithHook1(Component) {
 
    function CompWithHook(props) {
      
      const navigate = useNavigate();
  
      return <Component {...props} navigate={navigate} />;
    }
  
    return CompWithHook;
  }
  
  export default DashWithHook1(SupportDashbord);