/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaUsersGear } from "react-icons/fa6";
import React, { Component } from 'react';
import "../Dashbord/Dashbord.css"
import { PiHandDepositFill , PiHandWithdrawFill } from "react-icons/pi";
import SupportPardakht from "./SupportPardakht"
import { CiBarcode } from "react-icons/ci";
import logo from "../../photoes/logo.png"
import { FaListUl } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import UserContext from "../UserContext/UserContext"
import {  MdOutlineExitToApp , MdCampaign } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { SiAuthelia } from "react-icons/si";
import { IoIosChatbubbles } from "react-icons/io";
import { HiTicket } from "react-icons/hi2";
import { AiOutlineTransaction } from "react-icons/ai";
import SupportTicket from './SupportTicket';
import { FaCircle , FaGift  } from "react-icons/fa";
import SupportPanel from './SupportPanel'
import SupportAuth from './SupportAuth';
import SupportOrders from './SupportOrders';
import { TbReport } from "react-icons/tb";
import SupportAccTr from './SupportAccTr';
import { GrMoney } from "react-icons/gr";
import SubAcc from './SubAcc';
import { FaVoteYea , FaUserEdit  } from "react-icons/fa";
import SupportPass from './SupportPass';
import AdminUsers from '../Admin/AdminUsers';
import SupportWidReq from './SupportWidReq';
import SupportCoupon from './SupportCoupon';
import SupportOldAcc from './SupportOldAcc';
import axios from "axios";
import SupportGift from "./SupportGift";
import AdminCamp from "../Admin/AdminCamp";
import AdminReport from "../Admin/AdminReport";
import Acc from "../Dashbord/Acc";
class SupportDashbord extends Component {
    constructor(props) {
        super(props);
        this.state = {
         dashmenu : "داشبورد",
		 inAcc: "فعال",
		 navig : <MdDashboard />,
		 mobileNav : true ,
		 newMessage : false , 
		 allCount : {count: 0,
			ticket: {}
		 },
		 sokhan:{ author_id: "", text:"", author: "" }
        }
    }
	static contextType = UserContext

	componentDidMount(){
	const tok = "394867:66c5c69674f5c"
	axios.get("https://one-api.ir/sokhan/?token="+tok+"&action=random").then(res=>{
		this.setState({sokhan : res.data.result})
		console.log(res);
	})
	}
    render() {
		const navclassName = ()=>{
			if(this.state.mobileNav){
				return "notAc"
			}else{
				return "navAc"
			}
		}
		const {user, logout , url } = this.context;
		// const fun = ()=>{
		// 	if(user.dataes.username === "capitan.asadi@fenefx.net"){
		// 		return <div className="mt">
		// 			<img src={pishan} alt="pishan" height="200px" width="200px" />
		// 			<img src={pishan1} alt="pishan" height="200px" width="200px" />
		// 		</div>
		// 	}
		// 	else if(user.dataes.username === "e.ra.tech@fenefx.net"){
        //         return <div className="mt">
		// 			<img src={batman} alt="pishan" height="200px" width="200px" />
		// 			<img src={batman2} alt="pishan" height="200px" width="200px" />
		// 		</div>
		// 	}
		// }
		const showMainCon = ()=>{
			if(this.state.dashmenu === "داشبورد" ){
				return <>
				 <h5 className='comingsoon'>  سلام {user.dataes.first_name !== null? user.dataes.first_name+
					" " + user.dataes.last_name:user.dataes.username
				}
				  {user.dataes.username!=="Mohammad"?<div className="f12 mt">
				  <div> {this.state.sokhan.text} </div>
				  <div className="mt"> {this.state.sokhan.author} </div>
				  </div>:<div style={{
					direction:"ltr"
				  }}>
				   <h5 className="mt"> The Owner's Panel </h5>
				   <h5 className="mt">We're Working to Make Things Better !</h5>
				   <div className="mt f12 gradient-text-animation"> Thank you for your patience as we work diligently to enhance our system.
					<br/> Our team is committed to providing you with the best experience 
					 possible, and we're excited about the improvements
					 coming your way.
					<br/>
					 <div style={{
						width:"100%",
						textAlign:"center"
					 }}> Stay tuned for updates!
					 </div> </div>
				 </div>
				  }
				 </h5>
				
				</>
					
				
					
			}else if(this.state.dashmenu === "سفارشات" ){
				return <SupportOrders/>
					
			}else if(this.state.dashmenu === "کمپین" ){
				return <AdminCamp/>
					
			}else if(this.state.dashmenu === "گزارشات" ){
				return <AdminReport/>
					
			}else if(this.state.dashmenu === "تراکنش ها" ){
				return <SupportAccTr/>
					
			}else if(this.state.dashmenu === "تیکت ها" ){
				
				return(		
					<div> <SupportTicket allCount={this.state.allCount}/> </div>
				)
			}else if(this.state.dashmenu === "بررسی پاس" ){
				
				return(		
					<div> <SupportPass/> </div>
				)
			}else if(this.state.dashmenu === "احراز هویت"){
				return <>
				<SupportAuth/>
			</>
			}else if(this.state.dashmenu === "حساب های قبلی"){
				return <>
				<SupportOldAcc/>
			</>
			}else if(this.state.dashmenu === "درخواست پرداخت"){
				return <>
				<SupportPardakht/>
			</>
			}else if(this.state.dashmenu === "حساب هدیه"){
				return <>
				<SupportGift/>
			</>
			}else if(this.state.dashmenu === "چت" ){
				return <SupportPanel/>
			}else if(this.state.dashmenu === "حساب ها" ){
				return <> <SubAcc/> </>
			}else if(this.state.dashmenu === "کاربران" ){
				return <> <AdminUsers/> </>
			}else if(this.state.dashmenu === "درخواست برداشت" ){
				return <> <SupportWidReq/> </>
			}else if(this.state.dashmenu === "کد تخفیف" ){
				return <> <SupportCoupon/> </>
			}else if(this.state.dashmenu === "ویرایش اطلاعات" ){
				return <> <Acc/> </>
			}
		}
		const activers = (e)=>{
			if(this.state.dashmenu === e){
				return "active"
			}else{
				return ""
			}
		}
		
		const allScreen = ()=>{
			if(!this.state.mobileNav){
				return <div onClick={()=>{
					this.setState({mobileNav: !this.state.mobileNav})
				}} className="allScreen"></div>
			}
		}
		const allContent = ()=>{
			if(user.dataes !== null){
				let m =[]
			if(user.dataes !== undefined){
				user.dataes.permissions.map(d=>{
					m.push(d.fa_name)
				  })
			}
				return (
					<div>
						<div className="background"></div>
              <div className="app">
	<header className={user.dataes.username==="Mohammad"?"app-headerm":"app-header"}>
		<div className="app-header-logo">
			<div className="dashlogo">
					<img src={logo} alt='logo'/>				
			</div>
		</div>	
		<div className="app-header-actions">
			<button className="user-profile">
				<span> {user.dataes.username} </span>
				<span>
					<img className='w50' src={url+"storage/" +  user.dataes.image} alt='' />
				</span>
			</button>
			
		</div>
		<div className="app-header-mobile">
		    <span className="userSpan"> {user.dataes.username} </span>
		    <div className="icon-button large">
			
			<img className='w50' src={url+"storage/" +  user.dataes.image} alt='' />
			</div>
			<button onClick={()=>{
				this.setState({mobileNav : !this.state.mobileNav})
			}} className="icon-button large">
				<FaListUl/>
			</button>
		</div>
	</header>
	<div className="app-body">
		{allScreen()}
		<div className="app-body-navigation">
			<nav className="navigation">
			    <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("داشبورد")}>
				{this.state.navig}
					<span >داشبورد</span>
				</a>
				<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("ویرایش اطلاعات")}>
					<FaUserEdit />
					<span >ویرایش اطلاعات</span>
				</a>
				{m.includes("سفارشات") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("سفارشات")}>
					<GoProjectRoadmap />
					<span >سفارشات</span>
				</a>: null}
				{m.includes("کمپین") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("کمپین")}>
					<MdCampaign />
					<span >کمپین</span>
				</a>: null}
				{m.includes("گزارشات") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("گزارشات")}>
					<TbReport/>
					<span >گزارشات</span>
				</a>: null}
				{m.includes("حساب هدیه") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("حساب هدیه")}>
					<FaGift />
					<span >حساب هدیه</span>
				</a>: null}
				{m.includes("کد تخفیف") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("کد تخفیف")}>
					<CiBarcode />
					<span >کد تخفیف</span>
				</a>: null}
				{m.includes("حساب های قبلی") ? 
				<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  
				className={activers("حساب های قبلی")}>
				    <GoProjectRoadmap />
					<span >حساب های قبلی</span>
				</a>: null}
				{m.includes("درخواست برداشت") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("درخواست برداشت")}>
					<PiHandWithdrawFill/>
					<span >درخواست برداشت</span>
				</a>: null}
				{m.includes("درخواست پرداخت") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("درخواست پرداخت")}>
					<PiHandDepositFill/>
					<span >درخواست پرداخت</span>
				</a>: null}
				{m.includes("کاربران") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("کاربران")}>
				<FaUsersGear />
					<span >کاربران</span>
				</a>: null}
				{m.includes("محصولات") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("محصولات")}>
					<GoProjectRoadmap />
					<span >محصولات</span>
				</a>: null}
				
				{m.includes("تراکنش ها") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("تراکنش ها")}>
				<AiOutlineTransaction />
					<span >تراکنش ها</span>
				</a>: null}
				{m.includes("تیکت") ? <a style={{justifyContent: "space-between"}} onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({newMessage: false})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("تیکت ها")}>
                    <div style={{display:"flex"}}> 
						<HiTicket />
					    <span >تیکت ها</span> 
					</div>
					<div> {this.state.newMessage ? <FaCircle  className='animation' color='red'  /> : null} </div>
				</a>:null}
				{m.includes("بررسی پاس") ? <a style={{justifyContent: "space-between"}} onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({newMessage: false})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("بررسی پاس")}>
                    <div style={{display:"flex"}}> 
					    <FaVoteYea />
					    <span >بررسی پاس</span> 
					</div>
					<div> {this.state.newMessage ? <FaCircle  className='animation' color='red'  /> : null} </div>
				</a>:null}
				{m.includes("حساب ها") ? <a style={{justifyContent: "space-between"}} onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({newMessage: false})
				}} className={activers("حساب ها")}>
                    <div style={{display:"flex"}}> 
					<GrMoney />
					    <span >حساب ها</span> 
					</div>
					<div> {this.state.newMessage ? <FaCircle  className='animation' color='red'  /> : null} </div>
				</a>:null}
				{m.includes("احراز هویت")?<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("احراز هویت")}>
					<SiAuthelia/>
					<span >احراز هویت</span>
				</a>:null}
				{m.includes("چت")?<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("چت")}>
                    <IoIosChatbubbles />
					<span >چت</span>
				</a>:null}
				
				<a onClick={()=>{
						logout()
						this.props.navigate("/")
					}}>
					<MdOutlineExitToApp/>
					<span >خروج</span>
				</a>
			</nav>	
		</div>
		<div className={"app-mobile-navigation"+ " " + navclassName()}>
        <nav className="navigation">
			    <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("داشبورد")}>
				{this.state.navig}
					<span >داشبورد</span>
				</a>
				<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("ویرایش اطلاعات")}>
					<FaUserEdit />
					<span >ویرایش اطلاعات</span>
				</a>
				{m.includes("سفارشات") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("سفارشات")}>
					<GoProjectRoadmap />
					<span >سفارشات</span>
				</a>: null}
				{m.includes("کمپین") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("کمپین")}>
					<MdCampaign/>
					<span >کمپین</span>
				</a>: null}
				{m.includes("گزارشات") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("گزارشات")}>
					<TbReport/>
					<span >گزارشات</span>
				</a>: null}
				{m.includes("حساب هدیه") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("حساب هدیه")}>
					<FaGift />
					<span >حساب هدیه</span>
				</a>: null}
				{m.includes("کد تخفیف") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("کد تخفیف")}>
					<CiBarcode />
					<span >کد تخفیف</span>
				</a>: null}
				{m.includes("حساب های قبلی") ? 
				<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  
				className={activers("حساب های قبلی")}>
				    <GoProjectRoadmap />
					<span >حساب های قبلی</span>
				</a>: null}
				{m.includes("درخواست برداشت") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("درخواست برداشت")}>
					<PiHandWithdrawFill/>
					<span >درخواست برداشت</span>
				</a>: null}
				{m.includes("درخواست پرداخت") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("درخواست پرداخت")}>
					<PiHandWithdrawFill/>
					<span >درخواست پرداخت</span>
				</a>: null}
				{m.includes("کاربران") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("کاربران")}>
				<FaUsersGear />
					<span >کاربران</span>
				</a>: null}
				{m.includes("محصولات") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("محصولات")}>
					<GoProjectRoadmap />
					<span >محصولات</span>
				</a>: null}
				
				{m.includes("تراکنش ها") ? <a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}}  className={activers("تراکنش ها")}>
				<AiOutlineTransaction />
					<span >تراکنش ها</span>
				</a>: null}
				{m.includes("تیکت") ? <a style={{justifyContent: "space-between"}} onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("تیکت ها")}>
                    <div style={{display:"flex"}}> 
						<HiTicket />
					    <span >تیکت ها</span> 
					</div>
					<div> {this.state.newMessage ? <FaCircle  className='animation' color='red'  /> : null} </div>
				</a>:null}
				{m.includes("بررسی پاس") ? <a style={{justifyContent: "space-between"}} onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("بررسی پاس")}>
                    <div style={{display:"flex"}}> 
					    <FaVoteYea />
					    <span >بررسی پاس</span> 
					</div>
					<div> {this.state.newMessage ? <FaCircle  className='animation' color='red'  /> : null} </div>
				</a>:null}
				{m.includes("حساب ها") ? <a style={{justifyContent: "space-between"}} onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("حساب ها")}>
                    <div style={{display:"flex"}}> 
					<GrMoney />
					    <span >حساب ها</span> 
					</div>
					<div> {this.state.newMessage ? <FaCircle  className='animation' color='red'  /> : null} </div>
				</a>:null}
				{m.includes("احراز هویت")?<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("احراز هویت")}>
					<SiAuthelia/>
					<span >احراز هویت</span>
				</a>:null}
				{m.includes("چت")?<a onClick={(e)=>{
					this.setState({dashmenu : e.currentTarget.innerText})
					this.setState({mobileNav : !this.state.mobileNav})
				}} className={activers("چت")}>
                    <IoIosChatbubbles />
					<span >چت</span>
				</a>:null}
				
				<a onClick={()=>{
						logout()
						this.props.navigate("/")
					}} href="#">
					<MdOutlineExitToApp/>
					<span >خروج</span>
				</a>
			</nav>	
		            </div>
		           <div className="app-body-main-content">		
		              {showMainCon()}
		           </div>
                  </div>
               </div>
            </div>
				)
			}else{
				return <div style={{height: "100vh"}}></div>
				
			}
		}
        return (
            <>
			{allContent()}
			</>
        );
    }
}

export default SupportDashbord;
