/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect } from 'react'
import { IoIosCreate } from "react-icons/io";
import axios from 'axios';
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import Success from '../Success/Success'
import { RiShieldUserFill } from "react-icons/ri";
import { GiTrophyCup } from "react-icons/gi";
import { RiLockPasswordFill } from "react-icons/ri";
import moment from 'moment-jalaali';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FaUserCheck , FaHouseUser , FaPhone , FaTelegram  } from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
// import { FaRegQuestionCircle } from "react-icons/fa";
export default function AllOrders() {
    const getData = (u)=>{
             load(true)
             axios.get(url+"api/v1/panel/accounts?req_type="+u,
                 { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
             } }).then(res=>{
                 load(false)
                 console.log(res.data.accounts);
                 
                 setHesab(res.data.accounts)
                 setCount(res.data.count);
             }).catch(err=>{
                 load(false)
                 console.log(err);
             })
        
     }
    useEffect(()=>{
     getData("active")
    },[])
    const { load , url , user} = useContext(UserContext);
    const [show , setShow] = useState(false)
    const [modal , setModal] = useState(false)
    const [hesab , setHesab] = useState([])
    const [log , setLog] = useState("")
    const [pass , setPass] = useState("")
    const [id , setId] = useState()
    const [count , setCount] = useState(0)
    const [inModal , setInModal] = useState([0])
    const [active , setAc] = useState({a1:"active" , a2:"" , a3: ""})
    const [brokers , setBrokers] = useState([])
    const [platforms , setPlatforms] = useState([])
    const [brok , setBrok] = useState("")
    const [plat , setPlat] = useState("")
    const [server , setServer] = useState([])
    const [product , setProduct] = useState([])
    const [serverName , setServerName] = useState("")
    const [how , setHow] = useState("")
    const [acc , setAcc] = useState(true)
    const [last_name , setLast_name] = useState(user.dataes.last_name)
    const [first_name , setName] = useState(user.dataes.first_name)
    const [telId , setTelId] = useState(user.dataes.telegram_id)
    const [email , setEmail] = useState(user.dataes.email)
    const [number , setNumber] = useState(user.dataes.phone_number)
    const [order , setOrder] = useState({})
	const [gavanin, setGavanin] = useState(false)
	const [gavanin1, setGavanin1] = useState(false)
	const [show1, setShow1] = useState(false)
	const [step, setStep] = useState(false)
	const [pro, setPro] = useState("")
	const [howTo, setHowTo] = useState(1)
    const hesabs = ()=>{
        const translator = (n)=>{
            if(n=="First Step"){
                return "مرحله اول"
            }else if(n == "Second Step"){
                return "مرحله دوم"
            }else if(n == "Pro Trader"){
                return "پرو تریدر"
            }else if(n == "Real"){
                return "REAL"
            }else if(n == "One Step"){
                return "تک مرحله"
            }else{
                return n
            }
        }
        const status = (s , i) => {
            if(i.type == "Pro Trader" || i.type == "Real"){
                const ps = ()=>{
                    if(i.payouts.length !== 0){
                        return i.payouts[i.payouts.length - 1].status
                    }else{
                        return 100000
                    }
                } 
                const pd = i.payouts
                if(i.payouts.length == 0 ){
                    return <div onClick={()=>{
                        if(user.dataes.confirmed_authentications.find(e=>e.type=="Video_auth")){
                            setInModal([10, i.id , []])
                            setModal(true)
                        }else{
                            NotificationManager.error("احراز هویت مرحله دوم تکمیل نشده است","درخواست انجام نشد",5000)
                        }
                    }} className='green' style={{cursor:"pointer"}}> درخواست برداشت </div>
                }else if(ps() == 0 || ps() == 1 || ps() == 3|| ps() == 5 || ps() == 6){
                    return <div className='orange'> در حال بررسی </div>
                }else if(ps() == 2){
                   return <div onClick={()=>{
                    setInModal([10, i.id , pd ,true ])
                    setModal(true)
                   }} className='green' style={{cursor:"pointer"}}> تایید شده </div>
                }else if(ps() == 4){
                    return <div onClick={()=>{
                        setInModal([10, i.id ,  pd , false ])
                        setModal(true)
                        console.log(i);
                        
                    }} className='red' style={{cursor:"pointer"}}> رد شده </div>
                 }
                }else{
                    if(s !== null){
                
                    if (s.status == 0) {
                        return <div className='orange'> در حال بررسی </div>
                    } else if (s.status == 2)  {
                        return <div onClick={()=>{
                            if(i.type == "Second Step"||
                                i.type == "One Step"){
                                if(user.dataes.authenticated){
                                    load(true)
                                axios.get(url+"api/v1/panel/order_page",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setBrokers(res.data.brokers)
                                    setInModal([11,i])
                                    setModal(true)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }else{
                                    NotificationManager.warning("احراز هویت مرحله اول تکمیل نشده است")
                                }
                                
                            }else{
                                load(true)
                                axios.get(url+"api/v1/panel/order_page",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setBrokers(res.data.brokers)
                                    setInModal([3,i])
                                    setModal(true)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                            }
                        }} className='green' style={{cursor:"pointer"}}>{
                            i.type == "Second Step"||
                            i.type == "One Step"?
                            "درخواست Real":"پاس شده"} </div>
                    }else if (s.status == 3){
                        if(i.id == inModal[0]){
                            return <div className='orange'> در حال بررسی </div>
                        }else{
                            return <div onClick={()=>{
                                setInModal([1,s.description, i.id] )
                                setModal(true)
                            }} className='red' style={{cursor:"pointer"}}> رد شده </div>
                        }
                    }else{
                        return <div className='orange'> در حال بررسی </div>
                    }
                }else{
                    if(i.id == inModal[0]){
                        return <div className='orange'> در حال بررسی </div>
                    }else{
                        return <div onClick={()=>{
                            console.log(i);
                            
                            setInModal([0, i.id , i])
                            setModal(true)
                        }} className='blue' style={{cursor:"pointer"}}> درخواست پاس </div>
                    }
                }
                }
            
        }
        return <>
        {hesab.map(d=>{
            if(d.order.delivery_status == "user" && d.login == null){
              if(d.status == 0 ){
                return <tr>
                        {active.a1 == "active" ?<th> {status(d.latest_pass_request, d)} </th>: null}
                         <th> {d.order.product.category.title + " / " + d.order.product.title} 
                            {d.transaction.transition_type ==
                          "Existing Account"?" -- حساب قبلی ":null}
                             {d.transaction.transition_type ==
                          "Gift Account"?" -- حساب هدیه ":null}
                           </th>
                         <th> {d.order.platform} </th>
                         <th colSpan={3}> 
                            <div onClick={()=>{
                                setShow(true)
                                setId(d.id)
                                setInModal([])
                                setOrder(d.order)
                            }} className='view'> وارد کردن اطلاعات حساب </div> 
                         </th>
                         
                         <th> {d.order.broker.name + " / " + d.order.server} </th> 
                         <th> {d.transaction.id} </th>   
                         <th> {d.related_to&&d.related_to!==null?translator(d.type)+" - "+d.related_to.login:translator(d.type)} </th>    
                     </tr>
              }else if(d.status == 2){
                return <tr>
                        {active.a1 == "active" ?<th> {status(d.latest_pass_request, d)} </th>: null}
                         <th> {d.order.product.category.title + " / " + d.order.product.title} 
                            {d.transaction.transition_type ==
                          "Existing Account"?" -- حساب قبلی":null}
                           {d.transaction.transition_type ==
                          "Gift Account"?" -- حساب هدیه ":null}
                           </th>
                         <th> {d.order.platform} </th>
                         <th colSpan={3}> 
                            <div onClick={()=>{
                                setShow(true)
                                setInModal([4,d])
                                setId(d.id)
                                setOrder(d.order)
                                console.log(d.account_actions.reverse().find(e=> e.title=="Account Rejected"));
                            }} style={{cursor:"pointer" , paddingTop:"3px",paddingBottom:"3px"}}
                             className='red'> اطلاعات شما تایید نشده </div> 
                         </th>
                         
                         <th> {d.order.broker.name + " / " + d.order.server} </th>   
                         <th> {d.transaction.id} </th>    
                         <th> {d.related_to&&d.related_to!==null?translator(d.type)
                         +" - "+d.related_to.login:translator(d.type)} </th>    
                     </tr>
              }
            }else if(d.order.delivery_status == "us" && d.login == null){
                
              const saxt = ()=>{
                if(d.account_info == null){
                    return <div style={{paddingTop:"3px" , paddingBottom:"3px"}}
                    onClick={()=>{
                        setAcc(false)
                        setShow(true)
                        setOrder(d.order)
                    }}
                    className='blue'> کلیک جهت شروع چالش </div> 
                }else{
                    if(d.order.platform == "MT5" && d.account_info.telegram_id == null){
                    return <div style={{paddingTop:"3px" , paddingBottom:"3px" , cursor:"pointer"}}
                    className='blue'
                    onClick={()=>{
                        setAcc(false)
                        setShow(true)
                        setOrder(d.order)
                    }}> کلیک جهت شروع چالش  </div> 
                    }else{
                    return <div style={{paddingTop:"3px" , paddingBottom:"3px"}}
                    className='orange'> در حال ساخت توسط فنفیکس</div> 
                    }
                }
              }  
              return  <tr>
                 <th> {d.order.product.category.title + " / " + d.order.product.title}
                   {d.transaction.transition_type ==
                          "Existing Account"?" -- حساب قبلی":null}
                          {d.transaction.transition_type ==
                          "Gift Account"?" -- حساب هدیه ":null}
                     </th>
                 <th> {d.order.platform} </th>
                 <th colSpan={3}> 
                   {saxt()}
                 </th>
                 <th> {d.order.broker.name + " / " + d.order.server} </th>  
                 <th> {d.transaction.id} </th>     
                 <th> {d.related_to&&d.related_to!==null?
                 translator(d.type)+" - "+d.related_to.login:
                 translator(d.type)} </th>    
             </tr>
            }else if(d.order.delivery_status == "user" && d.login !== null && d.status == 0  ){
                const saxt = ()=>{
                    if(d.account_info == null){
                        return <th colSpan={3}>
                            <div style={{paddingTop:"3px" , paddingBottom:"3px"}}
                        onClick={()=>{
                            setAcc(false)
                            setShow(true)
                            setOrder(d.order)
                        }}
                        className='purple'> کلیک جهت شروع چالش </div> 
                        </th>
                    }else{
                        return <>
                           <th style={{direction:"ltr" , textAlign:"left"}}> {d.login} <FaCopy onClick={()=>{
                                navigator.clipboard.writeText(d.login)
                            }} style={{verticalAlign:"middle", fontSize:"15px"}} /></th>
                              <th>در دسترس کاربر </th>
                              <th style={{direction:"ltr",textAlign:"left"}}> 
                          {d.investor_password} <span style={{color:"orange"}} >  ( در حال بررسی ) </span>
                       </th>
                        </> 
                    }
                  } 
                return <tr>
               {active.a1 == "active" ?
                 <th> {status(d.latest_pass_request, d)} </th>: null}
                 <th> {d.order.product.category.title + " / " + d.order.product.title}
                         {d.transaction.transition_type ==
                          "Existing Account"?" -- حساب قبلی":null}
                          {d.transaction.transition_type ==
                          "Gift Account"?" -- حساب هدیه ":null}
                 </th>
                 <th> {d.order.platform} </th>
                  {saxt()}
                 <th> {d.order.broker.name + " / " + d.order.server} </th>  
                 <th> {d.transaction.id} </th>     
                 <th>{d.related_to&&d.related_to!==null?translator(d.type)+" - "
                 +d.related_to.login:translator(d.type)} </th>    
              
             </tr> 
              
            }else{
            return <tr>
                        {active.a1 == "active" ?<th> {status(d.latest_pass_request, d)} </th>: null}
                        {active.a3 == "active" ?
                        <th> 
                         <div onClick={()=>{
                            
                            console.log(d);
                            setModal(true)
                            setInModal([66,d.account_actions])
                         }} className='red'> علت منسوخی </div>
                        </th>: null}
                        {active.a4 == "active" ?
                        <th style={{textAlign:"center" , color:"gold"}}>
                             <GiTrophyCup onClick={()=>{
                               
                                if(d.type =="Second Step" || d.type =="One Step"){
                                    if(d.order.user.certificate.image !== null && d.order.user.certificate.image){
                                    window.open(url+"storage/"+d.order.user.certificate.image, '_blank')
                                  }else{
                                    NotificationManager.info("تصویر سرتیفیکیت بارگزاری نشده ، لطفا منتظر بمانید")
                                }
                                }else if(d.type =="Pro Trader"||d.type =="Real"){
                                    if(d.payouts.find(e=>e.status==2)&&d.payouts.find(e=>e.status==2).image!==null&&
                                    d.payouts.find(e=>e.status==2)&&d.payouts.find(e=>e.status==2).image){
                                        window.open(url+"storage"+d.payouts.find(e=>e.status==2).image, '_blank')
                                    }else{
                                        NotificationManager.info("تصویر سرتیفیکیت بارگزاری نشده ، لطفا منتظر بمانید")
                                    }
                                }
                                
                             }} /> 
                        </th>: null}
                        <th> {d.order.product.category.title + " / " + d.order.product.title}
                        {d.transaction.transition_type ==
                          "Existing Account" ? " -- حساب قبلی":null}
                          {d.transaction.transition_type ==
                          "Gift Account"?" -- حساب هدیه ":null}
                        </th>
                        <th> {d.order.platform} </th>
                        <th style={{direction:"ltr",textAlign:"left"}}> {d.login}  <FaCopy onClick={()=>{
                                navigator.clipboard.writeText(d.login)
                            }} style={{verticalAlign:"middle", fontSize:"15px"}} /></th>
                        <th style={{direction:"ltr",textAlign:"left"}}> 
                        {/* <div onClick={()=>{
                            load(true)
                                 axios.post(url+"api/v1/panel/account/"+d.id+"/show_password",{},
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    NotificationManager.success("گذرواژه شما با از طریق ایمیل برای شما ارسال شد","گذر واژه با موفقیت ارسال شد",3000)

                                }).catch(err=>{
                                    load(false)
                                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شماارسال نشد",3000)
                                    console.log(err);
                                })
                            }} className='view'> مشاهده </div>  */}
                            {d.password} <FaCopy onClick={()=>{
                                navigator.clipboard.writeText(d.password)
                            }} style={{verticalAlign:"middle", fontSize:"15px"}} />
                            
                        </th>
                        <th style={{direction:"ltr",textAlign:"left"}}>{d.investor_password} <FaCopy onClick={()=>{
                                navigator.clipboard.writeText(d.investor_password)
                            }} style={{verticalAlign:"middle", fontSize:"15px"}} /> </th>
                        <th> {d.order.broker.name + " / " + d.order.server} </th>  
                        <th> {d.transaction.id} </th>     
                        <th> {d.related_to&&d.related_to!==null?
                        translator(d.type)+" - "+d.related_to.login:
                        translator(d.type)} </th>    
                         
                   </tr>
            }
        })}
        </>
    }
    
    const clickOnAcc =(d)=>{
      if(d==1){
        setAc({a1:"active" , a2:"" , a3: "", a4:""})
       
        getData("active")
        
      }else if(d==2){
        setAc({a1:"" , a2:"active" , a3: "", a4:""})
     
        getData("inactive")


      }else if(d==3){
        setAc({a1:"" , a2:"" , a3: "active" , a4:""})
        
        getData("delete")
      }else if(d==4){
        setAc({a1:"" , a2:"" , a3: "", a4: "active"})
        
        getData("passed")

      }
    }    
    const inModalShow = ()=>{
        if(inModal[0] == 0){
           if(inModal[2]?.order.product.category_id==4){
            return  <div >
            <div className='bb'> درخواست پاس حساب تک مرحله ای ویژه هالووین </div>
            <div className='mt' > 
                به منظور اطلاع‌رسانی دقیق‌تر در خصوص قوانین مربوط به 
                بررسی پاس حساب، لطفا موارد زیر را مد نظر قرار دهید:
                </div>
            <ul style={{
               textAlign:"right",
               marginRight:"5px"
           }} className='f12 p10 bb'>
                <li>
                پاس حساب تنها در صورتی 
                امکان‌پذیر است که حداقل ۵ روز معاملاتی (پشت سر هم یا غیر متوالی) 
                معامله کرده باشید.
                </li>
                <li>
                  برای پاس باید تارگت سود حداقل 15 درصدی را به دست آورده باشید.
                </li>
                <li>
                  این حساب محدودیت زمانی 10 روزه برای پاس کردن چالش دارد.
                </li>
             </ul>
             <div className='bb'>لطفاً پس از رعایت این شرایط و رسیدن به اهداف مورد نظر،
                حساب خود را برای بررسی نهایی ارسال فرمایید.</div>
            <p className='mt'> در صورت ثبت درخواست ،
                درخواست شما به تیم پشتیبانی فنی ارجاع داده خواهد شد <br/> 
                و پس از انجام بررسی های لازم از طریق همین بخش اعلام خواهد شد   </p>
                 <div className='closeModal'>
                    <button className='btnreply mt' onClick={()=>{
                        setModal(false)
                    }}> بستن </button>
                    <button className='btnreply mt' onClick={()=>{
                       load(true)
                       axios.post(url+"api/v1/panel/pass_request/"+inModal[1],{},
                           { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                       } }).then(res=>{
                           load(false)
                           NotificationManager.success("درخواست پاس با موفقیت ارسال شد","درخواست موفق",5000)
                           setModal(false)
                           setInModal([inModal[1]])
                           getData("active")
                       }).catch(err=>{
                           load(false)
                           if(err.response.status == 422){
                               NotificationManager.error("5 روز معاملاتی شما به اتمام نرسیده است","ناموفق",4000)
                           }else{
                               NotificationManager.error("درخواست ارسال نشد دوباره امتحان نمایید","ناموفق",4000)
                           }
                           
                           console.log(err);
                       })
                    }}> تایید </button>
                 </div>
             </div>
           }else{
            return  <div >
                     <div className='bb'> درخواست پاس حساب معاملاتی </div>
                     <div className='mt' > 
                         به منظور اطلاع‌رسانی دقیق‌تر در خصوص قوانین مربوط به 
                         بررسی پاس حساب، لطفا موارد زیر را مد نظر قرار دهید:
                         </div>
                     <ul style={{
                        textAlign:"right",
                        marginRight:"5px"
                    }} className='f12 p10 bb'>
                         <li>
                         پاس حساب در مرحله اول و دوم تنها در صورتی 
                         امکان‌پذیر است که حداقل ۵ روز معاملاتی (پشت سر هم یا غیر متوالی) 
                         معامله کرده باشید.
                         </li>
                         <li>
                         برای مرحله اول باید تارگت سود حداقل 10 درصدی را به دست آورده باشید.
                         </li>
                         <li>
                         برای مرحله دوم باید تارگت سود حداقل 5 درصدی را به دست آورده باشید.
                         </li>
                         <li>
                         در هر مرحله لازم است که این شرایط 
                         به دقت رعایت شود تا بررسی حساب شما با تاخیر انجام نشود.
                         </li>
                      </ul>
                      <div className='bb'>لطفاً پس از رعایت این شرایط و رسیدن به اهداف مورد نظر،
                         حساب خود را برای بررسی نهایی ارسال فرمایید.</div>
                     <p className='mt'> در صورت ثبت درخواست ،
                         درخواست شما به تیم پشتیبانی فنی ارجاع داده خواهد شد <br/> 
                         و پس از انجام بررسی های لازم از طریق همین بخش اعلام خواهد شد   </p>
                          <div className='closeModal'>
                             <button className='btnreply mt' onClick={()=>{
                                 setModal(false)
                             }}> بستن </button>
                             <button className='btnreply mt' onClick={()=>{
                                load(true)
                                axios.post(url+"api/v1/panel/pass_request/"+inModal[1],{},
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    NotificationManager.success("درخواست پاس با موفقیت ارسال شد","درخواست موفق",5000)
                                    setModal(false)
                                    setInModal([inModal[1]])
                                    getData("active")
                                }).catch(err=>{
                                    load(false)
                                    if(err.response.status == 422){
                                        NotificationManager.error("5 روز معاملاتی شما به اتمام نرسیده است","ناموفق",4000)
                                    }else{
                                        NotificationManager.error("درخواست ارسال نشد دوباره امتحان نمایید","ناموفق",4000)
                                    }
                                    
                                    console.log(err);
                                })
                             }}> تایید </button>
                          </div>
                  </div>
           }
        }else if(inModal[0] == 66){
            return <> 
                       
                       {inModal[1].map((e , i)=>{
                        if(e.action_status==3){
                            return <div key={i}  className='des'>
                                     <p className='why'> علت نقض حساب در {moment(e.updated_at).format('jYYYY/jM/jD')} :  </p>
                                     <p> {e.description} </p>
                                    </div>
                        }
                       })}
                       
                    </>
        }else if(inModal[0] == 1){
            return <div >
            <div  className='des'> 
                <p className='why'> علت رد درخواست :  </p> 
                   <p> {inModal[1]} </p>
            </div> 
            <div className='closeModal'>
                 <button className='btnreply mt' onClick={()=>{
                     setModal(false)
                 }}> بستن </button>
                  <button className='btnreply mt' onClick={()=>{
                    load(true)
                    axios.post(url+"api/v1/panel/pass_request/"+inModal[2],{},
                         { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                     } }).then(res=>{
                         load(false)
                          NotificationManager.success("درخواست پاس با موفقیت ارسال شد","درخواست موفق",5000)
                          getData("active")
                         setModal(false)
                         setInModal([inModal[2]])
                        
                    }).catch(err=>{
                        load(false)
                        if(err.response.status == 422){
                            NotificationManager.error("پنج روز معاملاتی به اتمام نرسیده","ناموفق",4000)
                        }else{
                           NotificationManager.error("درخواست ارسال نشد دوباره امتحان نمایید","ناموفق",4000) 
                        }
                        
                        console.log(err);
                    })
                 }}> درخواست بررسی مجدد </button>
                </div>
           </div>
        }else if(inModal[0] == 3){
          return  <div>
                         <div className='bb'> درخواست حساب مرحله دوم </div>
                          <div>
                               <div className="bb">
                                    <label > نحوه ساخت حساب </label>
                                    <select className="second" value={how} onChange={(e)=>{
                                        setHow(e.target.value)
                                          }} >
                                        <option value=""> نحوه ساخت حساب را انتخاب نمایید </option>
                                        <option value="us"> توسط فنفیکس </option>
                                        <option value="user"> توسط خودم </option>
                                    </select>
                               </div>
                               {how == "user"? <div>
                                <div className='flex1'>
                                           <div className='gg'> <RiShieldUserFill /> شماره لاگین   </div>
                                           <input value={log} onChange={(e)=>{
                                            setLog(e.target.value)
                                           }} type='text' className='loginInput'/>
                                         </div>
                                         <div className='flex1'>
                                           <div className='gg'> <RiLockPasswordFill /> رمز Investor   </div>
                                           <input style={{direction:"ltr",textAlign:"left"}} value={pass} onChange={(e)=>{
                                            setPass(e.target.value)
                                           }} type='text' className='loginInput'/>
                                         </div>
                               </div>:null}
                               <div className="bb">
                                  <label> بروکر </label>
                                  <select className="second" value={brok} onChange={(e)=>{
                                       setBrok(e.target.value)
                                       setServer([])
                                       if(e.target.value !== ""){
                                                 load(true)
                                             axios.get(url+"api/v1/panel/broker_servers?broker_id="+ e.currentTarget.value,
                                                 { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,     
                                             } }).then(res=>{
                                                 load(false)
                                                 setPlatforms(res.data.broker_servers) 
                                                 console.log(res.data);
                                                 setPlat("") 
                                             }).catch(err=>{
                                                 load(false)
                                                 console.log(err);
                                             }) 
                                             }else{
                                                 setPlatforms({})
                                             }
                                         }}>
                                         <option  value=""> بروکر را انتخاب نمایید </option>
                                         {brokers.map(d=>{
                                             return <option key={d.id} value={d.id}> { d.name} </option>
                                         })}
                                     </select>
                                     </div>
                                      {Object.keys(platforms).length !== 0? <div className='bb'>
                                          <label> پلتفرم را انتخاب نمایید </label>
                                      <select className='second' value={plat} onChange={(e)=>{
                                             setPlat(e.target.value)
                                             setServer([])
                                              setServerName("")
                                                  if(e.target.value == "mt5"){
                                                   setServer(platforms.mt5)
                                                   
                                                   
                                                  }else if(e.target.value == "mt4"){
                                                   setServer(platforms.mt4)
                                                 }else{
                                                     setServer([])
                                                  }
                        
                                                   }}>
                                          <option   value=""> پلتفرم را انتخاب نمایید </option>
                                            {Object.keys(platforms).map(d=>{
                                              return <option key={platforms[d].id} value={platforms[d].id}> {d} </option>
                                                  })}
                                         </select>
                </div>: null}
                {server.length !== 0 ? <div className='bb'>
                    <label> سرور را انتخاب نمایید </label>
                <select className='second' value={serverName} onChange={(e)=>{
                        setServerName(e.target.value)
  
                    }}>
                    <option value=""> سرور را انتخاب نمایید </option>
                    {server.map(d=>{
                        return <option key={d.id} value={d.title}> {d.title} </option>
                    })}
                </select>
                </div>: null}
                {serverName !== "" && brok !=="" && plat !== "" && how !=="" ?
                <button onClick={()=>{
                   
                    let data = {
                        platform: plat , 
                        broker : brok , 
                        server:serverName ,
                        delivery_type : how , 
                        login : log , 
                        password : pass
                    }
                   if(how=="user"){
                    if(log !== "" && pass !==""){
                        load(true)
                             axios.post(url+"api/v1/panel/second_phase/"+inModal[1].id,data ,
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                           } }).then(res=>{
                            load(false)
                            setModal(false)
                            NotificationManager.success("درخواست حساب مرحله دوم با موفقیت ایجاد شد ","درخواست انجام شد",4000)
                            getData("active")
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                            NotificationManager.error("ساخت حساب انجام نشد","",3000)
           
                        })
                    }else{
                        NotificationManager.error("گذرواژه یا لاگین خالی می باشد","انجام نشد", 5000)
                    }
                   }else{
                             load(true)
                             axios.post(url+"api/v1/panel/second_phase/"+inModal[1].id, data , 
                             { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                             } }).then(res=>{
                             load(false)
                             NotificationManager.success("درخواست حساب مرحله دوم با موفقیت ایجاد شد ","درخواست انجام شد",4000)
                             getData("active")
                             setModal(false)
                            }).catch(err=>{
                              load(false)
                              NotificationManager.error("ساخت حساب انجام نشد","",3000)
                           console.log(err);
                      })
                    
                   }
                    
                }} className='button login__submit1'>
                    درخواست حساب
                </button>: null}
                          </div>
                  </div>
        }else if(inModal[0] == 10){
                return <div >
                    <div className='bb'> درخواست برداشت </div>
                    {inModal[3]?<ul style={{
                        textAlign:"right",
                        
                    }} className='mt f12 p10 bb'>
                         <li> درخواست برداشت شما با موفقیت تایید شده و مبلغ 
                         {" "+inModal[2][inModal[2].length-1].received_amount+" $ "} 
                         به کیف پول شما واریز گردید . 
                         </li>
                         <li>
                         بالانس حساب بعدی : { " "+inModal[2][inModal[2].length-1].next_balance+" " }
                         </li>
                         <li>
                          برای درخواست اکانت بعدی گزینه درخواست حساب را بزنید
                         </li>
                         <li>
                           چنانچه قصد تغییر بروکر ، پلتفرم و یا سرور را برای حساب جدید خود دارید 
                           پس از درخواست حساب ،<br/> تیکتی با عنوان تغییر حساب برای دپارتمان فنی ارسال نمایید
                         </li>
                      </ul>:null}
                    {!inModal[3] ? <>
                                    {inModal[2].map((e , i)=>{
                                           if(e.status == 4){
                                            return <div key={i} style={{marginBottom:"10px"}}  className='des'> 
                                           <p className='why'> علت رد درخواست در 
                                            <span style={{ textAlign:"left"}}>{" "+ moment(e.updated_at).format('jYYYY/jM/jD') }</span> :  </p> 
                                           <p> {e.supervisor_description} </p>
                                        </div>
                                           }
                                      })}
                                   <p className='mt'> در صورت ثبت درخواست مجدد ،
                                     درخواست شما به تیم پشتیبانی فنی ارجاع داده خواهد شد <br/> 
                                     و پس از انجام بررسی های لازم از طریق همین بخش قابل پیگیری می باشد   
                                   </p>
                                  </>
                                  : 
                                  <p className='mt'> در صورت ثبت درخواست ،
                                  درخواست شما به تیم پشتیبانی فنی ارجاع داده خواهد شد <br/> 
                                  و پس از انجام بررسی های لازم از طریق همین بخش قابل پیگیری می باشد   </p>
                    }
                 <div className='closeModal'>
                    <button className='btnreply mt' onClick={()=>{
                        setModal(false)
                    }}> بستن </button>
                    {inModal[3]?<button className='btnreply mt' onClick={()=>{
                       load(true)
                       axios.post(url+"api/v1/panel/pro/"+inModal[1],{},
                           { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                       } }).then(res=>{
                           load(false)
                           NotificationManager.success("درخواست با موفقیت ارسال شد","درخواست موفق",5000)
                           setModal(false)
                           setInModal([inModal[1]])
                           getData("active")
                       }).catch(err=>{
                           load(false)
                           if(err.response.status == 422){
                               let mes = err.response.data.days + " روز تا پایان حداقل روز های معاملاتی شما"
                               NotificationManager.error(mes,"ناموفق",4000)
                           }else{
                               NotificationManager.error("درخواست ارسال نشد دوباره امتحان نمایید","ناموفق",4000)
                           }
                           console.log(err);
                       })
                    }}> درخواست حساب </button>:
                    <button className='btnreply mt' onClick={()=>{
                        load(true)
                        axios.post(url+"api/v1/panel/payout/store",{account_id:inModal[1]},
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            NotificationManager.success("درخواست برداشت با موفقیت ارسال شد","درخواست موفق",5000)
                            setModal(false)
                            setInModal([inModal[1]])
                            getData("active")
                        }).catch(err=>{
                            load(false)
                            if(err.response.status == 422){
                                let mes = err.response.data.days + " روز تا پایان حداقل روز های معاملاتی شما"
                                NotificationManager.warning(mes,"ناموفق",4000)
                            }else if(err.response.status == 409){
                                NotificationManager.warning("تریدر گرامی طبق قانون 27 لطفا در روز های 14 ، 15 ، 29 و 30 هر ماه میلادی اقدام به عملیات برداشت از حساب نمایید","ناموفق",4000)
                            }else{
                                NotificationManager.error("درخواست ارسال نشد دوباره امتحان نمایید","ناموفق",4000)
                            }
                            console.log(err);
                        })
                     }}> {inModal[2].length !== 0 ? "درخواست مجدد": "تایید"} </button>
                    }
                 </div>
         </div>
            
        }else if(inModal[0] == 11){
            return  <div >
            <div className='bb'> درخواست حساب Real  </div>
            <p className='mt'> در انتخاب بروکرتان این نکته را در نظر داشته باشید که؛
         بروکرهایی که بر روی سرورهای دمو کمسیون اعمال نمیکنند
          ولی در حساب ریل کمسیون از حساب شرکت کسر میشود،
         در مرحله برداشت سود کمسیون در حساب تریدر اعمال خواهد شد
         اندازه کمسیون بسته به نوع بروکر و
         نوع جفت ارز معاملاتی از پنج الی پانزده دلار به ازای هر لات متغیر میباشد </p>
         <div className="bb">
                                  <label> بروکر </label>
                                  <select className="second" value={brok} onChange={(e)=>{
                                       setBrok(e.target.value)
                                       setServer([])
                                       if(e.target.value !== ""){
                                                 load(true)
                                             axios.get(url+"api/v1/panel/broker_servers?broker_id="+ e.currentTarget.value,
                                                 { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,     
                                             } }).then(res=>{
                                                 load(false)
                                                 setPlatforms(res.data.broker_servers) 
                                                 console.log(res.data);
                                                 setPlat("") 
                                             }).catch(err=>{
                                                 load(false)
                                                 console.log(err);
                                             }) 
                                             }else{
                                                 setPlatforms({})
                                             }
                                         }}>
                                         <option  value=""> بروکر را انتخاب نمایید </option>
                                         {brokers.map(d=>{
                                             return <option key={d.id} value={d.id}> { d.name} </option>
                                         })}
                                     </select>
                                     </div>
                                      {Object.keys(platforms).length !== 0? <div className='bb'>
                                          <label> پلتفرم را انتخاب نمایید </label>
                                      <select className='second' value={plat} onChange={(e)=>{
                                             setPlat(e.target.value)
                                             setServer([])
                                              setServerName("")
                                                  if(e.target.value == "mt5"){
                                                   setServer(platforms.mt5)
                                                   
                                                   
                                                  }else if(e.target.value == "mt4"){
                                                   setServer(platforms.mt4)
                                                 }else{
                                                     setServer([])
                                                  }
                        
                                                   }}>
                                          <option   value=""> پلتفرم را انتخاب نمایید </option>
                                            {Object.keys(platforms).map(d=>{
                                              return <option key={platforms[d].id} value={platforms[d].id}> {d} </option>
                                                  })}
                                         </select>
                </div>: null}
                {server.length !== 0 ? <div className='bb'>
                    <label> سرور را انتخاب نمایید </label>
                <select className='second' value={serverName} onChange={(e)=>{
                        setServerName(e.target.value)
  
                    }}>
                    <option value=""> سرور را انتخاب نمایید </option>
                    {server.map(d=>{
                        return <option key={d.id} value={d.title}> {d.title} </option>
                    })}
                </select>
                </div>: null}
                {serverName !== "" && brok !=="" && plat !== ""  ?
                <button onClick={()=>{
                    let data = {platform: plat , broker : brok , server:serverName 
                    }
                    load(true)
                    axios.post(url+"api/v1/panel/real/"+inModal[1].id,data,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                        
                        NotificationManager.success("درخواست حساب Real با موفقیت ارسال شد","درخواست موفق",5000)
                        setModal(false)
                        setInModal([inModal[1].id])
                        setAc({a1:"",a2:"active",a3:""})
                        getData("inactive")
                    }).catch(err=>{
                        load(false)
                        if(err.response.status == 422){
                        let mes = err.response.data.days + " روز تا پایان حداقل روز های معاملاتی شما"
                        NotificationManager.error(mes,"ناموفق",4000)
                        }else{
                            NotificationManager.error("درخواست ارسال نشد دوباره امتحان نمایید","ناموفق",4000)
                        }
                        console.log(err);      
                      })
                   
                    
                }} className='button login__submit1'>
                    درخواست حساب
                </button>: null}
                
                 
         </div>
        }
    }
    const newCount = (c)=>{
        if(c > 0){
            return <div className='newCount'> {c} </div>
        }
    }
    const inModal2 = ()=>{
        if(acc){
         return <div className='logCon'>
            {inModal[0]==4?
                  <div style={{marginBottom:"10px"}}  className='des'> 
                    <p className='why'> علت رد اکانت :  </p> 
                    <p> {inModal[1].account_actions.reverse().find(e=> e.title=="Account Rejected").description} </p>
                  </div>

             :null}
          <div className='bb'> {inModal[0]==4?"اطلاعات جدید را وارد نمایید "
          :"اطلاعات حساب کاربری خود را وارد نمایید "}</div>
          
          <div className='flex1'>
            <div className='gg'> <FaUserCheck /> نام   </div>
            <input value={first_name} onChange={(e)=>{
             setName(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <FaHouseUser /> نام خانوادگی   </div>
            <input value={last_name} onChange={(e)=>{
             setLast_name(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <MdOutlineAlternateEmail /> ایمیل   </div>
            <input value={email} onChange={(e)=>{
             setEmail(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> < FaPhone /> شماره همراه   </div>
            <input value={number} onChange={(e)=>{
             setNumber(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <IoIosCreate /> نحوه ساخت   </div>
            <select style={{width:window.screen.width>800?"166px":"255px"}}  value={howTo} onChange={(e)=>{
             setHowTo(e.target.value)
            }} type='text' className='loginInput'>
                <option style={{color:"black"}} value={1}> توسط خودم </option>
                <option style={{color:"black"}} value={2}> توسط فنفیکس </option>
            </select>
          </div>
          {howTo==1?<>
            <div className='flex1'>
            <div className='gg'> <RiShieldUserFill /> شماره لاگین   </div>
            <input value={log} onChange={(e)=>{
             setLog(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <RiLockPasswordFill /> رمز Investor   </div>
            <input style={{direction:"ltr",textAlign:"left"}} value={pass} onChange={(e)=>{
             setPass(e.target.value)
            }} type='text' className='loginInput'/>
          </div>
          </>:null}
          {order.platform == "MT5"?<> 
            <input checked={gavanin} className='radio mipaziram' onChange={(e)=>{
               setGavanin(e.target.checked)
                
             }}   name="gavanin" type='checkbox' /> 
             <a href='https://t.me/userinfobot'
			  target="_blank"  className='f12'> شناسه عددی تلگرام را دریافت کردم (کلیک نمایید -- الزامی)  </a>
             
          <div className='flex1'>
            <div className='gg'> < FaTelegram/> شناسه عددی تلگرام  <AiFillQuestionCircle onClick={()=>{
                NotificationManager.success("شناسه عددی که برای فعال کردن پنل آنالیز شماست را وارد نمایید ",
                    "راهنما",8000)
            }} style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    verticalAlign:"middle"
                }} /> </div>
            <input disabled={!gavanin} value={telId} onChange={(e)=>{
             setTelId(e.target.value)
             if(isNaN(+e.currentTarget.value)){
                setTelId("")
            }
            }} type='text' className='loginInput'/>
          </div>
          <input checked={gavanin1} className='radio mipaziram' onChange={(e)=>{
               setGavanin1(e.target.checked)
                
             }}   name="das" type='checkbox' /> 
             <a  href='https://t.me/Mt5reportbot' 
			  target="_blank"  className='f12'> Mt5reportbot را فعال کردم (کلیک نمایید -- الزامی) </a>
              <p className='f12' style={{color:"red" }}> *در صورت صحیح نبودن شناسه عددی تلگرام ، فنفیکس قادر به به ارسال گزارش 
            حساب شما از قبیل <br/> تخلفات 
            ، منسوخی حساب و ...  از تلگرام نخواهد شد  </p>
          </>: null}
          
          <button disabled={order.platform == "MT5"?!gavanin1:false} onClick={()=>{
            if(howTo == 1){
                if(order.platform == "MT5"){
                    if(pass !== "" && log !== "" && telId!== "" 
                    && first_name!=="" && last_name!==""&& number!=="" &&
                    email!==""
                    ){
                        load(true)
                    let data = {user_login: log , user_pass: pass }
                    axios.post(url+"api/v1/panel/account/"+id+"/update",data ,
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                    setShow(false)
                    getData("inactive")
                }).catch(err=>{
                    load(false)
                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                    console.log(err);
                })
                load(true)
                    let data1 = {
                         email: email ,
                         number:number , 
                         telegram: telId , 
                         name:first_name,
                         last_name:last_name,
                         account_id:order.account.id
                        }
                        console.log(data1);
                        
                    axios.post(url+"api/v1/panel/edit/buy",data1 ,
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(()=>{
                    load(false)
                    NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                    setShow(false)
                    getData("inactive")
                }).catch(err=>{
                    load(false)
                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                    console.log(err);
                })
                    }else{
                        NotificationManager.error("لطفا همه فیلد ها را پر نمایید"  , "اطلاعات شما ثبت نشد",3000)
                       
                    }
                }else{
                    
                    if(pass !== "" && log !== "" 
                        && first_name!=="" && last_name!==""&& number!=="" &&
                        email!==""
                        ){
                            load(true)
                        let data = {user_login: log , user_pass: pass }
                        axios.post(url+"api/v1/panel/account/"+id+"/update",data ,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                        NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                        setShow(false)
                        getData("inactive")
                    }).catch(err=>{
                        load(false)
                        NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                        console.log(err);
                    })
                    load(true)
                        let data1 = {
                            email: email , 
                            number:number , 
                            name:first_name,
                            last_name:last_name,
                            account_id:order.account.id}
                        axios.post(url+"api/v1/panel/edit/buy",data1 ,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                        NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                        setShow(false)
                        getData("inactive")
                    }).catch(err=>{
                        load(false)
                        NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                        console.log(err);
                    })
                        }else{
                            NotificationManager.error("لطفا همه فیلد ها را پر نمایید"  , "اطلاعات شما ثبت نشد",3000)
                           
                        }
                }
            }else{
                if(order.platform == "MT5"){
                    if(first_name!=="" && last_name!==""&& number!=="" &&
                    email!==""
                    ){
                    load(true)
                    let data = {delivery_type:"us"}
                    axios.post(url+"api/v1/panel/account/"+id+"/update",data ,
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                    setShow(false)
                    getData("inactive")
                }).catch(err=>{
                    load(false)
                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                    console.log(err);
                })
                load(true)
                    let data1 = {
                        email: email , 
                        number:number , 
                        telegram: telId , 
                        name:first_name ,
                        last_name:last_name ,
                        account_id:order.account.id }
                    axios.post(url+"api/v1/panel/edit/buy",data1 ,
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                    setShow(false)
                    getData("inactive")
                }).catch(err=>{
                    load(false)
                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                    console.log(err);
                })
                    }else{
                        NotificationManager.error("لطفا همه فیلد ها را پر نمایید"  , "اطلاعات شما ثبت نشد",3000)
                       
                    }
                }else{
                    
                    if(first_name!=="" && last_name!==""&& number!=="" &&
                        email!==""
                        ){
                        load(true)
                        let data = {delivery_type:"us"}
                        axios.post(url+"api/v1/panel/account/"+id+"/update",data ,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                        NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                        setShow(false)
                        getData("inactive")
                    }).catch(err=>{
                        load(false)
                        NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                        console.log(err);
                    })
                    load(true)
                        let data1 = {
                            email: email , 
                            number:number , 
                            name:first_name,
                            last_name:last_name,
                            account_id:order.account.id
                          }
                        axios.post(url+"api/v1/panel/edit/buy",data1 ,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                        NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                        setShow(false)
                        getData("inactive")
                    }).catch(err=>{
                        load(false)
                        NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                        console.log(err);
                    })
                        }else{
                            NotificationManager.error("لطفا همه فیلد ها را پر نمایید"  , "اطلاعات شما ثبت نشد",3000)
                           
                        }
                }
            }
          }} className='logBtn mt'>
            شروع چالش
          </button>
          </div>
        }else{
            if(order.platform == "MT5"){
              return <>
            <div className='flex1'>
            <div className='gg'> <FaUserCheck /> نام   </div>
            <input value={first_name} onChange={(e)=>{
             setName(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <FaHouseUser /> نام خانوادگی   </div>
            <input value={last_name} onChange={(e)=>{
             setLast_name(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <MdOutlineAlternateEmail /> ایمیل   </div>
            <input value={email} onChange={(e)=>{
             setEmail(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> < FaPhone /> شماره همراه   </div>
            <input value={number} onChange={(e)=>{
             setNumber(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
           <> 
            <input checked={gavanin} className='radio mipaziram' onChange={(e)=>{
               setGavanin(e.target.checked)
                
             }}   name="gavanin" type='checkbox' /> 
             <a  href='https://t.me/userinfobot'
			  target="_blank"  className='f12'> شناسه عددی تلگرام را دریافت کردم (کلیک نمایید -- الزامی)  </a>
             
          <div className='flex1'>
            <div className='gg'> < FaTelegram/> شناسه عددی تلگرام  <AiFillQuestionCircle onClick={()=>{
                NotificationManager.success("شناسه عددی 10 رقمی که برای فعال کردن پنل آنالیز شماست را وارد نمایید ",
                    "راهنما",8000)
            }} style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    verticalAlign:"middle"
                }} /> </div>
            <input disabled={!gavanin} value={telId} onChange={(e)=>{
             setTelId(e.target.value)
             if(isNaN(+e.currentTarget.value)){
                setTelId("")
            }
            }} type='text' className='loginInput'/>
          </div>
          <input checked={gavanin1} className='radio mipaziram' onChange={(e)=>{
               setGavanin1(e.target.checked)
                
             }}   name="das" type='checkbox' /> 
             <a  href='https://t.me/Mt5reportbot' 
			  target="_blank"  className='f12'> Mt5reportbot را فعال کردم (کلیک نمایید -- الزامی)   </a>
              <p className='f12' style={{color:"red"}}> *در صورت صحیح نبودن شناسه عددی تلگرام گزارش 
            حساب  شما از قبیل <br/> تخلفات 
            ، منسوخی حساب و ... برایتان از تلگرام ارسال نخواهد شد  </p>
          </>
          
          <button disabled={!gavanin1} onClick={()=>{
            if(number !== "" && email !== "" && telId!=="" && first_name!== "" && last_name !==""
                && number!==null && email!==null && telId!==null && first_name!== "" && last_name!==""
            ){
                load(true)
            let data = {
                name: first_name , 
                last_name: last_name, 
                email: email , 
                number:number , 
                telegram : telId,
                account_id:order.account.id}
                
            axios.post(url+"api/v1/panel/edit/buy",data ,
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
            } }).then(res=>{
                load(false)
                NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                setShow(false)
                getData("inactive")
            }).catch(err=>{
                load(false)
                NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                console.log(err);
            })
            }else{
            NotificationManager.error("لطفا همه فیلد ها را پر نمایید"  , "اطلاعات شما ثبت نشد",3000)
            }
          }} className='logBtn'>
           شروع چالش
          </button>
            </>
          }else{
            return <>
            <div className='flex1'>
            <div className='gg'> <FaUserCheck /> نام   </div>
            <input value={first_name} onChange={(e)=>{
             setName(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <FaHouseUser /> نام خانوادگی   </div>
            <input value={last_name} onChange={(e)=>{
             setLast_name(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <MdOutlineAlternateEmail /> ایمیل   </div>
            <input value={email} onChange={(e)=>{
             setEmail(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> < FaPhone /> شماره همراه   </div>
            <input value={number} onChange={(e)=>{
             setNumber(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          
          <button onClick={()=>{
            if(number !== "" && email !== "" && first_name!== "" && last_name !=="" &&
              number !== null && email!== null && first_name!== "" && last_name!==null
            ){
                load(true)
            let data = {
                name: first_name , 
                last_name: last_name, 
                email: email , 
                number:number ,
                account_id:order.account.id
              }
            axios.post(url+"api/v1/panel/edit/buy",data ,
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
            } }).then(res=>{
                load(false)
                NotificationManager.success("اطلاعات شما با موفقیت ثبت شد","ثبت موفق",3000)
                setShow(false)
                getData("inactive")
            }).catch(err=>{
                load(false)
                NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات شما ثبت نشد",3000)
                console.log(err);
            })
            }else{
            NotificationManager.error("لطفا همه فیلد ها را پر نمایید"  , "اطلاعات شما ثبت نشد",3000)
            }
          }} className='logBtn'>
           شروع چالش 
          </button>
            </>
          }
         }
            
    }
  return (
    <div>
        <Success show={modal} closeModal={()=>{setModal(false)
            setOrder({})
            setGavanin(false)
            setGavanin1(false)
        } } name="insidefjkjhfj">
          {inModalShow()}
        </Success>
        <NotificationContainer/>
        <Success show={show} closeModal={()=>{
            setShow(false)
            setAcc(true)
            setOrder({})
            setGavanin(false)
            setGavanin1(false)
        }} name="allOrders">
          {inModal2()}
        </Success>
        <Success show={show1} closeModal={()=>{
           setShow1(false)
           setLog("")
           setPass("")
           setPass("")
           setBrok("")
           setServer([])
           setPlatforms({})
           setPlat("")
           setServer([])
        }} name="allOrders3223">
          <div>
           <div className='bb'> مشخصات حساب قبلی خود را وارد نمایید  </div>
            <div>
                 <div style={{maxWidth:"380px" , margin:"10px auto"}} className='f12 '>
                    این بخش موقتی بوده و مربوط به حساب فعال خریداری شده قبل از بارگزاری وب اپلیکیشن پنل کاربری ( my.fenefx.net ) 
                    می باشد. <br/>
                    حساب های خریداری شده از طریق وب اپلیکیشن ( قسمت دریافت سرمایه ) بلافاصله بعد از خرید در 
                    قسمت غیر فعال قابل مشاهده است. 
                 </div>
                 <div className='bb' >
                 <div className='flex1 '>
                           <div className='gg'> <RiShieldUserFill /> شماره لاگین   </div>
                           <input value={log} onChange={(e)=>{
                            setLog(e.target.value)
                            }} type='text' className='loginInput'/>
                         </div>
                         <div className='flex1'>
                           <div className='gg'> <RiLockPasswordFill /> رمز Investor   </div>
                           <input style={{direction:"ltr",textAlign:"left"}} value={pass} onChange={(e)=>{
                            setPass(e.target.value)
                           }} type='text' className='loginInput'/>
                         </div>
               </div>
               <div className="bb">
                <label style={{paddingTop:"10px"}}> نوع حساب </label>
                <select className="second" value={step} onChange={(e)=>{
                      setStep(e.target.value)
                      const x = ()=>{
                        if(e.currentTarget.value == "First Step" || 
                           e.currentTarget.value == "Second Step" ||
                           e.currentTarget.value == "Real"
                        ){
                           return 1
                        }else{
                            return 2
                        }
                      }
                       if(e.target.value !== ""){
                        load(true)
                             axios.get(url+"api/v1/panel/category_products?package_id="+ x(),
                                 { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,     
                             } }).then(res=>{
                                 load(false)
                                 setProduct(res.data.products)
                                 
                             }).catch(err=>{
                                 load(false)
                                 console.log(err);
                             })
                       }else{
                        setProduct([])
                       }
                         }}>
                         <option value=""> نوع حساب را انتخاب نمایید </option>
                         <option value="First Step"> چالش دو مرحله ای - مرحله اول </option>
                         <option value="Second Step"> چالش دو مرحله ای - مرحله دوم </option>
                         <option value="Pro Trader"> پروتریدر </option>
                         <option value="Real"> REAL </option>
                     </select>
                     </div>
                     {product.length !== 0? <div className="bb">
                <label style={{paddingTop:"10px"}}> حساب </label>
                <select className="second" value={pro} onChange={(e)=>{
                      setPro(e.target.value)
                      setBrok("")
                         }}>
                         <option  value=""> حساب را انتخاب نمایید </option>
                         {product.map(d=>{
                             return <option key={d.id} value={d.id}> { d.title} </option>
                         })}
                     </select>
                     </div>:null}
               <div className="bb">
                <label style={{paddingTop:"10px"}}> بروکر </label>
                <select className="second" value={brok} onChange={(e)=>{
                      setBrok(e.target.value)
                       setServer([])
                   if(e.target.value !== ""){
                                 load(true)
                             axios.get(url+"api/v1/panel/broker_servers?broker_id="+ e.currentTarget.value,
                                 { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,     
                             } }).then(res=>{
                                 load(false)
                                 setPlatforms(res.data.broker_servers) 
                                 console.log(res.data);
                                 setPlat("") 
                             }).catch(err=>{
                                 load(false)
                                 console.log(err);
                             }) 
                             }else{
                                 setPlatforms({})
                             }
                         }}>
                         <option  value=""> بروکر را انتخاب نمایید </option>
                         {brokers.map(d=>{
                             return <option key={d.id} value={d.id}> { d.name} </option>
                         })}
                     </select>
                     </div>
                      {Object.keys(platforms).length !== 0? <div className='bb'>
                          <label style={{paddingTop:"10px"}}> پلتفرم را انتخاب نمایید </label>
                         <select className='second' value={plat} onChange={(e)=>{
                             setPlat(e.target.value)
                                setServer([])
                              setServerName("")
                                  if(e.target.value == "mt5"){
                                    setServer(platforms.mt5)
                                 }else if(e.target.value == "mt4"){
                                    setServer(platforms.mt4)
                                    }
         
                                   }}>
                          <option   value=""> پلتفرم را انتخاب نمایید </option>
                            {Object.keys(platforms).map(d=>{
                              return <option key={platforms[d].id} value={platforms[d].id}> {d} </option>
                                  })}
                         </select>
                </div>: null}
                {server.length !== 0 ? <div className='bb'>
                    <label style={{paddingTop:"10px"}}> سرور را انتخاب نمایید </label>
                <select className='second' value={serverName} onChange={(e)=>{
                        setServerName(e.target.value)
                    }}>
                    <option value=""> سرور را انتخاب نمایید </option>
                    {server.map(d=>{
                        return <option key={d.id} value={d.title}> {d.title} </option>
                    })}
                </select>
                </div>: null}
                {serverName !== "" && brok !=="" && plat !== "" && step !=="" && pro !== ""  ?
                <button onClick={()=>{
                    let data = {platform: plat , broker : brok , server:serverName ,
                         login : log , password : pass , step:step , product_id : pro
                    }
                    if(log !== "" && pass !==""){
                        if(step == "Real"){
                            if(user.dataes.confirmed_authentications.find(e=>e.type=="Video_auth")){
                                load(true)
                              axios.post(url+"api/v1/panel/account/existing",data ,
                             { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                              } }).then(res=>{
                                  load(false)
                                  setShow1(false)
                                  NotificationManager.success("اطلاعات اکانت با موفقیت ارسال شد ","درخواست انجام شد",4000)
                                  getData("inactive")
                                  setAc({a1: "", a2: "active", a3: ""})
                              }).catch(err=>{
                                 load(false)
                                 if(err.response.status == 422){
                                   NotificationManager.error("اکانت وارد شده قبلا وارد شده است","انجام نشد",5000)
                                 }else if(err.response.status == 423){
                                     NotificationManager.error("اکانت وارد شده قبلا نقض شده","انجام نشد",5000)
                                 }else{
                                     NotificationManager.error("بعدا دوباره امتحان نمایید","انجام نشد",5000)
                                 }
                                  console.log(err);
                              })
                            }else{
                                NotificationManager.warning("احراز هویت مرحله دوم ( ویدیویی ) انجام نشده ، ابتدا احراز هویت مرحله دوم را تکمیل نمایید")
                            }
                        }else{
                            load(true)
                  axios.post(url+"api/v1/panel/account/existing",data ,
                 { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                 load(false)
                 setShow1(false)
                 NotificationManager.success("اطلاعات اکانت با موفقیت ارسال شد ","درخواست انجام شد",4000)
                 getData("inactive")
                 setAc({a1: "", a2: "active", a3: ""})
             }).catch(err=>{
                load(false)
                if(err.response.status == 422){
                  NotificationManager.error("اکانت وارد شده قبلا وارد شده است","انجام نشد",5000)
                }else if(err.response.status == 423){
                    NotificationManager.error("اکانت وارد شده قبلا نقض شده","انجام نشد",5000)
                }else{
                    NotificationManager.error("بعدا دوباره امتحان نمایید","انجام نشد",5000)
                }
                 console.log(err);
             })
                        }
                    }else{
                        NotificationManager.error("لاگین یا Investor خالی می باشد","انجام نشد", 5000)
                    }
                }} className='button login__submit1'>
                   ارسال اطلاعات 
                </button>: null}
                          </div>
                  </div>
        </Success>
      <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{clickOnAcc(1)}} className={active.a1}>
                فعال
            </a>
            <a onClick={()=>{clickOnAcc(2)}} className={active.a2}>
                غیر فعال
                {newCount(count)}
            </a>
            <a onClick={()=>{clickOnAcc(3)}} className={active.a3}>
                منسوخ شده
            </a>
            <a onClick={()=>{clickOnAcc(4)}} className={active.a4}>
                تکمیل شده
            </a>
            {/* <a onClick={()=>{
            NotificationManager.info("این قسمت مربوط به ورود اطلاعات حساب های خریداری شده به روش قبلی ( واتساپ ) می باشد. از ورود "+
             "اطلاعات حساب های خریداری شده از طریق پنل کاربری اکیدا خودداری نمایید","راهنما",10000)
                load(true)
                axios.get(url+"api/v1/panel/order_page",
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    setBrokers(res.data.brokers)
                    console.log(res.data);
                    
                    setShow1(true)
                }).catch(err=>{
                    load(false)
                    console.log(err);
                })
            }} style={{border:"1px solid" , background:"#042d21"}} >
                حساب فعال دارم <FaRegQuestionCircle  style={{ marginRight:"10px" , marginLeft:"0px"}} />
            </a>  */}
        </div>
          <>
            <div className='transAction'>
                <div className='eachTicket'>
                    <div className='bb'> وضعیت سرمایه های دریافت شده  : </div>
                    {active.a1 == "active" ?<p className='mt f10'> 
                        *برای ثبت درخواست و اطلاع از وضعیت درخواست بر روی وضعیت مربوط به آن حساب کلیک نمایید </p>:null}
                      {active.a4 == "active" ?<p className='mt f10'>
                        *برای مشاهده یا دانلود Certificate خود روی آیکون <span> <GiTrophyCup style={{
                            fontSize:"20px",
                            verticalAlign:"middle"
                        }}/> </span> کلیک نمایید
                      </p>:null}
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover thMinMob">
                            <thead >
                                <tr>
                                    {active.a1?<th> وضعیت </th>: null}
                                    {active.a4?<th></th>:null}
                                    <th> حساب </th>
                                    <th> پلتفرم </th>
                                    <th> ورود </th>
                                    <th> گذرواژه </th>
                                    <th> INVESTOR </th>
                                    <th> سرور  </th>    
                                    <th> تراکنش  </th>    
                                    <th> مرحله  </th>    
                                </tr>
                            </thead>
                            <tbody>
                             {hesab.length == 0 ? 
                              <tr>
                               <th style={{textAlign:"center"}} colSpan={12}> اطلاعاتی یافت نشد  </th>
                              </tr>: null}
                             {hesabs()}
                            </tbody>
                        </table>
                    </div>
                </div>
             </div>
            </>
    </div>
    </div>
  )
}