/* eslint-disable eqeqeq */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const parseCustomDate = (dateString) => {
    const [time, date] = dateString.split(' ');
    return new Date(`${date}T${time}`);
  };
  const processDataByMonth = (data) => {
    const monthlyData = {};
  
    if (Array.isArray(data)) {
      data.forEach((item) => {
        const date = parseCustomDate(item.created_at);
        const month = date.toLocaleString('default', { month: 'long', year: 'numeric' });
  
        // Initialize month if it doesn't exist
        if (!monthlyData[month]) {
          monthlyData[month] = { 
            total: 0, 
            newAcc: 0 , 
            notNew: 0 , 
            walletRecharge: 0 ,
            coinsBuy:0 ,
            tcPay: 0,
          };
        }
  
        // Increment the total count for this month
        monthlyData[month].total += 1;
  
        // Increment the new account count if the transaction type is "New Account"
        
          if (item.transaction && item.transaction.transition_type === "New Account") {
            monthlyData[month].newAcc += 1;
          }else if (item.transaction && item.transaction.transition_type !== "New Account"){
            monthlyData[month].notNew += 1;
          }
          // -------------
          if (item.gateway == 1) {
            monthlyData[month].walletRecharge += 1;
          }else if (item.gateway == 2){
            monthlyData[month].coinsBuy += 1;
          }else if (item.gateway == 3){
            monthlyData[month].tcPay += 1;
          }
        
      });
    }
    const labels = Object.keys(monthlyData);
    const counts = Object.values(monthlyData).map((item) => item.total);
    const newAccCounts = Object.values(monthlyData).map((item) => item.newAcc);
    const notNewAcc = Object.values(monthlyData).map((item) => item.notNew);
    const coinsBuy = Object.values(monthlyData).map((item) => item.coinsBuy);
    const walletRecharge = Object.values(monthlyData).map((item) => item.walletRecharge);
    const tcPay = Object.values(monthlyData).map((item) => item.tcPay);
    return { labels, counts, newAccCounts,notNewAcc , tcPay , walletRecharge ,coinsBuy};
  };
  const processChartData = (data) => {
    const typeCounts = {};
    if (Array.isArray(data)){
      data.forEach((d) => {
        const productType = d.order?.product?.type;
        const productTitle = d.order?.product?.title;
    
        if (productType && productTitle) {
          // Initialize the type if it doesn't exist
          if (!typeCounts[productType]) {
            typeCounts[productType] = {};
          }
    
          // Count each title within the type
          if (!typeCounts[productType][productTitle]) {
            typeCounts[productType][productTitle] = 1;
          } else {
            typeCounts[productType][productTitle] += 1;
          }
        }
      });
    }
    
  
    // Extract labels and datasets from typeCounts
    const labels = Object.keys(typeCounts); // Product types as labels
  
    // Prepare datasets based on each title across types
    const titleCounts = {}; // Temporary object to collect title counts across types
  
    labels.forEach((type) => {
      const titles = typeCounts[type];
      Object.keys(titles).forEach((title) => {
        if (!titleCounts[title]) {
          titleCounts[title] = Array(labels.length).fill(0); // Initialize array for each title with zeroes
        }
        titleCounts[title][labels.indexOf(type)] = titles[title];
      });
    });
  
    const datasets = Object.keys(titleCounts).map((title) => ({
      label: title,
      data: titleCounts[title],
      backgroundColor: `rgba(
       ${Math.floor(Math.random() * 255)},
       ${Math.floor(Math.random() * 255)}, 
       ${Math.floor(Math.random() * 255)}
       , 1)`,
    }));
  
    return { labels, datasets };
  };
  const processDataByWeekday = (data) => {
    const weeklyData = {};
    if (Array.isArray(data)) {
        data.forEach((item) => {
        const date = parseCustomDate(item.created_at);
        const weekday = date.toLocaleString('default', { weekday: 'long' }); // Get day of the week
        // Initialize weekday if it doesn't exist
        if (!weeklyData[weekday]) {
          weeklyData[weekday] = { 
            total: 0, 
            newAcc: 0 , 
            notNew: 0 , 
            walletRecharge: 0 ,
            coinsBuy:0 ,
            tcPay: 0,
          };
        }
        // Increment the total count for this day
        weeklyData[weekday].total += 1;
        // Increment counts based on transaction type
        if (item.transaction && item.transaction.transition_type === "New Account") {
          weeklyData[weekday].newAcc += 1;
        } else if (item.transaction && item.transaction.transition_type !== "New Account") {
          weeklyData[weekday].notNew += 1;
        }
        //---------------
        if (item.gateway == 1 && +item.verified_by>1) {
          weeklyData[weekday].walletRecharge += 1;
        }else if (item.gateway == 2){
          weeklyData[weekday].coinsBuy += 1;
        }else if (item.gateway == 3){
          weeklyData[weekday].tcPay += 1;
        }
      });
    }
    const labels = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    const counts = Object.values(weeklyData).map((item) => item.total);
    const newAccCounts = Object.values(weeklyData).map((item) => item.newAcc);
    const notNewAcc = Object.values(weeklyData).map((item) => item.notNew);
    const coinsBuy = Object.values(weeklyData).map((item) => item.coinsBuy);
    const walletRecharge = Object.values(weeklyData).map((item) => item.walletRecharge);
    const tcPay = Object.values(weeklyData).map((item) => item.tcPay);
    return { labels, counts, newAccCounts,notNewAcc , tcPay , walletRecharge ,coinsBuy};

  };
  const processDataByHour = (data) => {
    const hourlyData = {};
  
    if (Array.isArray(data)) {
      data.forEach((item) => {
        const date = parseCustomDate(item.created_at);
        const hour = date.getHours(); // Get the hour (0-23)
  
        // Initialize hour if it doesn't exist
        if (!hourlyData[hour]) {
          hourlyData[hour] = { 
            total: 0, 
            newAcc: 0 , 
            notNew: 0 , 
            walletRecharge: 0 ,
            coinsBuy:0 ,
            tcPay: 0,
          };
        }
  
        // Increment the total count for this hour
        hourlyData[hour].total += 1;
  
        // Increment counts based on transaction type
        if (item.transaction && item.transaction.transition_type === "New Account") {
          hourlyData[hour].newAcc += 1;
        } else if (item.transaction && item.transaction.transition_type !== "New Account") {
          hourlyData[hour].notNew += 1;
        }
        //---------------
        if (item.gateway == 1) {
          hourlyData[hour].walletRecharge += 1;
        }else if (item.gateway == 2){
          hourlyData[hour].coinsBuy += 1;
        }else if (item.gateway == 3){
          hourlyData[hour].tcPay += 1;
        }
      });
    }
  
    // Generate labels for each hour (0 to 23) to ensure all hours are included, even if they have no data
    const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
  
    // Map the data for each hour, filling in zeroes if there was no data for a particular hour
    const counts = labels.map((label) => hourlyData[parseInt(label)]?.total || 0);
    const newAccCounts = labels.map((label) => hourlyData[parseInt(label)]?.newAcc || 0);
    const notNewAcc = labels.map((label) => hourlyData[parseInt(label)]?.notNew || 0);
    const walletRecharge = labels.map((label) => hourlyData[parseInt(label)]?.walletRecharge || 0);
    const coinsBuy = labels.map((label) => hourlyData[parseInt(label)]?.coinsBuy || 0);
    const tcPay = labels.map((label) => hourlyData[parseInt(label)]?.tcPay || 0);
    return { labels, counts, newAccCounts, notNewAcc ,walletRecharge , tcPay , coinsBuy};
  };
  
  

const Analyze = (props) => {
  const chartData = (e)=>{ 
   if(e === "month"){
    const { labels, counts , newAccCounts ,notNewAcc } = processDataByMonth(props.data);
    return {
    
        labels: labels,
        datasets: [
          {
            label: 'تعداد کل حساب های ایجاد شده',
            data: counts,
            backgroundColor: 'blue',
          },
          {
            label: 'تعداد حساب های با پرداخت',
            data: newAccCounts,
            backgroundColor: 'rgba(75, 192, 80, 1)',
          },
          {
            label: 'تعداد حساب های بدون پرداخت',
            data: notNewAcc,
            backgroundColor: 'red',
          },
        ],
      };
   }else if(e === "week"){
    const { labels, counts , newAccCounts ,notNewAcc } = processDataByWeekday(props.data);
    return {
        labels: labels ,
        datasets: [
          {
            label: 'تعداد کل حساب های ایجاد شده',
            data: counts,
            backgroundColor: 'blue',
          },
          {
            label: 'تعداد حساب های با پرداخت',
            data: newAccCounts,
            backgroundColor: 'rgba(75, 192, 80, 1)',
          },
          {
            label: 'تعداد حساب های بدون پرداخت',
            data: notNewAcc,
            backgroundColor: 'red',
          },
        ],
      };
   }else if(e === "hour"){
    const { labels, counts , newAccCounts ,notNewAcc } = processDataByHour(props.data);
    return {
        labels: labels ,
        datasets: [
          {
            label: 'تعداد کل حساب های ایجاد شده',
            data: counts,
            backgroundColor: 'blue',
          },
          {
            label: 'تعداد حساب های با پرداخت',
            data: newAccCounts,
            backgroundColor: 'rgba(75, 192, 80, 1)',
          },
          {
            label: 'تعداد حساب های بدون پرداخت',
            data: notNewAcc,
            backgroundColor: 'red',
          },
        ],
      };
   }
  } 
  const chartData2 = (e)=>{ 
    if(e === "month"){
     const { labels, counts , tcPay , walletRecharge ,coinsBuy } = processDataByMonth(props.data);
     
     
     return {
     
         labels: labels,
         datasets: [
           {
             label: 'تعداد کل تراکنش ها',
             data: counts,
             backgroundColor: 'yellowgreen',
           },
           {
            label: 'واریز سود',
            data: walletRecharge,
            backgroundColor: 'orange',
          },
           {
             label: 'COINSBUY',
             data: coinsBuy,
             backgroundColor: 'blue',
           },
           {
             label: 'TOP CHANGE',
             data: tcPay,
             backgroundColor: 'white',
           },
         ],
       };
    }else if(e === "week"){
      const { labels, counts , tcPay , walletRecharge ,coinsBuy } = processDataByWeekday(props.data);

     return {
         labels: labels ,
         datasets: [
          {
            label: 'تعداد کل تراکنش ها',
            data: counts,
            backgroundColor: 'yellowgreen',
          },
          {
           label: 'واریز سود',
           data: walletRecharge,
           backgroundColor: 'orange',
         },
          {
            label: 'COINSBUY',
            data: coinsBuy,
            backgroundColor: 'blue',
          },
          {
            label: 'TOP CHANGE',
            data: tcPay,
            backgroundColor: 'white',
          },
        ],
       };
    }else if(e === "hour"){
     const { labels, counts , tcPay , walletRecharge ,coinsBuy } = processDataByHour(props.data);
     return {
         labels: labels ,
         datasets: [
          {
            label: 'تعداد کل تراکنش ها',
            data: counts,
            backgroundColor: 'yellowgreen',
          },
          {
           label: 'واریز سود',
           data: walletRecharge,
           backgroundColor: 'orange',
         },
          {
            label: 'COINSBUY',
            data: coinsBuy,
            backgroundColor: 'blue',
          },
          {
            label: 'TOP CHANGE',
            data: tcPay,
            backgroundColor: 'white',
          },
        ],
       };
    }
   } 
  const options = (e)=>{
    if(e === "month"){
        return {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: 'white', // Set color for legend labels
                  font: {
                    family: 'iransans', // Optional: set font family
                    size: 14, // Optional: set font size
                  },
                },
              },
              title: {
                display: true,
                text: 'نمودار ماهانه حساب های ایجاد شده',
                color: 'white', // Set color for the title
                font: {
                  family: 'yekan', // Optional: set font family
                  size: 20, // Optional: set font size
                },
                
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // Set color for X-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize X-axis grid color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Set color for Y-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize Y-axis grid color
                },
                beginAtZero: true,
              },
            },
          };
    }else if(e === "week"){
        return {
            responsive: true,
            indexAxis: 'y',
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: 'white', // Set color for legend labels
                  font: {
                    family: 'iransans', // Optional: set font family
                    size: 14, // Optional: set font size
                  },
                },
              },
              title: {
                display: true,
                text: 'نمودار هفتگی حساب های ایجاد شده',
                color: 'white', // Set color for the title
                font: {
                  family: 'yekan', // Optional: set font family
                  size: 20, // Optional: set font size
                },
                
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // Set color for X-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize X-axis grid color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Set color for Y-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize Y-axis grid color
                },
                beginAtZero: true,
              },
            },
          };
    }else if(e === "hour"){
        return {
            responsive: true,
            indexAxis: 'x',
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: 'white', // Set color for legend labels
                  font: {
                    family: 'iransans', // Optional: set font family
                    size: 14, // Optional: set font size
                  },
                },
              },
              title: {
                display: true,
                text: 'نمودار ساعتی حساب های ایجاد شده',
                color: 'white', // Set color for the title
                font: {
                  family: 'yekan', // Optional: set font family
                  size: 20, // Optional: set font size
                },
                
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // Set color for X-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize X-axis grid color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Set color for Y-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize Y-axis grid color
                },
                beginAtZero: true,
              },
            },
          };
    }
  }
  const options2 = (e)=>{
    if(e === "month"){
        return {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: 'white', 
                  font: {
                    family: 'iransans',
                    size: 14, 
                  },
                },
              },
              title: {
                display: true,
                text: 'نمودار ماهانه تراکنش های ایجاد شده',
                color: 'white', 
                font: {
                  family: 'yekan', 
                  size: 20, 
                },
                
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // Set color for X-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize X-axis grid color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Set color for Y-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize Y-axis grid color
                },
                beginAtZero: true,
              },
            },
          };
    }else if(e === "week"){
        return {
            responsive: true,
            indexAxis: 'y',
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: 'white', // Set color for legend labels
                  font: {
                    family: 'iransans', // Optional: set font family
                    size: 14, // Optional: set font size
                  },
                },
              },
              title: {
                display: true,
                text: 'نمودار هفتگی تراکنش های ایجاد شده',
                color: 'white', // Set color for the title
                font: {
                  family: 'yekan', // Optional: set font family
                  size: 20, // Optional: set font size
                },
                
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // Set color for X-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize X-axis grid color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Set color for Y-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize Y-axis grid color
                },
                beginAtZero: true,
              },
            },
          };
    }else if(e === "hour"){
        return {
            responsive: true,
            indexAxis: 'x',
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: 'white', // Set color for legend labels
                  font: {
                    family: 'iransans', // Optional: set font family
                    size: 14, // Optional: set font size
                  },
                },
              },
              title: {
                display: true,
                text: 'نمودار ساعتی تراکنش های ایجاد شده',
                color: 'white', // Set color for the title
                font: {
                  family: 'yekan', // Optional: set font family
                  size: 20, // Optional: set font size
                },
                
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // Set color for X-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize X-axis grid color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Set color for Y-axis labels
                  font: {
                    family: 'iransans', // Optional: set font family
                  },
                },
                grid: {
                  color: 'rgba(200, 200, 200, 0.2)', // Customize Y-axis grid color
                },
                beginAtZero: true,
              },
            },
          };
    }else if(e === "product"){
      return {
          responsive: true,
          indexAxis: 'x',
          plugins: {
            legend: {
              position: 'top',
              labels: {
                color: 'white', // Set color for legend labels
                font: {
                  family: 'iransans', // Optional: set font family
                  size: 14, // Optional: set font size
                },
              },
            },
            title: {
              display: true,
              text: 'نمودار بر اساس محصولات',
              color: 'white', // Set color for the title
              font: {
                family: 'yekan', // Optional: set font family
                size: 20, // Optional: set font size
              },
              
            },
          },
          scales: {
            x: {
              ticks: {
                color: 'white', // Set color for X-axis labels
                font: {
                  family: 'iransans', // Optional: set font family
                },
              },
              grid: {
                color: 'rgba(200, 200, 200, 0.2)', // Customize X-axis grid color
              },
            },
            y: {
              ticks: {
                color: 'white', // Set color for Y-axis labels
                font: {
                  family: 'iransans', // Optional: set font family
                },
              },
              grid: {
                color: 'rgba(200, 200, 200, 0.2)', // Customize Y-axis grid color
              },
              beginAtZero: true,
            },
          },
        };
  }
  }

  const mainShow = () => {if(props.type == 2){
    return <div>
      <Bar style={{
        }} data={chartData2("month",props.type)} options={options2("month")} />
      <Bar style={{
        }} data={chartData2("week",props.type)} options={options2("week")} />
      <Bar style={{
        }} data={chartData2("hour",props.type)} options={options2("hour")} />
  </div>
  }else if(props.type == 1){
    return (
      <div>
        <Bar style={{
        }} data={chartData("month",props.type)} options={options("month")} />
        <Bar style={{
        }} data={chartData("week",props.type)} options={options("week")} />
        <Bar style={{
        }} data={chartData("hour",props.type)} options={options("hour")} />
      </div>
    );
  }}
  return (
    <div>
      {mainShow()}
      <Bar data={processChartData(props.data)} options={options2("product")}/>
    </div>
  );
};

export default Analyze;
