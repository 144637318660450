/* eslint-disable jsx-a11y/anchor-is-valid */
import {React,useContext,useState} from 'react'
import { BiSolidLogInCircle } from "react-icons/bi";
import { TbPasswordFingerprint } from "react-icons/tb";
import { IoMdBarcode } from "react-icons/io";
import logo from "../../photoes/logo.png"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import UserContext from "../UserContext/UserContext"
import validator from 'validator';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export default function Forgot(props) {
    const { url, load } = useContext(UserContext);
    const [barrasi , setBarrasi] = useState(true)
    const [afterUser , setAfter] = useState(false)
    const [password, setPassword] = useState("")
	const [password2, setPassword2] = useState("")
    const [number, setNumber] = useState("")
    const [yek, setYek] = useState("")
    const [time, setTime] = useState(300)
    const [type, settype] = useState("")
 const [disable , setDis] = useState(false)

    const barrasiShow = ()=>{
	
        if(barrasi){
            return <div className='flex'>
            <CountdownCircleTimer
            isPlaying
            duration={time}
            colors={['#fff', '#003e23', '#f05c03', '#f00']}
            colorsTime={[100, 55, 30, 0]}
            size={35}
            strokeWidth={3.5}
            onComplete={()=>{
                setBarrasi(false)
                setDis(true)
                setYek("")
            }}
            children={({ remainingTime }) => {
                const minutes = Math.floor(remainingTime / 60)
                const seconds = remainingTime % 60
              
                return `${minutes}:${seconds}`
              }}
            
          >
            
          </CountdownCircleTimer> 
             <div className='barrasi'> تغییر گذرواژه </div>
            </div>
        }else{
            return "ارسال مجدد"
        }
     }
    const after = ()=>{
        if(afterUser){
        return <>
          <form onSubmit={(e)=>{e.preventDefault()}} className="login">
            
            <div  className="login__field">					
                <input disabled={disable} value={yek}  type="text"
                onChange={(e)=>{
                  setYek(e.target.value)
                }}
                className="login__input" placeholder=" کد تایید "/>
                <IoMdBarcode/>
            </div>
            <div className="login__field">					
                <input  disabled={disable}  type="password"
                onChange={(e)=>{setPassword(e.target.value)}}
                 className="login__input" value={password} placeholder=" گذرواژه جدید "/>
                 <TbPasswordFingerprint />
            </div>
            <div className="login__field">					
                <input  disabled={disable}  type="password"
                onChange={(e)=>{setPassword2(e.target.value)}}
                className="login__input" value={password2} placeholder=" تکرار گذرواژه جدید "/>
                <TbPasswordFingerprint color='#cca64d'/>
            </div>
            <button onClick={()=>{
                if(barrasi){
                     if(yek !== "" && password !== "" && password2 !== "" && password === password2 && password.length > 7){
                        load(true)
                        axios.post(url+"api/v1/auth/forget_password/verify",{username: number , code: yek , new_password : password }).then(
                            (res)=>{
                                load(false)
                                if(res.data.message !== undefined &&  res.data.message === "Invalid Code"){
                                  NotificationManager.error("کد تایید صحیح نمی باشد","تغییر گذرواژه انجام نشد",5000)
                                  
                                }else{
                                    NotificationManager.success("گذرواژه شما با موفقیت تغییر یافت","عملیات موفق",5000)
                                props.login()
                                }
                            }
                        ).catch(er=>{
                            load(false)
                            
                        NotificationManager.error("کد تایید وارد شده صحیح نمی باشد","گذرواژه تغییر نیافت",5000)
                         
                        })
                     }else{
                        if(password !== password2){
                            NotificationManager.error("گذرواژه با تکرار گذرواژه برابر نیست","تغییر گذرواژه انجام نشد",5000)
                        }else if(password.length < 8){
                            NotificationManager.error("گذرواژه باید حداقل 8 کاراکتر داشته باشد","تغییر گذرواژه انجام نشد",5000)
                         
                        }
                        else{
                            NotificationManager.error("لطفا تمامی فیلد ها را پر نمایید","تغییر گذرواژه انجام نشد",5000)
                        }
                     }
                }else{
                    let type = ()=>{
                        if(validator.isEmail(number)){
                            return "email"
                        }else if(validator.isMobilePhone(number , ['fa-IR'])){
                            return "phone"
                        }else{
                            return ""
                        }
                     }
                    load(true)
                    axios.post(url+"api/v1/auth/forget_password",{username: number , type: type()}).then(
                        (res)=>{
                            load(false)
                            setTime(res.data.time)
                            setBarrasi(true)
                            setDis(false)
                            if(res.data.message === "Code Already Sent"){
                                NotificationManager.success("کد تایید شما قبلا ارسال شده و قابل استفاده است","",5000)

                            }else{
                                NotificationManager.success("کد تایید شما با موفقیت ارسال شد","",5000)

                            }
                            

                        }
                    ).catch(er=>{
                        load(false)
                        
                    NotificationManager.error("ایمیل یا شماره همراه موجود نمی باشد","کد تایید ارسال نشد",5000)
                     
                    })
                }
                
            }} className="button login__submit">
                <span className="button__text"> 
                    {
                        barrasiShow()
                    }
                     </span>
                
            </button>				
        </form>
        <div className='forgotAndReg'>
        <a onClick={()=>{
            setAfter(false)
        }}  className='alog'> 
             تغییر شماره همراه /ایمیل
        </a>
      </div>
        </>
        }else{
            return <>
            <form onSubmit={(e)=>{e.preventDefault()}} className="login">
            <div className="login__field">					
                <input value={number}  type="text" className="login__input"
                onChange={(e)=>{setNumber(e.target.value)}}
                placeholder=" شماره همراه / ایمیل "/>
                <BiSolidLogInCircle />
            </div>
           
            <button onClick={()=>{
                if(number !== ""){
                    if(validator.isEmail(number) || validator.isMobilePhone(number , ['fa-IR'])){
                     let type = ()=>{
                        if(validator.isEmail(number)){
                            return "email"
                        }else if(validator.isMobilePhone(number , ['fa-IR'])){
                            return "phone"
                        }else{
                            return ""
                        }
                     }
                     settype(type)
                     load(true)
                    
                     axios.post(url+"api/v1/auth/forget_password",{username: number , type: type()}).then(
                        (res)=>{
                            load(false)
                            setTime(res.data.time)
                            setAfter(true) 
                            setDis(false)
                            setBarrasi(true)
                            if(res.data.message === "Code Already Sent"){
                                NotificationManager.success("کد تایید شما قبلا ارسال شده و قابل استفاده است","",5000)

                            }else{
                                NotificationManager.success("کد تایید شما با موفقیت ارسال شد","",5000)

                            }
                            

                        }
                    ).catch(er=>{
                        load(false)
                        
                    NotificationManager.error("ایمیل یا شماره همراه موجود نمی باشد","کد تایید ارسال نشد",5000)
                     
                    })
                    }else{
                    NotificationManager.error("شماره یا ایمیل معتبر نمی باشد","کد تایید ارسال نشد",5000)

                    }
                }else{
                    NotificationManager.error("شماره یا ایمیل را وارد نمایید","کد تایید ارسال نشد",5000)
                }
            }} className="button login__submit">
                <span className="button__text"> دریافت کد تایید</span>
                
            </button>				
        </form>
        <div className='forgotAndReg'>
        <a onClick={props.login}  className='alog'> 
           بازگشت
        </a>
      </div>
            </>
            
        }
    }
  return (
    <div className={window.innerWidth < 800 ? "container2":"container1"}>
		<NotificationContainer/>
		
	<div className="screen">
		<div className="screen__content">
      <div className='logoCon'>
      <img className='logo' src={logo} alt=''/>
      </div>
	         
			{after()}
			
		</div>
		<div className="screen__background">
			
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</div>
  )
}
