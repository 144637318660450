/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useRef, useState , useContext, useEffect } from 'react'
import "./Auth.css"
import SucImg from "../../photoes/148767.png"
import { SiTicktick } from "react-icons/si";
import { GiCancel } from "react-icons/gi";
import UserContext from "../UserContext/UserContext"
import Cookies from 'js-cookie';
import axios from 'axios';
import Success from '../Success/Success';
import ReactPlayer from 'react-player';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { RiInformation2Fill } from "react-icons/ri";
export default function Auth() {
const { user , load , url } = useContext(UserContext);
const [s,setS] = useState({show1:  "image-upload-wrap", show2: "file-upload-content"})
const [s2,setS2] = useState({show1:  "image-upload-wrap", show2: "file-upload-content"})
const [s3,setS3] = useState({show1:  "image-upload-wrap", show2: "file-upload-content"})

const [image1,setImage] = useState(null)
const [image2,setImage2] = useState(null)
const [err,setErr] = useState("")

const imgRef = useRef("")
const imgRef2 = useRef("")
const imgIn = useRef("")
const imgIn2 = useRef("")
const [selectedFile, setSelectedFile] = useState(null);
const [previewUrl, setPreviewUrl] = useState(null);
useEffect(()=>{
    if(user.dataes.video_path){
      setS3({show2:  "image-upload-wrap", show1: "file-upload-content"})
      setPreviewUrl(url+"storage/"+user.dataes.video_path)
    }
  
  
    if(user.dataes.self_image){
        setS2({show2:  "image-upload-wrap", show1: "file-upload-content"})
        imgRef2.current.src = url+"storage/" +user.dataes.self_image
        setImage2()
    }
    if(user.dataes.id_card_image){
        setS({show2:  "image-upload-wrap", show1: "file-upload-content"})
        imgRef.current.src =url+"storage/" + user.dataes.id_card_image

    }
},[])
const [showSuc , setSuc] = useState(false)
const [modal , setModal] = useState(false)
const [elat , setElat] = useState({})
const authYesOr = (type)=>{
    let x = []
    user.dataes.confirmed_authentications.map(d=>{
     x.push(d.type)
    })
    if(x.includes(type)){   
        return <SiTicktick />
    }else{
        return <GiCancel color='red' />
    }
}
const authYesOrNo = (type)=>{
  let x = []
  user.dataes.confirmed_authentications.map(d=>{
   x.push(d.type)
  })
  
  if(x.includes(type)){   
      return true
  }else{
      return false
  }
}
const progress = ()=>{
  let x = []
  user.dataes.confirmed_authentications.map(d=>{
   x.push(d.type)
  })
  if((x.length/5)*100>100){
    return 100
  }else{
    return (x.length/5)*100
  }
 
}
const notShow = ()=>{
  let translator = (t) =>{
    if(t=== "Identity_auth"){
      return "اطلاعات هویتی"
    }else if(t==="SelfImage_auth"){
      return "تصویر سلفی"
    }else if(t==="IdCard_auth"){
      return "تصویر کارت ملی"
    }else if(t==="Video_auth"){
      return "ویدیو احراز هویت"
    }
  }
  if(user.dataes.un_confirmed_authentications.length !== 0){
    return <div className="authCheck">
    <div className='bb'>
       <div className='ehraz' >
       احراز هویت های رد شده 
       </div>
    </div>

           <div className='authUl'>
               {user.dataes.un_confirmed_authentications.map(d=>{
                return <div key={d.id} className='yesOrNo'> 
                   <div> {translator(d.type)} </div>  
                   <div onClick={()=>{
                    setElat(d)
                    setModal(true)
                   }} className='rad'> علت رد </div>
               </div>
               })}
           </div>
     </div>
     
  }
  
}

  const [act,setAct]=useState({a1:"active",a2:""})
  const mainShow = ()=>{
     if(act.a1){
      
      
      return   <>
        <div className='row'>
            <div className='col-xl-4 pl0'> 
            <div className="authCheck">
               <div className='bb'>
                  <div className='ehraz' >
                     وضعیت احراز هویت
                  </div>
               </div>

               <div className='authUl'>
                  <div className='yesOrNo'> 
                      <div> اطلاعات هویتی  </div>  
                         {authYesOr("Identity_auth")}
                      </div>
                   </div>
                   <div className='yesOrNo'> 
                     <div> تصویر سلفی  </div>  
                       {authYesOr("SelfImage_auth")}
                    </div>
                    <div className='yesOrNo'> 
                     <div> تصویر کارت ملی  </div>  
                       {authYesOr("IdCard_auth")}
                    </div>
                    <div className='yesOrNo'> 
                     <div>   ایمیل  </div>  
                       {authYesOr("Email_auth")}
                    </div>
                    <div className='yesOrNo'> 
                     <div>   شماره همراه  </div>  
                       {authYesOr("Phone_auth")}
                    </div>

            </div>
            <div class="progress-bar" style={{background:
              "radial-gradient(closest-side, #02412f 79%, transparent 80% 100%), conic-gradient(#4f0 "+
               progress()
              +"%, #5cff000f 0)"}}>
                 {progress() === 100? <div className='eee'> مرحله اول <br/> تکمیل شده </div>: <>
                  <div className='eee'> احراز هویت </div>
                  <div> {progress()}% </div>
                 </>}
                <progress value="5" min="0" max="100" style={{visibility:"hidden", height:"0",width:"0"}}>
                 
                </progress>
            </div>
            <ul style={{margin:"10px 5px"}}  className='messageCon mobileMessageCon'>
              <p> <RiInformation2Fill className='information ml5' color='greenyellow' />  
                احراز هویت سطح اول جهت ورود به مرحله اصلی بعد از پاس کردن چالش ها اجباری می باشد
              </p>
            </ul>
            {notShow()}
            </div>
            <div className='col-xl-8 pr0'> 
              <div>
                <div className="file-upload">
                 <h6 className='bb'>
                  موارد مربوط به بارگذاری تصاویر
                 </h6>
       <div className='authCon'>
       <ul>     
            <li> عکس های بی کیفیت و ناواضح تایید نمی شوند </li>
            <li> در صورتی که کاربری کارت ملی نداشته باشد
                ، می تواند این گزینه را با شناسنامه جدید (شناسنامه عکس دار و حاوی شماره ملی)
                 یا پاسپورت معتبر جایگزین کند 
            </li>
            <li>
              حداکثر حجم قابل بارگزاری برای تصاویر ، 3 مگابایت می باشد
            </li>
            <li>
              شما باید یک عکس به صورت سلفی با کارت ملی با کیفیت بالا بدون عینک و ماسک ارسال بفرمایید
            </li>

        </ul>
       </div>
       <>
       <h6 className='bt'> بارگذاری تصویر کارت ملی : </h6>
      <div className={s.show1}>
       <input ref={imgIn} onChange={(e)=>{
        setImage(e.currentTarget.files[0])
        setS({show2:  "image-upload-wrap", show1: "file-upload-content"})
        imgRef.current.src = URL.createObjectURL(e.currentTarget.files[0])
         }} className="file-upload-input" type='file' accept="image/*" />
         <div className="drag-text">
          <h5> جهت بارگذاری تصویر کارت ملی کلیک نمایید </h5>
         </div>
        </div>
       <div className={s.show2}>
       <img ref={imgRef} className="file-upload-image" alt="" />
       <div className="image-title-wrap">
        <button onClick={()=>{
            setImage(null)
          setS({show1:  "image-upload-wrap", show2: "file-upload-content"})
          imgRef.current.src = null
          }} type="button" disabled={authYesOrNo("IdCard_auth") ?true:false}
          className="remove-image"> {authYesOrNo("IdCard_auth") ?"احراز هویت تصویر کارت ملی انجام شده":"حذف تصویر "}</button>
       </div>
       </div>
       </>
       <>
       <h6 className='bt mt'> بارگذاری تصویر سلفی با کارت ملی : </h6>
      <div className={s2.show1}>
       <input ref={imgIn2} onChange={(e)=>{
        setImage2(e.currentTarget.files[0])
        setS2({show2:  "image-upload-wrap", show1: "file-upload-content"})
        imgRef2.current.src = URL.createObjectURL(e.currentTarget.files[0])
         }} className="file-upload-input" type='file' accept="image/*" />
         <div className="drag-text">
          <h5> جهت بارگذاری تصویر سلفی با کارت ملی کلیک نمایید </h5>
         </div>
        </div>
       <div className={s2.show2}>
       <img ref={imgRef2} className="file-upload-image" alt="" />
       <div className="image-title-wrap">
        <button onClick={()=>{
          setS2({show1:  "image-upload-wrap", show2: "file-upload-content"})
          imgRef2.current.src = null
          setImage2(null)
          }} type="button" disabled={authYesOrNo("SelfImage_auth") ?true:false}
            className="remove-image"> {authYesOrNo("SelfImage_auth") ?"احراز هویت تصویر سلفی انجام شده":"حذف تصویر "} </button>
       </div>
       </div>
       </>
       <h5 className='bb mt'>
          
        </h5>
       <div className='authCon'>
        <ul>     
            <li> برای برداشت از کیف پول حتما باید احراز هویت شما تکمیل شده باشد </li>
            <li> پس از ارسال تصاویر، بررسی های لازم جهت تایید موارد 
                ارسالی انجام شده و در قسمت وضعیت احراز هویت کاربر در بالای صفحه نشان داده می شود 
            </li>
            <li>
              در صورت تایید موارد ارسالی و تکمیل احراز هویت برخی از اطلاعات شما
               غیر قابل ویرایش خواهد بود. پس 
               حتما در ارسال اطلاعات دقت لازم را داشته باشید
            </li>
         </ul>
       </div>
       <div className='warn'>
        {err}
       </div>
       <button disabled={user.dataes.authenticated === 1 ?true:false} onClick={()=>{
        let data = {self_image : image2 , id_card_image : image1}
       
        if(image1 || image2){
            load(true)
            setErr("")
            axios.post(url+"api/v1/panel/edit_user_images",data,
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
                'Content-Type': 'multipart/form-data'
            } }).then(res=>{
                load(false)
                setSuc(true)
            }).catch(err=>{
                load(false)
                console.log(err);
            })
        }else{
            setErr(" لطفا تصویر جدید را بارگزاری نمایید ")
        }
        
       }} className='file-upload-btn mt'>
         {user.dataes.authenticated === 1 ?"احراز هویت شما تکمیل شده":"ارسال تصاویر احراز هویت"}
       </button>
       </div>
                
              </div>
            </div>
        </div>
    <Success show={modal} closeModal={()=>{
      setModal(false)
    }} name="authModgjal">
     <div >
            <div  className='des'> 
                <p className='why'> علت رد درخواست  :  </p> 
                <p> {elat.description} </p>
            </div> 
            <div className='closeModal'>
                 <button style={{width:"100% "}} className='btnreply mt' onClick={()=>{
                     setModal(false)
                 }}> فهمیدم </button>
                  
                </div>
        </div>
    </Success>
      </>
     }else{
     
      return <>
      <div className='row'>
          <div className='col-xl-4 pl0'> 
          <div className="authCheck">
              <div className='bb'>
                <div className='ehraz' >
                   متن ویدیو احراز هویتی :
                </div>
              </div>
              <div className=' p10'>
                اینجانب {user.dataes.first_name+" "+user.dataes.last_name} با کد ملی 
                {" "+user.dataes.id_number+" "} ،
                این ویدیو برای احراز هویت مجموعه فنفیکس می باشد.
              </div>
          </div>
          {user.dataes.confirmed_authentications.find(e=>e.type==="Video_auth")?
            <div class="progress-bar" style={{background:
              "radial-gradient(closest-side, #02412f 79%, transparent 80% 100%), conic-gradient(#4f0 100%, #5cff000f 0)"}}>
                 <div className='eee'> احراز هویت <br/> تکمیل شده </div>
                <progress value="5" min="0" max="100" style={{visibility:"hidden", height:"0",width:"0"}}>
                 
                </progress>
            </div>
          :<div class="progress-bar" style={{background:
            "radial-gradient(closest-side, #02412f 79%, transparent 80% 100%), conic-gradient(#4f0 0%, #5cff000f 0)"}}>
               <>
                <div className='eee'> مرحله دوم <br/> انجام نشده </div>
               </>
              <progress value="5" min="0" max="100" style={{visibility:"hidden", height:"0",width:"0"}}>
               
              </progress>
          </div>}
            <ul style={{margin:"10px 5px"}}  className='messageCon mobileMessageCon'>
              <p> <RiInformation2Fill className='information ml5' color='greenyellow' />  
                احراز هویت سطح دوم ( ویدیویی ) جهت کلیه برداشت ها از فنفیکس ، اجباری می باشد
              </p>
            </ul>
          {notShow()}
          </div>
          <div className='col-xl-8 pr0'> 
            <div>
              <div className="file-upload">
               <h6 className='bb'>
                موارد مربوط به بارگذاری ویدیو احراز هویت
               </h6>
     <div className='authCon'>
       <ul>     
          
          <li> در ویدیوی بارگزاری شده کاربر باید متن خواسته شده را با صدای بلند و واضح گفته و
            کارت ملی خود را به طوری که چهره اش کاملا مشخص است در دست داشته باشد  </li>
          <li> در صورتی که کاربری کارت ملی نداشته باشد
              ، می تواند این گزینه را با شناسنامه جدید (شناسنامه عکس دار و حاوی شماره ملی)
               یا پاسپورت معتبر جایگزین کند 
          </li>
          <li>
            حداکثر حجم قابل بارگزاری برای ویدیو ، 10 مگابایت می باشد
          </li>
          <li>
            ویدیو ارسالی بایستی با کیفیت بالا بدون عینک و ماسک ارسال شود
          </li>
       </ul>
     </div>
     <>
     <h6 className='bt'> بارگذاری ویدیو احراز هویت : </h6>
    <div className={s3.show1}>
    <input className="file-upload-input" type="file" accept="video/*" onChange={(e)=>{
      const file = e.target.files[0];
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      setS3({show2:  "image-upload-wrap", show1: "file-upload-content"})
    }} />
       <div className="drag-text">
        <h5> جهت بارگذاری ویدیو احراز هویت کلیک نمایید </h5>
       </div>
      </div>
     <div className={s3.show2}>
     {window.innerWidth>"800px"?<ReactPlayer url={previewUrl} controls width="100%"/>:
       <ReactPlayer url={previewUrl} controls className="video" 
       width="90%"  style={{margin:"10px auto" , maxHeight:"300px" }}/>
     }
     <div className="image-title-wrap">
       <button disabled={user.dataes.confirmed_authentications.find(e=>e.type==="Video_auth")?true:false} onClick={()=>{
          setSelectedFile(null);
          setPreviewUrl(null)
          setS3({show1:"image-upload-wrap", show2:"file-upload-content"})
         }} type="button" 
         className="remove-image"> {user.dataes.confirmed_authentications.find(e=>e.type==="Video_auth") ?
         " ویدیو احراز هویت تایید شده":"حذف ویدیو"}</button>
       </div>
      </div>
     </>

     
     <button disabled={user.dataes.confirmed_authentications.find(e=>e.type==="Video_auth")?true:false} onClick={()=>{
      let data = {video:selectedFile}
      if(selectedFile !==null){
        load(true)
        axios.post(url+"api/v1/panel/upload_video",data,
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
            'Content-Type': 'multipart/form-data'
        } }).then(res=>{
            load(false)
            setSuc(true)
        }).catch(err=>{
            load(false)
            NotificationManager.error("بارگزاری انجام نشد بعدا دوباره تلاش نمایید")
            console.log(err);
        })
      }else{
          NotificationManager.warning(" لطفا ویدیو احراز هویت خود را بارگزاری نمایید ")
      }
     }} className='file-upload-btn mt'>
       {user.dataes.confirmed_authentications.find(e=>e.type==="Video_auth") ?"احراز هویت شما تکمیل شده":"ارسال ویدیو احراز هویت"}
     </button>
     </div>
              
            </div>
          </div>
      </div>
     <Success show={modal} closeModal={()=>{
        setModal(false)
      }} name="authModgjal">
        <div>
          <div  className='des'> 
              <p className='why'> علت رد درخواست  :  </p> 
              <p> {elat.description} </p>
          </div> 
          <div className='closeModal'>
               <button style={{width:"100% "}} className='btnreply mt' onClick={()=>{
                   setModal(false)
                }}>
                 فهمیدم 
               </button>
          </div>
        </div>
      </Success>
    </>
     }
  }
  return (
    <>
    <Success show={showSuc} closeModal={()=>{setSuc(false)} } name="auth" >
            <div style={{padding:"10px"}}>
               <div>
                 <img className="sucImg" src={SucImg} alt='' />
                 <div> 
                  اطلاعات اکانت شما با موفقیت بروزرسانی شد
                 </div>
                 <button onClick={()=>{window.location.reload(false);}} style={{marginTop: "20px"}} className="btn btn-success">
                    تایید بروز رسانی
                 </button>
               </div>
            </div>
    </Success>
    <div className="app-header-navigation">
        <div className="tabs">
		  <a onClick={()=>{
        setAct({a1:"active", a2:""})
      }} className={act.a1}>
				احراز هویت مرحله اول
		  </a>			
      
      <a onClick={()=>{
        if(user.dataes.authenticated){
          setAct({a1:"", a2:"active"})
        }else{
          NotificationManager.info("احراز هویت مرحله اول تکمیل نشده است ، امکان بارگزاری "+
            "ویدیو احراز هویت بعد از تکمیل مرحله اول احراز هویت امکان پذیر است","",8000)
        }
        
      }} href className={act.a2}>
        احراز هویت مرحله دوم
      </a>
		  </div>
     </div>
     {mainShow()}
     <NotificationContainer/>
    </>
  )
}
