/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect , useContext, useState, useRef } from 'react'
import "./Order.css"
import axios from 'axios'
import Cookies from 'js-cookie'
import UserContext from "../UserContext/UserContext"
import { RiInformation2Fill } from "react-icons/ri";
import TransAction from '../TransAction/TransAction'
export default function Order(props) {
const { load , url } = useContext(UserContext);
const [orderData , setOrder] = useState({brokers: [] , packages: [] , messages: {}})
const [orderDatB , setOrderB] = useState({broker_servers: ""})
const [platforms , setPlat] = useState("")
const [broker , setBrok] = useState("")
const [categ , setCateg] = useState({products: []})
const [prod , setProd] = useState("")
const [server , setServer] = useState("")
const [btnSuc , setBtn] = useState(false)
const [trans , setTrans] = useState(false)
const [price , setPrice] = useState(0)
const [allData , setAllData] = useState({})
const [c , setC] = useState('')
const ref = useRef(null)
    useEffect(
        ()=>{
            load(true)
            axios.get(url+"api/v1/panel/order_page",
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
            } }).then(res=>{
                load(false)
                setOrder(res.data)
                
            }).catch(err=>{
                load(false)
                console.log(err);
            })
        }
    ,[])
    useEffect(()=>{
     if(btnSuc){
        ref.current.scrollIntoView({ block: "center"})
     }
    },[btnSuc])
    const catShow = ()=>{
        return <fieldset>
        <legend>  انتخاب نوع حساب </legend>
         {orderData.packages.map(d=>{
           return <div className='form-control-radio'>
           <input onChange={(e)=>{
            setCateg({products: []})
            setProd("")
            setBrok("")
            setServer("")
            setOrderB({broker_servers: ""})
            setPlat("")
            setBtn(false)
            
            load(true)    
             axios.get(url+"api/v1/panel/category_products?package_id="+ e.currentTarget.value,
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,     
            } }).then(res=>{
                load(false)
                setCateg(res.data)
                setC(d.title)
                
                
            }).catch(err=>{
                load(false)
                console.log(err);
            }) 
              }} className='radio' value={d.id} name="category" type='radio' />
              <label> {d.title} </label>
          </div>
         })}
     </fieldset>
    }
    
    const products = ()=>{
        if(categ.products.length !== 0){
           return <fieldset style={{columns:"1"}}>
                     <legend>  انتخاب حساب </legend>
                      {
                        categ.products.map(d=>{
                            return <div className='form-control-radio'>
                           
                            <input onChange={(e)=>{
                               
                               if(d.id == e.target.value){
                                if(d.category_id==4){
                                    setPrice([+d.price,+d.discount])
                                    setProd([d.title , d.id])
                                }else{
                                    setPrice(+d.price)
                                    setProd([d.title , d.id])
                                }
                                
                               }
                               }} value={d.id} name="products" type='radio' />
                               <label> {d.title} </label>
                           </div>
                        })

                      }
                       {/* <div style={{
                        display:"flex",
                        flexDirection:"column",
                        }} >
                           <div className='dfjc'>
                             <span >{1000000}</span>
                             <span >{(+price/1000 +"K")}</span>
                             <span >{1000}</span>
                           </div>
                           <input
                             style={{
                                direction:"ltr",
                             }}
                             type="range"
                             value={price}
                             onChange={(e)=>{
                                setPrice(+e.target.value)
                                setProd([(+e.target.value/1000 +"K") , +e.target.value])
                             }}
                             min={1000}
                             max={1000000}
                             step={1000}
                             className='range mt'

                           />
                         </div> */}
                   </fieldset>
        }
    }
    const brokerChecks =()=>{
        if(prod !== ""){
            return <fieldset>
                <legend>  انتخاب بروکر</legend>
           {orderData.brokers.map(d=>{
            return <div className='form-control-radio'>
            <input onChange={(e)=>{
                      if(d.id == e.currentTarget.value){
                        setBrok([d.name,d.id])
                        }
                        setBtn(false)
                        load(true)
                        setOrderB({broker_servers: ""})
                        setPlat("")
                        setServer("")
                        load(true)
                        axios.get(url+"api/v1/panel/broker_servers?broker_id="+ e.currentTarget.value,
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,     
                        } }).then(res=>{
                            load(false)
                            setOrderB(res.data)                   
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        }) 
                    }} value={d.id} name="broker" type='radio' />
            <label> {d.name} </label>
          </div>
           })}
        </fieldset>
        }
    }
    const afterBrok = ()=>{
        let x =[]
        
        if(orderDatB.broker_servers !== ""){
            Object.keys(orderDatB.broker_servers).map(d=>{
                x.push(d)
            })
            return <fieldset style={{columns:"1"}}>     
            <legend>  انتخاب پلتفرم</legend>
            <p style={{width:"100%"}}  className='f12'> * پنل آنالیز قابل استفاده برای پلتفرم MT5 </p>
             {
               x.map(dd=>{
                return <div className='form-control-radio'>
                <input onChange={(e)=>{
                  setPlat(e.currentTarget.value)
                }} value={dd} name="platform" type='radio' />
                <label> {dd.toUpperCase()} </label>
             </div>
               })
             }
            </fieldset>
        }
    }
    const afterPlat = ()=>{
     if(platforms === "mt4"){
        return <fieldset className='mobileServer'>
                <legend style={{direction:"rtl"}}> MetaTrader 4 انتخاب حساب</legend>
                {
                    orderDatB.broker_servers.mt4.map(d=>{
                        return <div className='form-control-radio'>
                        <input onChange={(e)=>{
                          setServer(d.title)
                          setBtn(true)
                        }} value={d.title} name="server" type='radio' />
                        <label> {d.title} </label>
                     </div>
                    })
                }
            </fieldset>
     }else if(platforms === "mt5"){
        return <fieldset className='mobileServer'>
        <legend style={{direction:"rtl"}}> MetaTrader 5 انتخاب حساب</legend>
        {
            orderDatB.broker_servers.mt5.map(d=>{
                return <div className='form-control-radio'>
                         <input onChange={(e)=>{
                            setServer(d.title)
                           setBtn(true)
                            }} value={d.title} name="server" type='radio' />
                            <label> {d.title} </label>
                        </div>
            })
        }
    </fieldset>
     }
        
    }
    const orderSubmit = ()=>{
    if(btnSuc){
        return <button onClick={()=>{
            load(true)
            let prYek = price[1]?price[1]:price
        let alldataes = {price : prYek , product_id: prod[1] ,
            platform: platforms,broker:broker[1],server: server ,
             brokerName: broker[0],
             serverName: c , 
             prodName: prod[0]
            }
        setAllData(alldataes)
        setTrans(true)
        load(false)
        }} className='orderBtn' > درخواست حساب </button>
    }
    }
    const messages = ()=>{
        return <ul ref={ref} className='messageCon'>
        {Object.keys(orderData.messages).map(d=>{
            return <p key={d.id} > <RiInformation2Fill className='information' color='greenyellow' />   {orderData.messages[d].text}. </p>
        })}
        </ul>
        
    }
    const lastConst = () =>{
       if(trans){
           return <TransAction 
           allData={allData} 
           messages={orderData.messages} 
           pageChange={props.changePage}
           gift={price[1]?true:false}
           />
       }else{
        if(window.innerWidth > 1200){
            return <div className="app-header-navigation">
            <div className="tabs">
              <a href className="active">
                    شرکت در چالش ها
              </a>			
             </div>
            <div className='row m10'>
                <div className='col-xl-5 '> 
                  <div className='orderAmount'>
                    <div style={{padding:"5px"}}>
                        کل مبلغ پرداختی
                    </div>
                    <h4 className='bB p5'> {price[1]?
                      <div>
                     <div  style={{textDecoration:"line-through",
                        color:"red",
                        fontSize:"17px"
                     }}>{price[0]+" $"}</div>
                     <div style={{color:"yellowgreen"}}>{price[1]+" $"}</div>
                     </div>
                    :price+" $"} </h4>
                    <div className='orderDetail bb'>
                        <div className='fl'>  نوع حساب </div>
                        <div> {c} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'>  حساب  </div>
                        <div style={{direction:"ltr"}}> {prod[0]} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> بروکر  </div>
                        <div> {broker[0]} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> پلتفرم </div>
                        <div> {platforms.toUpperCase()} </div>
                    </div>
                
                    <div className='orderDetail bb'>
                        <div className='fl'> سرور </div>
                        <div> {server} </div>
                    </div>
                    <div className='orderDetail '>
                        <div className='fl'> قیمت </div>
                        <div> {price[1]?price[1]+" $":price+" $"} </div>
                    </div>
                  </div>
                  {orderSubmit()}
                  {messages()}
                </div>

                <div className='col-xl-7 pr0'> 
                    <div >   
                         <div className='orderChoose'>
                           <h6 className='bb'> نوع سفارش خود را انتخاب نمایید </h6>
                           {catShow()}
                           {products()}               
                           {brokerChecks()}                   
                           {afterBrok()}
                           {afterPlat()}
                         </div>
                </div>
                    
                  
                </div>
            </div>
        </div>
        }else{
            return  <div className="app-header-navigation">
            <div className="tabs">
              <a href className="active">
                    شرکت در چالش ها
              </a>			
            </div>
            <div className='row m10'>
            <div className='col-xl-7 pr0'> 
                    <div >   
                         <div className='orderChoose'>
                           <h6 className='bb'> نوع سفارش خود را انتخاب نمایید </h6>
                           {catShow()}
                           {products()}               
                           {brokerChecks()}                   
                           {afterBrok()}
                           {afterPlat()}
                         </div>
                </div>
                    
                  
                </div>
                <div className='col-xl-5 '> 
                  <div className='orderAmount'>
                    <div style={{padding:"5px"}}>
                        کل مبلغ پرداختی
                    </div>
                    <h4 className='bB p5'>{price[1]?
                      <div>
                     <div  style={{textDecoration:"line-through",
                        color:"red",
                        fontSize:"17px"
                     }}>{price[0]+" $"}</div>
                     <div style={{color:"yellowgreen"}}>{price[1]+" $"}</div>
                     </div>
                    :price+" $"}</h4>
                    <div className='orderDetail bb'>
                        <div className='fl'>  نوع حساب </div>
                        <div> {c} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'>  حساب  </div>
                        <div> {prod[0]} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> بروکر  </div>
                        <div> {broker[0]} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> پلتفرم </div>
                        <div> {platforms.toUpperCase()} </div>
                    </div>
                
                    <div className='orderDetail bb'>
                        <div className='fl'> سرور </div>
                        <div> {server} </div>
                    </div>
                    <div className='orderDetail '>
                        <div className='fl'> قیمت </div>
                        <div>  {price[1]?price[1]+" $":price+" $"}  </div>
                    </div>
                  </div>
                  {orderSubmit()}
                  {messages()}
                </div>
                
            </div>
        </div>
        }
       }
    }
    
  return (<>
  {lastConst()}
  </>
   
  )
}
