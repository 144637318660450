/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect  } from 'react'
import Cookies from 'js-cookie';
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
import axios from 'axios';
import { MdAdsClick } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserContext from "../UserContext/UserContext"
import Success from '../Success/Success';
import { RiShieldUserFill , RiLockPasswordFill } from "react-icons/ri";
import { FaCopy } from "react-icons/fa";
 export default function SupportOrders() {
const { load , url , user } = useContext(UserContext);
const [auth , setAuth] = useState({data:[] , links:[], user:[]})
const [id , setId] = useState("")
const [showUser , setShowUser] = useState(false)
const [show , setShow] = useState(false)
const [btn , setBtn] = useState([])
const [log , setLog] = useState("")
const [pass , setPass] = useState("")
const [radd , setRadd] = useState("")
const [passInv , setPassInv] = useState("")
const [image , setImage] = useState("")
const [imageSer , setImageSer] = useState([false,""])
const [date , setDate] = useState("")
const [active , setActive] = useState({a1:"active",a2:"",a3:"",a4:""})
const [m , setM] = useState([])
const [cer , setCer] = useState(1)
const [searchTerm, setSearchTerm] = useState('');
const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  const sendSearchRequest = (term) => {
        if(term.length >1){
            if(cer === 1){
                load(true)
                            axios.get(url+"api/v1/admin/accounts/real/certificates?query="+term,
                           {
                               headers: {
                                   "Authorization": `bearer ${Cookies.get('token')}`,
                                  
                               }
                           }).then(res => {
                               load(false)
                               console.log(res.data.data);
                               setAuth(res.data.data)
                           }).catch(err => {
                               load(false)
                               console.log(err);
                           })
            }else{
                load(true)
                            axios.get(url+"api/v1/admin/accounts/real/certificates/show?query="+term,
                           {
                               headers: {
                                   "Authorization": `bearer ${Cookies.get('token')}`,
                                  
                               }
                           }).then(res => {
                               load(false)
                               console.log(res.data.data);
                               setAuth(res.data.data)
                           }).catch(err => {
                               load(false)
                               console.log(err);
                           })
            }
       }else{
                load(true)
                            axios.get(url+"api/v1/admin/accounts/real/certificates",
                           {
                               headers: {
                                   "Authorization": `bearer ${Cookies.get('token')}`,
                                  
                               }
                           }).then(res => {
                               load(false)
                               console.log(res.data.data);
                               setAuth(res.data.data)
                           }).catch(err => {
                               load(false)
                               console.log(err);
                           })
       }
    
  };
const [newBalance , setNewBalance] = useState("")
    useEffect(()=>{
        user.dataes.permissions.map(d=>{
            setM(e=>[...e , d.name])
          })
        load(true)
        axios.get(url+"api/v1/panel/orders",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                }
            }).then(res => {
                load(false)
                setAuth(res.data.data)
                console.log(res.data)
            }).catch(err => {
                load(false)
                console.log(err);
            })
    },[])
    const liShows = () =>{
        return <>
        {
            auth.links.map(dd=>{
                let c = ()=>{
                    if(dd.active){
                        return "ac"
                    }else{
                        return null
                    }
                }
                if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                    return <li key={dd.label} className={"page__numbers"+" "+ c()} onClick={()=>{
                        if(dd.url !== null){
                            load(true)
                        axios.get(dd.url,
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            console.log(res.data.data);
                            setAuth(res.data.data)
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }
                    }}> {dd.label} </li>
                }
             
            })
        }
        </>
        
     }
    const authShow = ()=>{
        return <>
          {auth.data.map(d=>{
            
            const name = (d)=>{
              if(d.account.account_info === null){
                   return d.user.first_name+" "+d.user.last_name
              }else{
                if(d.account?.account_info?.first_name !== null){
                    return d.account?.account_info?.first_name + " " +d.account?.account_info?.last_name
                  }else{
                    return d.account?.account_info?.username
                  }
              }
            }
            const translator = (n)=>{
                if(n==="First Step"){
                    return "مرحله اول"
                }else if(n === "Second Step"){
                    return "مرحله دوم"
                }else if(n === "Pro Trader"){
                    return "پرو تریدر"
                }else if(n === "Real"){
                    return "REAL"
                }else{
                    return n
                }
            }
            if(
               d.order.platform === "MT5" && 
               d.account.account_info?.telegram_id === null && 
               active.a4 !=="active"
               ){
                return null
              } else{
                return <>
                    
                    <tr className='font'>
                       <th style={{textAlign:"center"}}>{d.order.id}</th>
                        <th> {name(d)} </th>
                       <th> { d.order.product.category.title+ " " + d.order.product.title} </th>
                       <th> 
                        { d.order.server + "/"+ d.order.platform}
                       </th>
                       <th> { translator(d.account.type)} </th>
                       <th className='transId'> {d.account.created_at} </th>
                       <th className='transId'> {d.account.updated_at} </th>
                       {!btn.includes(d.account.id)?<th className='lastTh'> <MdAdsClick onClick={()=>{
                        if(showUser){
                            setShowUser(false)
                        }else{ 
                           if(active.a4){
                            setImageSer([true,""])
                            setId(d)
                            setShow(true)
                           }else{
                            if((d.type==="Pro Trader" || d.type==="Real" ) && d.related_to!==null){
                                load(true)
                                axios.get(url+"api/v1/panel/account/new_balance/"+d.account.id,
                                    {
                                        headers: {
                                            "Authorization": `bearer ${Cookies.get('token')}`,
                                        }
                                    }).then(res => {
                                        load(false)
                                        setNewBalance(res.data.next)
                                    }).catch(err => {
                                        load(false)
                                        console.log(err);
                                    })
                               }else{
                                
                               }
                               if(!m.includes("pass-requests-verify")){
                                load(true)
                                axios.post(url+"api/v1/panel/accounts/"+d.account.id+"/create",{} ,
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    if(res.data.success){
                                        NotificationManager.success("","انتخاب شد",2000)
                                        setId(d)
                                        setShow(true)
                                    }else{
                                        NotificationManager.error("قبلا توسط شخص دیگری انتخاب شده","انتخاب نشد",4000)
                                    }
                                }).catch(er=>{
                                    load(false)
                                    NotificationManager.error("قبلا توسط شخص دیگری انتخاب شده","انتخاب نشد",4000)
                                })
                            }else{
                                console.log(d);
                                setId(d)
                                setShow(true)
                            } 
                           }
                          }
                       }}/>
                       </th>: null}
                    </tr>
                </>
              }
          })}
        </>
    }
  return (
    <>
    <NotificationContainer/>
    <Success show={show} closeModal={()=>{
        setNewBalance("")
        setImageSer([false,""])
        setShow(false)}} name="allOrders3242" >
          {imageSer[0]?
          <>
            <div className='f12'>
                     <div className='orderDetail bb'>
                        <div className='fl'> نام کامل </div>
                        <div> {id.order.user.first_name
                         + " " + id.order.user.last_name} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.first_name
                                + " " + id.order.user.last_name)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} />  </div>
                     </div>
                     <div className='orderDetail bb'>
                        <div className='fl'> شماره </div>
                        <div> {id.order.user.phone_number} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.phone_number)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                     </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> ایمیل </div>
                        <div> {id.order.user.email} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.email)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /></div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> نام کاربری </div>
                        <div> {id.order.user.username} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.username)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> محصول </div>
                        <div> {id.order.product.category.title+ " " + id.order.product.title} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.product.category.title+ " " + id.order.product.title)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> سرور </div>
                        <div> {id.order.server + "/"+ id.order.platform} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.server + "/"+ id.order.platform)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /></div>
                    </div>
                </div>
           {id.user?.certificate.image?<>
              <div className='flex1'>
                 <div className='gg'> تصویر سرتیفیکیت </div>
                 <img style={{
                   height:"200px",
                   width:"200px"
                 }} src={url+"storage"+id.user.certificate.image} alt='cerImage' />
              </div>
              <div className='flex1'>
            <div className='gg'> تعویض سرتیفیکیت </div>
            <input style={{width:"166.3px"}} type="file" accept="image/*"  onChange={(e)=>{
             setImageSer([true,e.target.files[0]])
            }}  className='loginInput'/>
          </div>
          <button onClick={()=>{
             if(imageSer[1] !==""){
                load(true)
                axios.post(url+"api/v1/admin/accounts/real/certificate/"+id.user.certificate.id+"/update",
                   {certificate:imageSer[1]}
                   ,
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
                                 'Content-Type': 'multipart/form-data'
                } }).then(()=>{
                    load(false)
                    NotificationManager.success("تصویر سرتیفیکیت با موفقیت تعویض شد","ثبت موفق",3000)
                    setShow(false)
                    setBtn(e=>[...e , id.account.id])
                    setImageSer([true,""])
                }).catch(err=>{
                    load(false)
                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات کاربر ثبت نشد",3000)
                    console.log(err);
                })
             }else{
                NotificationManager.warning("لطفا تصویر سرتیفیکیت را بارگزاری نمایید")
             }
          }} className='logBtn'>
            تعویض تصویر
          </button>
          </>:<>
          <div className='flex1'>
            <div className='gg'> بارگزاری سرتیفیکیت </div>
            <input style={{width:"166.3px"}} type="file" accept="image/*"  onChange={(e)=>{
             setImageSer([true,e.target.files[0]])
            }}  className='loginInput'/>
          </div>
          <button onClick={()=>{
             if(imageSer[1] !==""){
                load(true)
                axios.post(url+"api/v1/admin/accounts/real/certificate/"+id.account.id,
                   {certificate:imageSer[1]}
                   ,
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
                                 'Content-Type': 'multipart/form-data'
                } }).then(res=>{
                    load(false)
                    NotificationManager.success("تصویر سرتیفیکیت با موفقیت ثبت شد","ثبت موفق",3000)
                    setShow(false)
                    setBtn(e=>[...e , id.account.id])
                    setImageSer([true,""])
                }).catch(err=>{
                    load(false)
                    
                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات کاربر ثبت نشد",3000)
                    console.log(err);
                })
             }else{
                NotificationManager.warning("لطفا تصویر سرتیفیکیت را بارگزاری نمایید")
             }
          }} className='logBtn'>
            ثبت تصویر
          </button>
          </>}
          
          </>
         :
         <div style={{padding:"10px"}}>    
           <div className='logCon'>
              <div className='bb'> اطلاعات حساب کاربر را وارد نمایید </div>
                    {id.order!== undefined?
                    <div className='f12'>
                     <div className='orderDetail bb'>
                        <div className='fl'> نام کامل </div>
                        <div> {id.account?.account_info?.first_name
                         + " " + id.account?.account_info?.last_name} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.account?.account_info?.first_name
                                + " " + id.account?.account_info?.last_name)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} />  </div>
                     </div>
                     <div className='orderDetail bb'>
                        <div className='fl'> شماره </div>
                        <div> {id.account?.account_info?.phone_number} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.account?.account_info?.phone_number)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                     </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> ایمیل </div>
                        <div> {id.account?.account_info?.email} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.account?.account_info?.email)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /></div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> شناسه تلگرام </div>
                        <div> {id.account?.account_info?.telegram_id} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.account?.account_info?.telegram_id)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> محصول </div>
                        <div> {id.order.product.category.title+ " " + id.order.product.title} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.product.category.title+ " " + id.order.product.title)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                    </div>
                    {id.account&&id.account.related_to !==null?
                    <div style={{color:"greenyellow"}} className='orderDetail bb'>
                    <div className='fl'> مرحله قبلی </div>
                    <div> {id.account.related_to.login} <FaCopy onClick={()=>{
                        navigator.clipboard.writeText(id.account.related_to.login)
                     }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                   </div>:null
                     }
                     {
                      newBalance!==""?
                      <div style={{color:"orange"}} className='orderDetail bb'>
                      <div className='fl'> بالانس جدید </div>
                      <div > {newBalance} <FaCopy onClick={()=>{
                          navigator.clipboard.writeText(newBalance)
                       }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /> </div>
                     </div>:null
                     }
                    <div className='orderDetail bb'>
                        <div className='fl'> سرور </div>
                        <div> {id.order.server + "/"+ id.order.platform} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.server + "/"+ id.order.platform)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginLeft:"10px"}} /></div>
                    </div>
                    </div>:null}
          <div className='flex1'>
            <div className='gg'> <RiShieldUserFill /> شماره لاگین   </div>
            <input value={log} onChange={(e)=>{
             setLog(e.target.value)
            }} type='text' className='loginInput'/>

          </div>
          <div className='flex1'>
            <div className='gg'> <RiLockPasswordFill /> رمز عبور   </div>
            <input type="text" value={pass} onChange={(e)=>{
             setPass(e.target.value)
            }}  className='loginInput'/>
          </div>
          <div className='flex1'>
            <div className='gg'> <RiLockPasswordFill /> INVESTOR   </div>
            <input type="text" value={passInv} onChange={(e)=>{
             setPassInv(e.target.value)
            }}  className='loginInput'/>
          </div>
          <div className='flex1'>
            <div className='gg'>  تاریخ شروع   </div>
            <input style={{width:"166.3px" , direction:"ltr" , textAlign:"left" }} type="date" value={date}  onChange={(e)=>{
             setDate(e.target.value)
             
            }}  className='loginInput'/>
          </div>
          <div className='flex1'>
            <div className='gg'>  تصویر  </div>
            <input style={{width:"166.3px"}} type="file" accept="image/*"  onChange={(e)=>{
             setImage(e.target.files[0])
            }}  className='loginInput'/>
          </div>
          <textarea style={{marginBottom:"10px" , width:"95%"}} className='raddWhy'
           value={radd} onChange={(e)=>{setRadd(e.target.value)}}
            placeholder='توضیحات ... ' />
          <button onClick={()=>{
            if(pass !== "" && log !== "" && passInv!==""){
            if(active.a3){
                let data = {
                    login: log , 
                    password: pass ,
                    investor_password : passInv ,
                    image:image ,
                    description:radd,
                    start_date : date
                    }
                load(true)
                axios.post(url+"api/v1/panel/admin/real/create/"+id.account.id,data ,
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
                                 'Content-Type': 'multipart/form-data'
                } }).then(res=>{
                    load(false)
                    NotificationManager.success("اطلاعات کاربر با موفقیت ثبت شد","ثبت موفق",3000)
                    setShow(false)
                    setBtn(e=>[...e , id.account.id])
                    setPass("")
                    setLog("")
                    setPassInv("")
                }).catch(err=>{
                    load(false)
                    setPass("")
                    setLog("")
                    NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات کاربر ثبت نشد",3000)
                    console.log(err);
                })
            }else{
                let data = {
                user_login: log , 
                user_pass: pass ,
                investor : passInv ,
                image:image ,
                description:radd,
                start_date : date
                }
                load(true)
            axios.post(url+"api/v1/panel/accounts/"+id.account.id+"/update",data ,
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` , 
                             'Content-Type': 'multipart/form-data'
            } }).then(res=>{
                load(false)
                NotificationManager.success("اطلاعات کاربر با موفقیت ثبت شد","ثبت موفق",3000)
                setShow(false)
                setBtn(e=>[...e , id.account.id])
                setPass("")
                setLog("")
                setPassInv("")
            }).catch(err=>{
                load(false)
                setPass("")
                setLog("")
                NotificationManager.error("لطفا بعدا دوباره سعی نمایید"  , "اطلاعات کاربر ثبت نشد",3000)
                console.log(err);
            })
            }
            }else{
            NotificationManager.error("لطفا همه فیلد ها را پر نمایید"  , "اطلاعات کاربر ثبت نشد",3000)
            }
          }} className='logBtn'>
            ثبت اطلاعات
          </button>
          </div>
          </div>
         }
        </Success>
    
     <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                setActive({a1:"active",a2:"",a3:"",a4:""})
                load(true)
              axios.get(url+"api/v1/panel/orders",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                   
                }
            }).then(res => {
                load(false)
                setAuth(res.data.data)
            }).catch(err => {
                load(false)
                console.log(err);
            })
            }} className={active.a1}>
                سفارشات کاربران
            </a>
            {user.dataes.permissions.find(e=>e.name==="read-real-accounts")&&<a onClick={()=>{
                setActive({a1:"",a2:"",a3:"active",a4:""})
                load(true)
             axios.get(url+"api/v1/panel/admin/accounts/show/real",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                }
            }).then(res => {
                load(false)
                setAuth(res.data.data)
            }).catch(err => {
                load(false)
                console.log(err);
            })
            }} className={active.a3}>
                REAL
            </a>}
            {user.dataes.permissions.find(e=>e.name==="certificate")&&<a onClick={()=>{
                setActive({a1:"",a2:"",a3:"",a4:"active"})
                setCer(1)
                load(true)
             axios.get(url+"api/v1/admin/accounts/real/certificates",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                }
            }).then(res => {
                load(false)
                console.log(res.data);
                setAuth(res.data.data)
            }).catch(err => {
                load(false)
                console.log(err);
            })
            }} className={active.a4}>
                CERTIFICATE
            </a>}
            <a onClick={()=>{
                setActive({a1:"",a2:"active",a3:"",a4:""})
                load(true)
             axios.get(url+"api/v1/panel/orders?type=mine",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,
                }
            }).then(res => {
                load(false)
                setAuth(res.data.data)
            }).catch(err => {
                load(false)
                console.log(err);
            })
            }} className={active.a2}>
                انتخاب شده
            </a>
        </div>
        <div className='transAction'>
                <div className='eachTicket'>
                    <p className='bb'>با کلیک بر روی آیکون کلیک اطلاعات حساب کاربر را وارد کنید : </p>
                    {active.a4&&<><select value={cer} style={{marginBottom:"10px"}} className='searchIn' onChange={(e)=>{
                        if(e.currentTarget.value == 1){
                            setCer(1)
                            load(true)
                            axios.get(url+"api/v1/admin/accounts/real/certificates",
                           {
                               headers: {
                                   "Authorization": `bearer ${Cookies.get('token')}`,
                                  
                               }
                           }).then(res => {
                               load(false)
                               console.log(res.data.data);
                               setAuth(res.data.data)
                           }).catch(err => {
                               load(false)
                               console.log(err);
                           })
                        }else{
                            setCer(2)
                            load(true)
                            axios.get(url+"api/v1/admin/accounts/real/certificates/show",
                           {
                               headers: {
                                   "Authorization": `bearer ${Cookies.get('token')}`,
                                  
                               }
                           }).then(res => {
                               load(false)
                               console.log(res.data.data);
                               setAuth(res.data.data)
                           }).catch(err => {
                               load(false)
                               console.log(err);
                           })
                        }
                    }}>
                        <option style={{color:"black"}} value={1}> بارگزاری نشده </option>
                        <option style={{color:"black"}} value={2}> بارگزاری شده </option>
                    </select>
                    <input value={searchTerm} style={{width:"35%" , margin:"10px"}}
                                     className='searchIn'
                                     onChange={(e)=>{
                                        setSearchTerm(e.target.value)
                                     }} type='text' placeholder='جستجو نام کاربری ...'/>
                    </>}
                    
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover  ">
                            <thead >
                                <tr className='font'>
                                    <th style={{textAlign:"center"}}> # </th>
                                    <th> کاربر </th>
                                    <th> محصول </th>
                                    <th> سرور </th>     
                                    <th> مرحله </th>                 
                                    <th> تاریخ درخواست </th>
                                    <th> بروزرسانی </th>
                                </tr>
                            </thead>
                            <tbody>
                                {authShow()}
                            </tbody>
                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(auth.prev_page_url !== null){
                                    load(true)
                                axios.get(auth.prev_page_url,
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setAuth(res.data.data)
                                    
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(auth.next_page_url !== null){
                                    load(true)
                                axios.get(auth.next_page_url,
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setAuth(res.data.data)
                                   
                                    
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

