/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect } from 'react'
import "./AdminUsers.css"
import { MdAdsClick } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Success from '../Success/Success';
import axios from 'axios';
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import { FaRegSquarePlus } from "react-icons/fa6";
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
import AddUser from './AddUser';
import { DateInput} from 'react-hichestan-datetimepicker';
export default function AdminUsers() {
const [modal , setModal] = useState(false)
const [users , setUsers] = useState({data:[] , links:[] })
const [eachUser , setEach] = useState({roles: [], permissions: [], user:{}})
const [role , setRole] = useState("")
const [faal , setFaal] = useState("")
const [pass , setPass] = useState("")
const [amount , setAmount] = useState("")
const [desc , setDesc] = useState("")
const [userD , setUser] = useState({})
const [showWallet , setShowWallet] = useState(false)
const [birth_day,setBir] = useState(null)
const [searchTerm, setSearchTerm] = useState('');
const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  const type = () =>{
    if(activer.a1){
        return "type=users"
    }else if(activer.a2){
        return "type=admin"
    }
 }
  const sendSearchRequest = (term) => {
        if(term.length >1){
            load(true)
            axios.get(url+"api/v1/panel/users/search?"+type()+"&query="+term,
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
            } }).then(res=>{
                load(false)
                setUsers(res.data.data)
            }).catch(err=>{
                load(false)
                console.log(err);
            })
        }else{
            load(true)
            axios.get(url+"api/v1/panel/users?type="+type(),
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
            } }).then(res=>{
                load(false)
                setUsers(res.data.data)
            }).catch(err=>{
                load(false)
                console.log(err);
            })
        }
        
      
       
    
  };
 const inModalShow = ()=>{
    if(activer.a1){
         return <div>
                 <div>
                    <div className='bb'> کاربر {userD.username} </div>
                    <button onClick={()=>{
                        if(user.dataes.roles[0].name==="admin"){
                            load(true)
                        axios.post(url+"api/v1/auth/support/login",{email: userD.username},
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            Cookies.set("support_token" , Cookies.get('token'))
                            Cookies.set("token" , res.data.access_token)
                            Cookies.set("username" , userD.username)
                            window.location.reload()
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }else{
                            load(true)
                        axios.post(url+"api/v1/panel/admin/login",{id: userD.id},
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            Cookies.set("support_token" , Cookies.get('token'))
                            Cookies.set("token" , res.data.access_token)
                            Cookies.set("username" , userD.username)
                            window.location.reload()
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }
                        
                    }} style={{width:"100%"}} className='btnreply mt'> ورود به پنل کاربری </button>
                    {user.dataes.roles[0].name==="admin"&&<button onClick={(event)=>{
                        
                            load(true)
                        axios.post(url+"api/v1/admin/user/active_action",{id:userD.id,
                            status:event.target.innerText === "فعال کردن کاربر"?1:0},
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(()=>{
                            load(false)
                            if(event.target.innerText === "فعال کردن کاربر"){
                                NotificationManager.success("کاربر با موفقیت غیر فعال شد")
                            }else{
                                NotificationManager.success("کاربر با موفقیت فعال شد")
                            }
                            if(event.target.innerText === "فعال کردن کاربر"){
                             event.target.innerText = "غیر فعال کردن کاربر"
                            }else{
                             event.target.innerText = "فعال کردن کاربر"   
                            }
                        }).catch(err=>{
                            load(false)
                            NotificationManager.error("انجام نشد بعدا مجدد امتحان نمایید")
                            console.log(err);
                        })
                        
                    }} style={{width:"100%"}} className='btnreply mt'>
                    {userD.status===1?"غیر فعال کردن کاربر":
                    "فعال کردن کاربر"
                    } </button>}
                      
                      <div style={{margin:"20px 0px 0px 0px"}} className='flex1 '>
                       <div className='gg'>  تاریخ تولد  </div>
                       <DateInput  value={birth_day} onChange={(e)=>{
                                    setBir(e.target.value.split('T')[0])
                                }}
                                 disabled
                                 className="form-control h30 " 
                                  placeholder="انتخاب از تقویم" />
                       </div>
                       
                    <button onClick={()=>{
                        if(birth_day !== null && birth_day !== ""){
                            load(true)
                        axios.post(url+"api/v1/panel/green_screen",{id:userD.id , birth_date:birth_day},
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(()=>{
                            load(false)
                            NotificationManager.success("مشکل صفحه سبز کاربر با موقیت حل شد ")
                        }).catch(err=>{
                            load(false)
                            NotificationManager.error("انجام نشد بعدا مجدد امتحان نمایید")
                            console.log(err);
                        })
                        }else{
                            NotificationManager.warning("تاریخ تولد کاربر را وارد نمایید")
                        }
                    }} style={{width:"100%"}} className='btnreply mt'> حل مشکل صفحه سبز </button>
                    {user.dataes.permissions.find(e=>e.name === "add-wallet")&&<>
                        <button
                    style={{width:"100%"}} 
                    className='btnreply mt'
                    onClick={()=>{
                        setShowWallet(!showWallet)
                    }}
                    > افزودن مبلغ کیف پول </button>
                    {showWallet && 
                    <div className='mt f12' 
                    style={{
                        width:"100%" , 
                        padding:"10px",
                        border:"1px solid gold",
                        borderRadius:"5px"
                    }}>
                       <div className='dfjc bb'>
                         <div className='gg'> مبلغ به دلار : </div>
                         <input 
                          value={amount}
                          onChange={(e)=>{setAmount(e.target.value)}}
                          className='form-control h30'/>
                       </div>
                       <div style={{
                        marginTop:"10px"
                       }} className='gg'>توضیحات را وارد نمایید :</div>
                       <textarea 
                       value={desc}
                       onChange={(e)=>{
                        setDesc(e.target.value)
                       }}
                       style={{
                        marginTop:"10px",
                        minHeight:"150px"
                       }} className='raddWhy'/>
                       <button 
                       style={{width:"100%"}} 
                       className='btn btn-success mt'
                       onClick={()=>{
                        if(amount!== "" && desc!== ""){
                            let text = "  آیا از افزودن مبلغ  "
                         + amount+" $ " +
                        " به کیف پول "+
                        +" "+userD.username+" "+
                        "اطمینان دارید ؟ "
                        if(window.confirm(text)){
                            load(true)
                            axios.post(url+"api/v1/admin/transactions/wallet/"+userD.id+"/add",
                                {amount:amount,
                                 text:desc
                                },
                                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                            } }).then(()=>{
                                load(false)
                                NotificationManager.success("مبلغ مورد نظر به کیف پول کاربر اضافه گردید")
                                setAmount("")
                                setDesc("")
                                setShowWallet(false)
                            }).catch(err=>{
                                load(false)
                                NotificationManager.error("انجام نشد بعدا مجدد امتحان نمایید")
                                console.log(err);
                            })
                        }
                        }else{
                            NotificationManager.warning("لطفا مبلغ و توضیحات را وارد نمایید")
                        }
                       }}>
                        افزودن مبلغ به کیف پول 
                       </button>
                    </div>}
                    </>}
                 </div>
                </div>
    }else if(activer.a2){
        return <div >
      <form onSubmit={(e)=>{
            e.preventDefault()
            const checkedPermissions = eachUser.permissions
      .filter(d => eachUser.user.permissions.some(e => e.name === d.name))
      .map(d => d.name);
            
            load(true)
            axios.post(url+"api/v1/admin/roles/"+eachUser.user.id+"/update", 
                {permissions:checkedPermissions,
                roles:role,
                status:faal,
                password:pass
                },
                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
            } }).then(res=>{
                load(false)
                NotificationManager.success("کاربر با موفقیت بروزرسانی شد","موفق")
            }).catch(err=>{
                load(false)
                console.log(err);
                NotificationManager.error("کاربر بروزرسانی نشد","ناموفق")
            })
         
        }}  >
        <div style={{columns:"2" , padding:"10px"}}>
        {eachUser.permissions.map(d=>{
            return  <>
                       <div key={d.id} className='form-control-radio'>
                             <input 
                             checked={eachUser.user.permissions.find(e=>e.name === d.name)?true:false}
                             onChange={(e) => {
                                if (e.target.checked) {
                                    // Add the permission if it doesn't exist
                                    setEach(prevState => ({
                                        ...prevState,
                                        user: {
                                            ...prevState.user,
                                            permissions: [...prevState.user.permissions, { name: d.name, id: d.id }]
                                        }
                                    }));
                                } else {
                                    setEach(prevState => ({
                                        ...prevState,
                                        user: {
                                        ...prevState.user,
                                         permissions: prevState.user.permissions.filter(p => p.name !== d.name)
                                        }
                                    }));
                                }
                            }}
                             className='radio' value={d.id} name={d.name} type='checkbox' />
                             <label> {d.fa_name} </label>
                       </div>
                    </>  

           
        })}
        </div>
        <div style={{padding:"10px" , borderTop:"1px solid" , borderBottom:"1px solid" }} className='dfjc'>
            <label> نقش کاربر  </label>
            <select style={{width:"50%",paddingRight:"10px"}} value={role} onChange={(e)=>{setRole(e.target.value)}}>
                {eachUser.roles.map(d=>{
                    return <option key={d.id} value={d.name}>{d.fa_name}</option>
                })}
            </select>
        </div>
        <div style={{padding:"10px" , borderTop:"1px solid" , borderBottom:"1px solid" }} className='dfjc'>
            <label> وضعیت </label>
            <select style={{width:"50%",paddingRight:"10px"}} value={faal} 
            onChange={(e)=>{setFaal(e.target.value)}}>
            
            <option value={1}>فعال</option>
            <option value={0}>غیر فعال</option>
            </select>
        </div>
        <div style={{padding:"10px" , borderTop:"1px solid" , borderBottom:"1px solid" }} className='dfjc'>
            <label> رمز عبور </label>
            <input style={{width:"50%",paddingRight:"10px"}} value={pass} 
            onChange={(e)=>{setPass(e.target.value)}}/> 
        </div>
        <button type='submit' className='orderBtn' > بروزرسانی کاربر </button>
      </form>
    </div>
    }
 }
 const { load , url , user } = useContext(UserContext);

 useEffect(()=>{
    
    load(true)
    axios.get(url+"api/v1/panel/users?type=users",
        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
    } }).then(res=>{
        load(false)
        
        setUsers(res.data.data)
    }).catch(err=>{
        load(false)
        console.log(err);
    })
 },[])
 const update = ()=>{
    setActiver({a1:"",a2:"active",a3:""})
    load(true)
    axios.get(url+"api/v1/panel/users?type=admin",
        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
    } }).then(res=>{
        load(false)
        setUsers(res.data.data)
    }).catch(err=>{
        load(false)
        console.log(err);
    })
 }
 const usersShow = ()=>{
    return <>
    {users.data.map(d=>{
          if(d.roles[0]){
            return <tr key={d.username}>
                     <th className='lastTh'>{d.id}</th>
                     <th> {d.username} </th>
                     <th> {d.first_name !== null?
                    d.first_name + " " + d.last_name:
                    "ثبت نشده" 
                    } </th>
                     <th> {d.roles[0].fa_name} </th>
                     <th className='lastTh' > 
                     <MdAdsClick onClick={()=>{
                       if(activer.a2 === "active"){
                        load(true)
                        axios.get(url+"api/v1/admin/roles/"+d.id,
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            setModal(true)
                            setEach(res.data)
                            setRole(res.data.user.roles[0].name)
                            setFaal(res.data.user.status)
                            console.log(res.data);
                            
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                       }else if(activer.a1==="active"){
                        setUser(d)
                        setModal(true)
                       }
                    }} /> </th>    
                 </tr>
          } 
        
    })}
    </>
 }
 const onSearch = ()=>{
    if(searchTerm.length >2){
       return "&&query="+searchTerm
    }else{
      return ""
    }
   }
 const liShows = () =>{
    return <>
    {
        users.links.map(dd=>{
            let c = ()=>{
                if(dd.active){
                    return "ac"
                }else{
                    return null
                }
            }
            if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                return <li key={dd.label} className={"page__numbers "+ c()} onClick={()=>{
                    if(dd.url !== null){
                        load(true)
                        
                    axios.get(dd.url+onSearch()+"&&"+type(),
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                       
                        setUsers(res.data.data)
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                    })
                    }
                }}> {dd.label} </li>
            }
         
        })
    }
    </>
    
 }
 const [activer, setActiver] = useState({a1:"active" , a2:"" , a3:"" })
 const mainShow = ()=>{
  if(activer.a3){
   return <AddUser  update={update}/>
  }else if(activer.a1){
   return <>
            <div className='transAction'>
                <div className='eachTicket'>
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover">
                            <thead >
                                <tr>
                                    <th style={{textAlign:"center"}}> ID </th>
                                    <th className='dfjc'> نام کاربری 
                                        <input
                                        className='searchIn'
                                     onChange={(e)=>{
                                        setSearchTerm(e.target.value)
                                     }} type='text' placeholder='جستجو نام کاربری'/> </th>            
                                    <th> نام کامل </th>
                                    <th> نقش </th>
                                    <th className='lastTh'>  </th>    
                                </tr>
                                
                            </thead>
                            <tbody>
                                {usersShow()}
                            </tbody>

                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(users.prev_page_url !== null){
                                    load(true)
                                axios.get(users.prev_page_url+onSearch()+"&&"+type(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(users.next_page_url !== null){
                                    load(true)
                                axios.get(users.next_page_url+onSearch()+"&&"+type(),
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>
            </div>
            </>
  }else{
    return <>
    <div className='transAction'>
        <div className='eachTicket'>
            <div className='table-responsive-md'>
                <table border={1} className="table table-hover">
                    <thead >
                        <tr>
                            <th style={{textAlign:"center"}}> ID </th>
                            <th className='dfjc'> نام کاربری 
                                <input
                                className='searchIn'
                             onChange={(e)=>{
                                setSearchTerm(e.target.value)
                             }} type='text' placeholder='جستجو نام کاربری'/> </th> 
                            <th> نام کامل </th>
                            <th> نقش </th>
                            <th className='lastTh'>  </th>    
                        </tr>
                        
                    </thead>
                    <tbody>
                        {usersShow()}
                    </tbody>

                </table>
                <ul class="page">
                       <li onClick={()=>{
                        if(users.prev_page_url !== null){
                            load(true)
                            console.log(users.prev_page_url+onSearch()+"&&"+type());
                            
                        axios.get(users.prev_page_url+onSearch()+"&&"+type(),
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                           
                            setUsers(res.data.data)
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }
                       }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                       {liShows()}
                       <li onClick={()=>{
                        if(users.next_page_url !== null){
                        load(true)
                        console.log(users.prev_page_url+onSearch()+"&&"+type());
                        axios.get(users.next_page_url+onSearch()+"&&"+type(),
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            setUsers(res.data.data)
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }
                       }} className="page__btn"><span class="material-icons">
                        <IoArrowForwardCircleSharp /></span></li>
                </ul>
            </div>

        </div>
    </div>
    </>
  }
 }
 
  return (
    <div>
        <Success show={modal} closeModal={()=>{
            setModal(false)
            setShowWallet(false)
            setAmount("")
            setDesc("")
            } } name="ihefjhfj" >
          {inModalShow()}
        </Success>
        <NotificationContainer/>
      <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                setActiver({a1:"active",a2:"",a3:""})
                load(true)
    axios.get(url+"api/v1/panel/users?type=users",
        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
    } }).then(res=>{
        load(false)
        
        setUsers(res.data.data)
    }).catch(err=>{
        load(false)
        console.log(err);
    })
            }}  className={activer.a1}>
             کاربران
            </a>
            {user.dataes.permissions.find(e=>e.name === "create-user") ?<a onClick={()=>{
                setActiver({a1:"",a2:"active",a3:""})
                load(true)
                axios.get(url+"api/v1/panel/users?type=admin",
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    setUsers(res.data.data)
                }).catch(err=>{
                    load(false)
                    console.log(err);
                })
            }}  className={activer.a2}>
             کارمندان
            </a>:null}
            {user.dataes.permissions.find(e=>e.name === "create-user") ? <a onClick={()=>{
                setActiver({a1:"",a2:"",a3:"active"})
            }} className={activer.a3}>
          <FaRegSquarePlus/>   افزودن کارمند
            </a>:null}
            
        </div>
        {mainShow()}
    </div>
    </div>
  )
}
