/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect  } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import { MdAdsClick } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserContext from "../UserContext/UserContext"
import Success from '../Success/Success';

import { FaCopy } from "react-icons/fa";
 export default function SupportOldAcc() {
const { load , url , user } = useContext(UserContext);
const [auth , setAuth] = useState([])
const [id , setId] = useState("")
const [showUser , setShowUser] = useState(false)
const [show , setShow] = useState(false)
const [btn , setBtn] = useState([])
const [xol , setXol] = useState(0)
const [active , setActive] = useState({a1:"active",a2:""})
const [m , setM] = useState([])
const [radd , setRadd] = useState("")
const [date , setDate] = useState("")

    useEffect(()=>{
        user.dataes.permissions.map(d=>{
            setM(e=>[...e , d.name])
          })
        load(true)
        axios.get(url+"api/v1/panel/admin/accounts/existing",
            {
                headers: {
                    "Authorization": `bearer ${Cookies.get('token')}`,  
                }
            }).then(res => {
                load(false)
                setAuth(res.data.data)
                
                
            }).catch(err => {
                load(false)
                console.log(err);
            })
    },[])
    const authShow = ()=>{
        return <>
          {auth.map(d=>{
            const name = (d)=>{
              if(d.order.user.first_name !== null){
                return d.order.user.first_name + " " +d.order.user.last_name
              }else{
                return d.order.user.username
              }
            }
            const translator = (n)=>{
                if(n==="First Step"){
                    return "مرحله اول"
                }else if(n === "Second Step"){
                    return "مرحله دوم"
                }else if(n === "Pro Trader"){
                    return "پرو تریدر"
                }else if(n === "Real"){
                    return "REAL"
                }else{
                    return n
                }
            }
            return <>
                    <tr className='font'>
                       <th style={{textAlign:"center"}}>{d.order.id}</th>
                        <th> {name(d)} </th>
                       <th> { d.order.product.category.title+ " " + d.order.product.title} </th>
                       <th> 
                        { d.order.server + "/"+ d.order.platform}
                       </th>
                       <th> {translator(d.account.type)} </th>
                       <th className='transId'> {d.account.created_at} </th>
                       <th className='transId'> {d.account.updated_at} </th>
                       {!btn.includes(d.account.id)?<th className='lastTh'> <MdAdsClick onClick={()=>{
                        if(showUser){
                            setShowUser(false)
                        }else{ 
                             setId(d)
                             setShow(true)
                          }
                       }}/>
                       </th>: null}
                    </tr>
                </>
              
          })}
        </>
    }
    const xolShow = ()=>{
        if(xol === 1){
            return <> 
            <textarea className='raddWhy' value={radd}
            onChange={(e)=>{setRadd(e.target.value)}} 
            placeholder='در صورت رد علت رد را وارد نمایید' />
            <div className='closeModal'>
            <button style={{width:"100%"}} className='btnreply mt' onClick={()=>{
            if(radd === ""){
                NotificationManager.error("علت رد خالی می باشد", "رد حساب انجام نشد",5000)
            }else{
                load(true)

                axios.post(url+"api/v1/panel/accounts/action", { account_id: id.account.id , status: 2 ,
                     description: radd , title:"Account Rejected" , type:"AccountAction"} ,
                    {
                     headers: {    "Authorization": `bearer ${Cookies.get('token')}`,}
                    }
                    ).then(res => {
                        load(false)
                        NotificationManager.success("حساب کاربر با موفقیت رد شد", "انجام شد", 5000)
                        setShow(false)
                        setBtn(e=>[...e,id.id])
                        setRadd("")
                    }).catch(err => {
                        load(false)
                        NotificationManager.error("رد حساب انجام نشد دوباره سعی کنید", "انجام نشد",5000)
                        console.log(err);
                        setRadd("")
                    })
                    
            }
            }}> رد نهایی حساب </button>
            
         </div>
         </>
        }else if(xol === 2){
            return <>
            <div className='flex1'>
            <div className='gg mt'>  تاریخ شروع   </div>
            <input style={{width:"166.3px" , direction:"ltr" , textAlign:"left" }} type="date" value={date}  onChange={(e)=>{
                setDate(e.target.value)
            }}  className='loginInput mt'/>
          </div>
            <div className='closeModal'>
            
            <button style={{width:"100%"}} className='btnreply mt' onClick={()=>{
               
                load(true)
                axios.post(url+"api/v1/panel/accounts/action", { account_id: id.account.id , status: 1 ,
                   description: "Aproved" , type:"AccountAction" , title:"Account Aproved" , start_date:date} ,
                  {
                      headers: {
                          "Authorization": `bearer ${Cookies.get('token')}`,
                      }
                      
                  }).then(res => {
                      load(false)
                      NotificationManager.success("حساب با موفقیت تایید شد", "انجام شد", 5000)
                          setShow(false)
                          setBtn(e=>[...e,id.id])
                          setRadd("")
                         
                  }).catch(err => {
                      NotificationManager.error("تایید حساب انجام نشد دوباره سعی کنید", "انجام نشد",5000)
                      load(false)
                      console.log(err);
                      setRadd("")
                  })
               
                  
              
             setRadd("")
            
            }}> تایید نهایی حساب </button>
         </div></>
        }
    }
  return (
    <>
    <NotificationContainer/>
    <Success show={show} closeModal={()=>{setShow(false)}} name="allOrders3242" >
          <div style={{minWidth:"350px",padding:"10px"}}>
                   
          <div className='logCon'>
          
         
                    {id.order!== undefined?<>
                    <h6 className='bB p5'> اطلاعات حساب : </h6>
                    <div className='orderDetail bb'>
                        <div className='fl'>  نام کامل </div>
                        <div> {id.order.user.first_name +" "+ id.order.user.last_name} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.first_name +" "+ id.order.user.last_name)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /></div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'>  ایمیل </div>
                        <div> {id.order.user.email } <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.email)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> شماره همراه </div>
                        <div> {id.order.user.phone_number } <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.phone_number)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'>  نوع حساب </div>
                        <div> {id.order.product.category.title } <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.product.category.title )
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'>  حساب  </div>
                        <div> {id.order.product.title} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.product.title)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> بروکر  </div>
                        <div> {id.order.broker.name} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.broker.name)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> پلتفرم </div>
                        <div> {id.order.platform} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.platform)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                
                    <div className='orderDetail bb'>
                        <div className='fl'> سرور </div>
                        <div> {id.order.server} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.server)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /></div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> مرحله </div>
                        <div> {id.type} </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> ورود </div>
                        <div> {id.account.login} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.account.login)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail bb'>
                        <div className='fl'> گذرواژه </div>
                        <div> {id.account.investor_password} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.account.investor_password)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>
                    <div className='orderDetail '>
                        <div className='fl'> تلگرام آیدی </div>
                        <div> {id.order.user.telegram_id} <FaCopy onClick={()=>{
                            navigator.clipboard.writeText(id.order.user.telegram_id)
                         }} style={{verticalAlign:"middle",fontSize:"25px" , marginRight:"10px"}} /> </div>
                    </div>         
            </>:null}
            <> 
            <div style={{marginBottom:"10px"}} className='closeModal mt'>
               <button className='btnreply ' onClick={()=>{
                 setXol(1)
               }}> رد حساب </button>
               <button className='btnreply' onClick={()=>{
                 setXol(2)
               }}> تایید حساب </button>
            </div>
            {xolShow()}
            </>
          </div>
        </div>
     </Success>
    
     <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
               load(true)
               axios.get(url+"api/v1/panel/admin/accounts/existing",
                   {
                       headers: {
                           "Authorization": `bearer ${Cookies.get('token')}`,
                          
                       }
                   }).then(res => {
                       load(false)
                       setAuth(res.data.data)
                      console.log(res.data.data);
                      
                       
                   }).catch(err => {
                       load(false)
                       console.log(err);
                   })
            }} className={active.a1}>
                حساب های قدیمی کاربران
            </a>
           
        </div>
        <div className='transAction'>
                <div className='eachTicket'>
                    <p className='bb'>با کلیک بر روی آیکون کلیک اطلاعات حساب کاربر را وارد کنید : </p>
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover  ">
                            <thead >
                                <tr className='font'>
                                    <th style={{textAlign:"center"}}> # </th>
                                    <th> کاربر </th>
                                    <th> محصول </th>
                                    <th> سرور </th>     
                                    <th> مرحله </th>                 
                                    <th> تاریخ درخواست </th>
                                    <th> بروزرسانی </th>
                                </tr>
                            </thead>
                            <tbody>
                                {authShow()}
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

