/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect,useContext, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import UserContext from "../UserContext/UserContext"
import "./TransActions.css"
import Success from '../Success/Success'
export default function TranActions(props) {
const { load , url } = useContext(UserContext);
const [trans , setTrans] = useState([])
const [modal , setModal] = useState(false)
const [inTrans , setInT] = useState({})


    useEffect(()=>{
        load(true)
        axios.get(url+"api/v1/panel/transactions",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
           setTrans(res.data)
           console.log(res.data);
           
        }).catch(err=>{
            load(false)
            console.log(err);
        })
    }, [])
    const transShow = ()=>{
        return <>
        {
             trans.map(d=>{
            const status = (s , i , v , t) => {
                if (s === 0) {
                    if(d.link !== null){
                        return <a href={d.link.link} target='_blank'>
                        <div className='blue'> در حال بررسی</div>
                    </a>
                    }else{
                        return <div className='orange'> در حال بررسی</div>
                    }
                } else if (s === 1) {
                    if(i === 1 ){
                        if(v === 1){
                            return <div onClick={()=>{
                                props.changePage("سرمایه های دریافت شده")
                            }} style={{cursor:"pointer"}} className='blue'>  تایید خرید </div>
                        }else{
                            return <div onClick={()=>{
                                props.changePage("کیف پول")
                            }} style={{cursor:"pointer"}} className='green'>  واریز سود </div>
                        }
                    }else{
                        return <div onClick={()=>{
                            props.changePage("سرمایه های دریافت شده")
                        }} style={{cursor:"pointer"}} className={t==="deposit"?'green':"purple"}>  تایید شده </div>
                    }
                } else {
                    return <div onClick={()=>{
                      setInT(d)
                      setModal(true)
                      
                      
                    }} className='red' style={{cursor:"pointer"}}> رد شده </div>
                }
            }
            
            const dargah = (d)=>{
              if(d === 1){
                return "کیف پول"
              }else{
                return "آنلاین"
              }
            }
            if(d.status === 1 && d.traceNumber === null){
                return null
            }else{
                return <tr key={d.id}>
                         <td style={{textAlign:"center"}}> {d.id} </td>
                         <td >  {d.transition_type==="Wallet Charge"?
                            <div className='green'>افزایش موجودی</div>:
                         status(d.status , d.gateway , d.verified_by , d.type)} </td>
                         <td> {d.transId} </td>
                         <td>{d.discount_price}</td>
                         <td>{dargah(d.gateway)} </td>
                         <td>{d.type==="withdraw"?"برداشت":"واریز"} </td>
                         <td>{d.updated_at} </td> 
                       </tr>
            }
        })
        }
        </>
       
    }
    const inModal = () =>{
        if(Object.keys(inTrans).length > 0 ){
            return <div>
                <div className='des'> 
                    <p className='why'> علت رد تراکنش :  </p> 
                    <p> {inTrans.description} </p>
                    
                </div> 
                
            </div>
        }
    }
  return (
        <>
        <Success  show={modal} closeModal={()=>{setModal(false)}} name="hhh">
          {inModal()}
        </Success>
            <div className="tabs">
              <a href className="active">
                    تراکنش ها
              </a>			
            </div>
            <div className='transAction'>
                <div className='eachTicket'>
                    <p className='bb'> تراکنش ها و وضعیت تراکنش ها  : </p>
                    <p className='mt f10'>  * برای اطلاع از علت رد روی دکمه قرمز رنگ رد شده کلیک نمایید </p>
                    <p className='mt f10'>  * برای ورود به درگاه پرداخت تراکنش های آنلاین (در صورت لزوم) روی در حال بررسی آبی رنگ کلیک نمایید </p>
                    <p className='mt f10'>  * برای مشاهده اطلاعات اکانت های خود به قسمت سرمایه های دریافت شده مراجعه نمایید  </p>
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover mw100 ">
                            <thead >
                                <tr>
                                    <th style={{textAlign:"center"}}> # </th>
                                    <th>وضعیت </th>
                                    <th>شناسه تراکنش </th>
                                    <th>مبلغ پرداخت شده </th>
                                    <th> نوع تراکنش </th>
                                    <th> نوع درخواست </th>
                                    <th> تاریخ ایجاد </th>    
                                </tr>
                            </thead>
                                <tbody>
                                    {trans.length === 0 ? <tr>
                                    <th style={{textAlign:"center"}} colSpan={7}> تراکنشی ثبت نشده  </th>
                                </tr>: null}
                                    {transShow()}
                                </tbody>
                                
                            
                            <tbody>
                                
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
        </>
  )
}
