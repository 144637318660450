/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {React,useContext,useEffect,useState} from 'react'
import "./Login.css"
import logo from "../../photoes/logo.png"
import Yekbar from '../Yekbar/Yekbar';
import validator from 'validator';
import { TbPasswordFingerprint } from "react-icons/tb";
import UserContext from "../UserContext/UserContext"
import { BiSolidLogInCircle } from "react-icons/bi";
import axios from 'axios';
import Register from "../Register/Register"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Forgot from '../Forgot/Forgot';
//"https://xyz.fenefx.net/chat/"
const Login = () => {
    const { str , load , url  } = useContext(UserContext);
	const [number, setNumber] = useState("")
	const [type, setType] = useState("")
	const [google, setGoogle] = useState(false)
	const [yek , setYek] =useState(0)
	const [time , setTime] =useState(120)
	const numChange = (event) => {
        setNumber(event.target.value);
    };
	const [password, setPassword] = useState("")
	const passChange = (event) => {
        setPassword(event.target.value);
    };
    const [logReg , setLog] = useState(false)
useEffect(()=>{
	if(str !== undefined){
		
		setLog(true)
	}
},[])
	const loginCheck = ()=>{
		if((validator.isEmail(number) ||
		    validator.isMobilePhone(number , ['fa-IR']) || 
			number === "Mohammad") 
			&& password !==""){
			 load(true)
		     if(validator.isEmail(number) || 
			    number === "Mohammad"){
				setType("email")
				axios.post(url+"api/v1/auth/login",{
					username: number , 
					password: password , 
					type: "email"}).then(
					(res)=>{
						console.log(res.data)
						load(false)
						setYek(1)
						setTime(res.data.time)
						setGoogle(res.data.tfa)
						if(res.data.message === "code already sent"){
							NotificationManager.success(" رمز عبور یکبار مصرف قبلا ارسال شده است و فعلا منقضی نشده است ","ورود دو مرحله ای")
						}else{
							NotificationManager.success(" رمز عبور یکبار مصرف به ایمیل شما ارسال شد ","ورود دو مرحله ای")
						}
						
						
					}
				).catch((err)=>{
					console.log(err.response);
					if(err.response !== undefined){
						if(err.response.status === 401){
						NotificationManager.error("اطلاعات کاربری صحیح نمی باشد","ورود انجام نشد", 5000)
						load(false)
						}else if(err.response.status === 429){
						NotificationManager.error("تعداد تلاش نا موفق بیش از حد مجاز چند دقیقه بعد دوباره تلاش نمایید","ورود انجام نشد",5000)
						load(false)
						}else if(err.response.status === 422){
						NotificationManager.error("لطفا نام کاربری و رمز عبور را وارد کنید","ورود انجام نشد",5000)
						load(false)
						}else if(err.response.status === 403){
							NotificationManager.error("اکانت شما مسدود شده ، با پشتیبانی تماس بگیرید","ورود انجام نشد",5000)
							load(false)
							}else{
						NotificationManager.error(" مشکل در سرور ، بزودی برمیگردیم ","ورود انجام نشد",5000)
						load(false)
						}
					}else{
						NotificationManager.error(" عدم برقراری ارتباط با سرور ","ورود انجام نشد")
						load(false)
					}	
				})
			 }else if(validator.isMobilePhone(number , ['fa-IR'])){
				setType("phone")

                axios.post(url+"api/v1/auth/login",{username: number , password: password , type:"phone"}).then(
					(res)=>{
						load(false)

						setYek(1)
						setTime(res.data.time)
						setGoogle(res.data.tfa)
						if(res.data.message === "code already sent"){
							NotificationManager.success(" رمز عبور یکبار مصرف قبلا ارسال شده است و فعلا منقضی نشده است ","ورود دو مرحله ای")
						}else{
							NotificationManager.success(" رمز عبور یکبار مصرف به شماره همراه شما ارسال شد ","ورود دو مرحله ای")
						}
					}
				).catch((err)=>{
					console.log(err);
					if(err.response !== undefined){
						if(err.response.status === 401){
						NotificationManager.error("اطلاعات کاربری صحیح نمی باشد","ورود انجام نشد", 5000)
						load(false)
						}else if(err.response.status === 429){
						NotificationManager.error("تعداد تلاش نا موفق بیش از اندازه چند دقیقه بعد دوباره تلاش نمایید","ورود انجام نشد",5000)
						load(false)
						}else if(err.response.status === 422){
						NotificationManager.error("لطفا نام کاربری و رمز عبور را وارد کنید","ورود انجام نشد",5000)
						load(false)
						}else if(err.response.status === 403){
							NotificationManager.error("به علت رفتار مشکوک اکانت شما مسدود شده ، با پشتیبانی تماس بگیرید","ورود انجام نشد",5000)
							load(false)
						}else{
						NotificationManager.error(" مشکل در سرور ، بزودی برمیگردیم ","ورود انجام نشد",5000)
						load(false)
						}
					}else{
						NotificationManager.error(" عدم برقراری ارتباط با سرور ","ورود انجام نشد")
						load(false)
					}	
				})
			 }
		}else{
          if(password === ""){
			NotificationManager.error("گذرواژه خالی می باشد","ورود انجام نشد")
		  }else{
			NotificationManager.error("ایمیل یا شماره همراه معتبر نمی باشد","ورود انجام نشد")
		  }
		}
       		
	}
	const proplog = ()=>{
		setLog(false)
	}
	const mainLogReg = ()=>{
     if(logReg){
		return <Register login={proplog}/>
	 }else{
		return <div className='body1'>
		         <div  className={window.innerWidth < 800 ? "container2":"container1"}>
	              <div  className="screen">
		            <div className="screen__content">
                     <div className='logoCon'>
                      <img className='logo' src={logo} alt='logo'/>
                     </div>
			    <form onSubmit={(e)=> {e.preventDefault()}} className="login">
				
				    <div className="login__field">
					    <input onChange={numChange} type="text" className="login__input" placeholder="شماره همراه / ایمیل"/>
					    <BiSolidLogInCircle />
				    </div>
				   <div className="login__field">
					
					<input onChange={passChange} type="password" className="login__input" placeholder="رمز عبور"/>
					<TbPasswordFingerprint />
				   </div>
				   <button onClick={loginCheck} className="button login__submit">
					<span className="button__text"> ورود </span>
					<i className="button__icon fas fa-chevron-right"></i>
				   </button>				
			    </form>
			   <div className='forgotAndReg'>
              <a onClick={()=>{
				setLog(true)
			}} className='alog' > 
		      حساب ندارید ؟ ثبت نام 
			</a>
        <a className='alog' onClick={()=>{
			setYek(2)
		}}>
			
			 فراموشی رمز عبور </a>
      </div>
		</div>
		<div className="screen__background">
			
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</div>
   

		</div>
	 }
	}
	const yekShow = ()=>{
		if(yek === 1){
			return <div>

			<Yekbar login={()=>{
				setYek(0)
			}} user={[number , password , type]} time={time} tfa={google}/>
			</div> 
		}else if(yek === 2){
			return <Forgot login={()=>{setYek(0)}}/>
		}
		else{
			return mainLogReg()
		}
	}
  return (
    <>
	<NotificationContainer/>
	{yekShow()}
	</>
  )
}

export default Login