// src/components/NotificationDropdown.js
import React, { useState , useContext } from 'react';
import './NotificationDropdown.css';
import { MdNotificationsActive } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import UserContext from "../UserContext/UserContext"
import { FaEye } from "react-icons/fa";
import axios from 'axios';
import Cookies from 'js-cookie';
const NotificationDropdown = (props) => {
    const { user , load ,url } = useContext(UserContext);
     
    const [open, setOpen] = useState(false);
    

    const toggleDropdown = () => {setOpen(!open)
        console.log(user.dataes.notifications);
        
    };
    const notifications = user.dataes.notifications
    const styleOnAlarm = ()=>{
        if(notifications.length > 0){
            return "anime"
        }else{
            return ""
        }
    }
    return (
        <div className="notification-dropdown">
            {open && <div onClick={()=>{
                setOpen(false)
            }} style={{height:"100vh" ,
                width:"100vw",
                position:"fixed" ,
                zIndex:"8",
                top:"50px",
                left:"0",
                bottom:"0"
                
                   }}></div>}
            <div className="icon" onClick={toggleDropdown}>
            {!open?<>
             <MdNotificationsActive style={{verticalAlign:"middle"}} className={styleOnAlarm()} />
             <div className='count'>{notifications.length}</div>
            </>:
             <MdCancel style={{verticalAlign:"middle"}} />
            }
            </div>
            {open && (
                <div className="dropdown-menu1" style={{maxHeight:"250px" , overflowY:"scroll"}}>
                    {notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <div key={notification.id} className="notification-item dfjc">
                              <div className='tick'> <FaEye onClick={()=>{
                                
                                load(true)
                                axios.post(url+"api/v1/panel/notification/"+notification.id,{},
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` ,              
                                } }).then( 
                                        res=>{
                                            load(false)
                                        }
                                    ).catch(err=>{
                                        load(false)
                                        
                                    })
                                 if(notification.redirect_url === "Tickets"){
                                    props.changePage("ارسال تیکت")
                                 }else if(notification.redirect_url === "Wallet"){
                                    props.changePage("کیف پول")   
                                 }else if(notification.redirect_url === "Accounts"){
                                    props.changePage("سرمایه های دریافت شده")
                                 }else if(notification.redirect_url === "Auth"){
                                    props.changePage("احراز هویت")
                                 }else if(notification.redirect_url === "Transactions"){
                                    props.changePage("تاریخچه تراکنش ها")
                                 }

                              }} style={{verticalAlign:"middle"}} /></div>  
                              <div style={{textAlign:"justify" ,
                                 paddingLeft:"7px",
                                 direction:"rtl"
                                 }}> {notification.message} </div>
                                
                            </div>
                        ))
                    ) : (
                        <div className="no-notifications"> پیامی ندارید </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotificationDropdown;
