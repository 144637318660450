/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState , useContext, useEffect } from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import Success from '../Success/Success'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { MdAdsClick } from "react-icons/md";

export default function SupportPass() {
    const [m , setM] = useState([])
    const [des , setDes] = useState({})
    useEffect(()=>{
      user.dataes.permissions.map(d=>{
        setM(e=>[...e , d.name])
      })
        load(true)
        axios.get(url+"api/v1/panel/requests/pass",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
    },[])
    const { load , url , user } = useContext(UserContext);
    const [modal , setModal] = useState(false)
    const [hesab , setHesab] = useState([])
    const [radd , setRadd] = useState("")
    const [id , setId] = useState("")
    const [btn , setBtn] = useState([])
    const [shows , setShows] = useState(0)
    const [passess , setPasses] = useState([])
    const hesabs = ()=>{
        const translator = (n)=>{
            if(n==="First Step"){
                return "مرحله اول"
            }else if(n === "Second Step"){
                return "مرحله دوم"
            }else if(n === "Pro Trader"){
                return "پرو تریدر"
            }else if(n === "Real"){
                return "REAL"
            }else{
                return n
            }
        }
        
        return <>
        {hesab.map(d=>{
              return <tr>
                         <th> {d.user.username} </th>
                         <th> {d.account.order.product.category?.title + "/" + d.account.order.product.title} </th>
                         <th> {d.account.order.server+" / "+d.account.order.platform} </th>
                         <th style={{direction:"ltr" , textAlign:"left"}}> {d.account.login} </th>
                         <th style={{direction:"ltr" , textAlign:"left"}}> {d.account.investor_password} </th>
                         <th> {translator(d.account.type)} </th>  
                         <th> {d.created_at} </th>    
                         <th> {d.updated_at} </th>    
                         {activer.a2==="active" || activer.a4 === "active"?
                         <th> {d.did_by!==null?d.did_by.first_name + " " + d.did_by.last_name:null} </th>:null  }  
                         <th className='lastTh'> 
                         {!btn.includes(d.id)?<MdAdsClick onClick={()=>{
                            load(true)
                            axios.get(url+"api/v1/panel/pass_request/count?account_id="+d.account_id,
                                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                            } }).then(res=>{
                                load(false)
                                console.log(res.data)
                                setPasses(res.data)
                                
                            }).catch(err=>{
                                load(false)
                                
                                console.log(err);
                            })
                            
                            let t = []
                            user.dataes.permissions.map(dd=>{
                                if(dd.name === "pass-requests-verify"){
                                 t.push(dd.name)
                                }
                            })
                            
                            if(!m.includes("pass-requests-verify")){
                                load(true)
                                axios.get(url+"api/v1/panel/requests/pass/"+d.id+"/pick",
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                    setId(d.id)
                                    setDes(d)
                                    if(res.data.message === "You have already requested for this account"){
                                        setModal(true)
                                    }else{
                                        NotificationManager.success("درخواست برای شما انتخاب شد",
                                            "درخواست موفق",5000)
                                            setModal(true)
                                    }
                                }).catch(err=>{
                                    load(false)
                                    NotificationManager.error("این تقاصا قبلا توسط شخص دیگری انتخاب شده",
                                        "ناموفق",4000)
                                    console.log(err);
                                })
                            }else{
                                setId(d.id)
                                setModal(true)
                                setDes(d)
                                console.log(d);
                                
                            }
                          
                            }} />:null} </th>    
                     </tr>
            
        })}
        </>
    }
    
    const btnShow = ()=>{
      if(shows === 1){
        return <> 
        <textarea className='raddWhy mt' 
           value={radd} 
           onChange={(e)=>{setRadd(e.target.value)}}
           placeholder='در صورت رد علت رد را وارد نمایید'/>
        <button style={{width:"100%",background:"red",color:"white"}} className='btn mt ' onClick={()=>{
            if(radd === ""){
               NotificationManager.error("علت رد خالی می باشد", "رد تقاضای پاس انجام نشد",5000)
           }else{
               const st = ()=>{
                   if(activer.a4){
                       return 3
                   }else{
                       return 4
                   }
               }
               load(true)
               axios.post(url+"api/v1/panel/requests/pass", { id: id , status: st() , text: radd} ,
                   {
                       headers: {
                           "Authorization": `bearer ${Cookies.get('token')}`,
                       }
                   }).then(res => {
                       load(false)
                       NotificationManager.success("تقاضای پاس با موفقیت رد شد", "انجام شد", 5000)
                       setModal(false)
                       setBtn(e=>[...e,id])
                       setRadd("")
                   }).catch(err => {
                       load(false)
                       NotificationManager.error("رد پاس انجام نشد دوباره سعی کنید", "انجام نشد",5000)
                       console.log(err);
                   })
           }
        }}> رد نهایی</button>
        </> 
        
      }else if(shows === 2){
        return <> 
        <textarea className='raddWhy mt' value={radd} onChange={(e)=>{setRadd(e.target.value)}}
                         placeholder='توضیحات را وارد نمایید' />
        <button style={{width:"100%", background:"green",color:"white"}} className='btn mt' onClick={()=>{
            if(radd !==""){
                load(true)
                let status = ""
                if(activer.a1 === "active"){
                    status = 1
                }else if(activer.a2 === "active"){
                    status = 2
                }else {
                    status = 1
                }
            
            axios.post(url+"api/v1/panel/requests/pass", { id: id , status: status, text: radd } ,
                {
                    headers: {
                        "Authorization": `bearer ${Cookies.get('token')}`,
                    }
                }).then(res => {
                    load(false)
                    NotificationManager.success("تقاضای پاس با موفقیت تایید شد", "انجام شد", 5000)
                    setModal(false)
                    setBtn(e=>[...e,id])
                    console.log(res.data);
                }).catch(err => {
                    load(false)
                    NotificationManager.error("تایید پاس انجام نشد دوباره سعی کنید", "انجام نشد",5000)
                    console.log(err);
                })
            }else{
                NotificationManager.warning("توضیحات خالی می باشد")
            }
         }}>  تایید نهایی </button>
         </>
      }
    }
    const transPass = (x)=>{
        if(x===3){
            return "رد نهایی شده"
        }else if(x===2){
            return "تایید نهایی شده"
        }else if(x===1){
            return "تایید شده"
        }else if(x===4){
            return "رد شده"
        }else if(x===0){
            return "ایجاد شده"
        }
    }
    const inModalShow = ()=>{

        return  <div >
                     <div className='bb'> تایید یا رد پاس حساب {des.account?.login} </div>
                     <div style={{margin:"10px"}}> وضعیت درخواست های قبلی کاربر : {passess.length} </div>
                       {passess.map((d,i)=>{
                        if(d.status === 1 || d.status === 4){
                            return <div style={{textAlign:"right",fontWeight:"100",padding:"5px", 
                                border:"1px solid #ffffff52"}} className='f12' key={i}> 
                                    درخواست پاس حساب {" "+d.account.login+" "} در
                                     تاریخ {" "+d.updated_at+" "} توسط 
                                     {d.did_by!==null&&" "+d.did_by.first_name+" "+d.did_by.last_name+" "}
                                      {" "+transPass(d.status)}
                                 </div>
                        }else if(d.status === 2 || d.status === 3){
                            return <div style={{textAlign:"right",fontWeight:"100",padding:"5px", 
                                border:"1px solid #ffffff52"}} className='f12' key={i}> 
                                    درخواست پاس حساب {" "+d.account.login+" "} در
                                     تاریخ {" "+d.updated_at+" "} توسط 
                                     {d.verified_by!==null&&" "+d.verified_by.first_name+" "+d.verified_by.last_name+" "}
                                      {" "+transPass(d.status)}
                                 </div>
                        }
                        
                       })}
                       <p className='mt'> تیم مانیتورینگ عزیز لطفا پس از انجام بررسی های لازم 
                        ، وضعیت پاس کاربر را تایید یا رد نمایید   </p>
                         {des.account&&des.account.related_to!==null?<div className='flex'>
                           <div> لاگین مرحله قبلی : </div>
                           <div style={{marginRight:"10px"}}> {des.account.related_to.login} </div>
                         </div>:null}
                       
                           {des.account&&des.account.pass_request_actions.map(d=>{
                            return <div className='des'>
                                           <p className='why'>
                                               توضیحات توسط {d.user.first_name+" "+d.user.last_name}
                                           </p>
                                           <p>{d.description}</p>
                                       </div>
                           })}
                          <div className='closeModal'>
                             <button className='btnreply mt' onClick={()=>{setShows(1)}}> رد</button>
                             <button className='btnreply mt' onClick={()=>{setShows(2)}}> تایید </button>
                          </div>
                          {btnShow()}
                  </div>
    }
  const [activer , setActiver] = useState({a1:"active",a2:"", a3:"",a4:""})
  return (
    <div>
        <Success show={modal} closeModal={()=>{setModal(false)
            setShows(0)
            setDes({})
            setRadd("")
        } } name="insidefjhfj" >
          {inModalShow()}
        </Success>
        <NotificationContainer/>
      <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                setActiver({a1:"active",a2:"",a4:"",a3:""})
                load(true)
        axios.get(url+"api/v1/panel/requests/pass",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a1}>
              بررسی پاس کاربران
            </a>
            <a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"active",a4:""})
                load(true)
        axios.get(url+"api/v1/panel/requests/pass/my",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
        } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a3}>
              انتخاب شده
            </a>
            {m.includes("pass-requests-verify")?<a onClick={()=>{
                setActiver({a1:"",a2:"active" , a3:"",a4:""})
                load(true)
             axios.get(url+"api/v1/panel/requests/pass?type=admin",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
             } }).then(res=>{
            load(false)
            console.log(res.data);
            
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }}  className={activer.a2}>
              پاس نهایی
            </a>:null}
            {m.includes("pass-requests-verify")?<a onClick={()=>{
                setActiver({a1:"",a2:"" , a3:"" ,a4:"active"})
                load(true)
             axios.get(url+"api/v1/panel/requests/pass?type=radd",
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
             } }).then(res=>{
            load(false)
            setHesab(res.data)
        }).catch(err=>{
            load(false)
            console.log(err);
        })
            }} className={activer.a4}>
              رد نهایی
            </a>:null}
        </div>
        <>
            <div className='transAction'>
                <div className='eachTicket'>
                    <div className='bb'> کل تقاضا های ثبت شده برای بررسی پاس  : </div>
                    <p className='mt f10'> *برای تایید یا رد پاس بر روی آیکون   
                        <MdAdsClick 
                        style={{fontSize:"20px",verticalAlign:"middle", marginRight:"3px"}} />
                         کلیک نمایید </p>
                    <div className='table-responsive-md'>
                        <table border={1} className="table table-hover">
                            <thead >
                                <tr>
                                    <th> کاربر </th>
                                    <th> حساب </th>
                                    <th> سرور </th>
                                    <th> ورود </th>
                                    <th> گذرواژه </th>
                                    <th> مرحله </th> 
                                    <th> تاریخ </th>      
                                    <th> بروزرسانی </th>      
                                    {activer.a2==="active" || activer.a4 === "active"?
                                    <th> انتخاب توسط </th>:null}      
                                    <th className='lastTh'>  </th>    
                                </tr>
                                
                            </thead>
                            <tbody>
                                {hesabs()}
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
            </>
       </div>
    </div>
  )
}
